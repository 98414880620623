@charset 'UTF-8';
/*

Theme: Spyre - Slick contemporary multipurpose theme
Product Page: https://themes.getbootstrap.com/product/spyre-slick-contemporary-multipurpose-theme
Author: Webinning
Author URI: https://webinning.co.uk

---

Copyright 2018 Webinning

*/
/* ==========================================================================
   Custom variables
   ========================================================================== */
/**
 * Copy and paste the variables that...
 *
 * Example for the most commom variables:
 *
 * $primary: #d138f2;
 * $secondary: #191919;
 * $font-family-base: 'Merriweather', serif;
 */
/* ==========================================================================
   Bootstrap and Custom variables
   ========================================================================== */
/* ==========================================================================
   Custom and vendor variables
   ========================================================================== */
/* ==========================================================================
   Mixins
   ========================================================================== */
/* ==========================================================================
   Containers calculations
   ========================================================================== */
/* Calculate container width */
/* Calculate outside space of container */
/* ==========================================================================
   Gradients for backgrounds
   ========================================================================== */
:root
{
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #f55454;
    --orange: #fd7e14;
    --yellow: #fbcc5d;
    --green: #2ecd75;
    --teal: #20c997;
    --cyan: #41c6f2;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --blue-light: #f3f8fc;
    --primary: #f12639;
    --secondary: #1f2749;
    --success: #2ecd75;
    --info: #41c6f2;
    --warning: #fbcc5d;
    --danger: #f55454;
    --light: #f5f5f5;
    --dark: #343a40;
    --blue-light: #f3f8fc;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --breakpoint-xxl: 1600px;
    --font-family-sans-serif: 'Roboto', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

*,
*::before,
*::after
{
    box-sizing: border-box;
}

html
{
    font-family: sans-serif;
    line-height: 1.15;

    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section
{
    display: block;
}

body
{
    font-family: 'Roboto', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;

    margin: 0;

    text-align: left;

    color: #1a1e2c;
    background-color: #fff;
}

[tabindex='-1']:focus:not(:focus-visible)
{
    outline: 0 !important;
}

hr
{
    overflow: visible; 

    box-sizing: content-box;
    height: 0;
}

h1,
h2,
h3,
h4,
h5,
h6
{
    margin-top: 0;
    margin-bottom: .5rem;
}

p
{
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title]
{
    cursor: help;
    text-decoration: underline;
    text-decoration: underline dotted;

    border-bottom: 0;

    text-decoration-skip-ink: none;
}

address
{
    font-style: normal;
    line-height: inherit; 

    margin-bottom: 1rem;
}

ol,
ul,
dl
{
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol
{
    margin-bottom: 0;
}

dt
{
    font-weight: 700;
}

dd
{
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote
{
    margin: 0 0 1rem;
}

b,
strong
{
    font-weight: bolder;
}

small
{
    font-size: 80%;
}

sub,
sup
{
    font-size: 75%;
    line-height: 0;

    position: relative;

    vertical-align: baseline;
}

sub
{
    bottom: -.25em;
}

sup
{
    top: -.5em;
}

a
{
    text-decoration: none;

    color: #f12639;
    background-color: transparent;
}
a:hover
{
    text-decoration: underline; 

    color: #be0c1d;
}

a:not([href]):not([class])
{
    text-decoration: none; 

    color: inherit;
}
a:not([href]):not([class]):hover
{
    text-decoration: none; 

    color: inherit;
}

pre,
code,
kbd,
samp
{
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    font-size: 1em;
}

pre
{
    overflow: auto;

    margin-top: 0;
    margin-bottom: 1rem;

    -ms-overflow-style: scrollbar;
}

figure
{
    margin: 0 0 1rem;
}

img
{
    vertical-align: middle;

    border-style: none;
}

svg
{
    overflow: hidden;

    vertical-align: middle;
}

table
{
    border-collapse: collapse;
}

caption
{
    padding-top: .75rem;
    padding-bottom: .75rem;

    caption-side: bottom; 

    text-align: left;

    color: #6c757d;
}

th
{
    text-align: inherit;
}

label
{
    display: inline-block;

    margin-bottom: .5rem;
}

button
{
    border-radius: 0;
}

button:focus
{
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea
{
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; 

    margin: 0;
}

button,
input
{
    overflow: visible;
}

button,
select
{
    text-transform: none;
}

[role='button']
{
    cursor: pointer;
}

select
{
    word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit']
{
    -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled)
{
    cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner
{
    padding: 0;

    border-style: none;
}

input[type='radio'],
input[type='checkbox']
{
    box-sizing: border-box;
    padding: 0;
}

textarea
{
    overflow: auto;

    resize: vertical;
}

fieldset
{
    min-width: 0;
    margin: 0;
    padding: 0;

    border: 0;
}

legend
{
    font-size: 1.5rem;
    line-height: inherit;

    display: block;

    width: 100%;
    max-width: 100%;
    margin-bottom: .5rem;
    padding: 0;

    white-space: normal; 

    color: inherit;
}

progress
{
    vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button
{
    height: auto;
}

[type='search']
{
    outline-offset: -2px;

    -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration
{
    -webkit-appearance: none;
}

::-webkit-file-upload-button
{
    font: inherit;

    -webkit-appearance: button;
}

output
{
    display: inline-block;
}

summary
{
    display: list-item;

    cursor: pointer;
}

template
{
    display: none;
}

[hidden]
{
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6
{
    font-family: 'Oswald', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 400;
    line-height: 1.2;

    margin-bottom: .5rem;

    color: inherit;
}

h1,
.h1
{
    font-size: 2.8125rem;
}

h2,
.h2
{
    font-size: 2.25rem;
}

h3,
.h3
{
    font-size: 1.96875rem;
}

h4,
.h4
{
    font-size: 1.6875rem;
}

h5,
.h5
{
    font-size: 1.40625rem;
}

h6,
.h6
{
    font-size: 1.125rem;
}

.lead
{
    font-size: 1.40625rem;
    font-weight: 300;
}

.display-1
{
    font-size: 6rem;
    font-weight: 400;
    line-height: 1.2;
}

.display-2
{
    font-size: 5.5rem;
    font-weight: 400;
    line-height: 1.2;
}

.display-3
{
    font-size: 4.5rem;
    font-weight: 400;
    line-height: 1.2;
}

.display-4
{
    font-size: 3.5rem;
    font-weight: 400;
    line-height: 1.2;
}

hr
{
    margin-top: 1rem;
    margin-bottom: 1rem;

    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

small,
.small
{
    font-size: 83.35%;
    font-weight: 400;
}

mark,
.mark
{
    padding: .2em;

    background-color: #fcf8e3;
}

.list-unstyled
{
    padding-left: 0;

    list-style: none;
}

.list-inline
{
    padding-left: 0;

    list-style: none;
}

.list-inline-item
{
    display: inline-block;
}
.list-inline-item:not(:last-child)
{
    margin-right: .5rem;
}

.initialism
{
    font-size: 90%;

    text-transform: uppercase;
}

.blockquote
{
    font-size: 1.125rem; 

    margin-bottom: 1rem;
}

.blockquote-footer
{
    font-size: 83.35%;

    display: block;

    color: #1a1e2c;
}
.blockquote-footer::before
{
    content: '\2014\00A0';
}

.img-fluid
{
    max-width: 100%;
    height: auto;
}

.img-thumbnail
{
    max-width: 100%;
    height: auto; 
    padding: .25rem;

    border: 1px solid #d2d4db;
    border-radius: .25rem;
    background-color: #fff;
}

.figure
{
    display: inline-block;
}

.figure-img
{
    line-height: 1; 

    margin-bottom: .5rem;
}

.figure-caption
{
    font-size: .9375rem;

    color: #6c757d;
}

code
{
    font-size: 87.5%;

    word-wrap: break-word; 

    color: #e83e8c;
}
a > code
{
    color: inherit;
}

kbd
{
    font-size: 87.5%;

    padding: .2rem .4rem;

    color: #fff;
    border-radius: .2rem; 
    background-color: #1a1e2c;
}
kbd kbd
{
    font-size: 100%;
    font-weight: 700; 

    padding: 0;
}

pre
{
    font-size: 87.5%;

    display: block;

    color: #1a1e2c;
}
pre code
{
    font-size: inherit;

    word-break: normal; 

    color: inherit;
}

.pre-scrollable
{
    overflow-y: scroll; 

    max-height: 340px;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl
{
    width: 100%;
    margin-right: auto;
    margin-left: auto; 
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 576px)
{
    .container,
    .container-sm
    {
        max-width: 540px;
    }
}

@media (min-width: 768px)
{
    .container,
    .container-sm,
    .container-md
    {
        max-width: 720px;
    }
}

@media (min-width: 992px)
{
    .container,
    .container-sm,
    .container-md,
    .container-lg
    {
        max-width: 960px;
    }
}

@media (min-width: 1200px)
{
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl
    {
        max-width: 1140px;
    }
}

@media (min-width: 1600px)
{
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl,
    .container-xxl
    {
        max-width: 1300px;
    }
}

.row
{
    display: flex;

    margin-right: -15px;
    margin-left: -15px; 

    flex-wrap: wrap;
}

.no-gutters
{
    margin-right: 0;
    margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*='col-']
{
    padding-right: 0;
    padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto
{
    position: relative;

    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col
{
    max-width: 100%; 

    flex-basis: 0;
    flex-grow: 1;
}

.row-cols-1 > *
{
    max-width: 100%; 

    flex: 0 0 100%;
}

.row-cols-2 > *
{
    max-width: 50%; 

    flex: 0 0 50%;
}

.row-cols-3 > *
{
    max-width: 33.33333%; 

    flex: 0 0 33.33333%;
}

.row-cols-4 > *
{
    max-width: 25%; 

    flex: 0 0 25%;
}

.row-cols-5 > *
{
    max-width: 20%; 

    flex: 0 0 20%;
}

.row-cols-6 > *
{
    max-width: 16.66667%; 

    flex: 0 0 16.66667%;
}

.col-auto
{
    width: auto;
    max-width: 100%; 

    flex: 0 0 auto;
}

.col-1
{
    max-width: 8.33333%; 

    flex: 0 0 8.33333%;
}

.col-2
{
    max-width: 16.66667%; 

    flex: 0 0 16.66667%;
}

.col-3
{
    max-width: 25%; 

    flex: 0 0 25%;
}

.col-4
{
    max-width: 33.33333%; 

    flex: 0 0 33.33333%;
}

.col-5
{
    max-width: 41.66667%; 

    flex: 0 0 41.66667%;
}

.col-6
{
    max-width: 50%; 

    flex: 0 0 50%;
}

.col-7
{
    max-width: 58.33333%; 

    flex: 0 0 58.33333%;
}

.col-8
{
    max-width: 66.66667%; 

    flex: 0 0 66.66667%;
}

.col-9
{
    max-width: 75%; 

    flex: 0 0 75%;
}

.col-10
{
    max-width: 83.33333%; 

    flex: 0 0 83.33333%;
}

.col-11
{
    max-width: 91.66667%; 

    flex: 0 0 91.66667%;
}

.col-12
{
    max-width: 100%; 

    flex: 0 0 100%;
}

.order-first
{
    order: -1;
}

.order-last
{
    order: 13;
}

.order-0
{
    order: 0;
}

.order-1
{
    order: 1;
}

.order-2
{
    order: 2;
}

.order-3
{
    order: 3;
}

.order-4
{
    order: 4;
}

.order-5
{
    order: 5;
}

.order-6
{
    order: 6;
}

.order-7
{
    order: 7;
}

.order-8
{
    order: 8;
}

.order-9
{
    order: 9;
}

.order-10
{
    order: 10;
}

.order-11
{
    order: 11;
}

.order-12
{
    order: 12;
}

.offset-1
{
    margin-left: 8.33333%;
}

.offset-2
{
    margin-left: 16.66667%;
}

.offset-3
{
    margin-left: 25%;
}

.offset-4
{
    margin-left: 33.33333%;
}

.offset-5
{
    margin-left: 41.66667%;
}

.offset-6
{
    margin-left: 50%;
}

.offset-7
{
    margin-left: 58.33333%;
}

.offset-8
{
    margin-left: 66.66667%;
}

.offset-9
{
    margin-left: 75%;
}

.offset-10
{
    margin-left: 83.33333%;
}

.offset-11
{
    margin-left: 91.66667%;
}

@media (min-width: 576px)
{
    .col-sm
    {
        max-width: 100%; 

        flex-basis: 0;
        flex-grow: 1;
    }
    .row-cols-sm-1 > *
    {
        max-width: 100%; 

        flex: 0 0 100%;
    }
    .row-cols-sm-2 > *
    {
        max-width: 50%; 

        flex: 0 0 50%;
    }
    .row-cols-sm-3 > *
    {
        max-width: 33.33333%; 

        flex: 0 0 33.33333%;
    }
    .row-cols-sm-4 > *
    {
        max-width: 25%; 

        flex: 0 0 25%;
    }
    .row-cols-sm-5 > *
    {
        max-width: 20%; 

        flex: 0 0 20%;
    }
    .row-cols-sm-6 > *
    {
        max-width: 16.66667%; 

        flex: 0 0 16.66667%;
    }
    .col-sm-auto
    {
        width: auto;
        max-width: 100%; 

        flex: 0 0 auto;
    }
    .col-sm-1
    {
        max-width: 8.33333%; 

        flex: 0 0 8.33333%;
    }
    .col-sm-2
    {
        max-width: 16.66667%; 

        flex: 0 0 16.66667%;
    }
    .col-sm-3
    {
        max-width: 25%; 

        flex: 0 0 25%;
    }
    .col-sm-4
    {
        max-width: 33.33333%; 

        flex: 0 0 33.33333%;
    }
    .col-sm-5
    {
        max-width: 41.66667%; 

        flex: 0 0 41.66667%;
    }
    .col-sm-6
    {
        max-width: 50%; 

        flex: 0 0 50%;
    }
    .col-sm-7
    {
        max-width: 58.33333%; 

        flex: 0 0 58.33333%;
    }
    .col-sm-8
    {
        max-width: 66.66667%; 

        flex: 0 0 66.66667%;
    }
    .col-sm-9
    {
        max-width: 75%; 

        flex: 0 0 75%;
    }
    .col-sm-10
    {
        max-width: 83.33333%; 

        flex: 0 0 83.33333%;
    }
    .col-sm-11
    {
        max-width: 91.66667%; 

        flex: 0 0 91.66667%;
    }
    .col-sm-12
    {
        max-width: 100%; 

        flex: 0 0 100%;
    }
    .order-sm-first
    {
        order: -1;
    }
    .order-sm-last
    {
        order: 13;
    }
    .order-sm-0
    {
        order: 0;
    }
    .order-sm-1
    {
        order: 1;
    }
    .order-sm-2
    {
        order: 2;
    }
    .order-sm-3
    {
        order: 3;
    }
    .order-sm-4
    {
        order: 4;
    }
    .order-sm-5
    {
        order: 5;
    }
    .order-sm-6
    {
        order: 6;
    }
    .order-sm-7
    {
        order: 7;
    }
    .order-sm-8
    {
        order: 8;
    }
    .order-sm-9
    {
        order: 9;
    }
    .order-sm-10
    {
        order: 10;
    }
    .order-sm-11
    {
        order: 11;
    }
    .order-sm-12
    {
        order: 12;
    }
    .offset-sm-0
    {
        margin-left: 0;
    }
    .offset-sm-1
    {
        margin-left: 8.33333%;
    }
    .offset-sm-2
    {
        margin-left: 16.66667%;
    }
    .offset-sm-3
    {
        margin-left: 25%;
    }
    .offset-sm-4
    {
        margin-left: 33.33333%;
    }
    .offset-sm-5
    {
        margin-left: 41.66667%;
    }
    .offset-sm-6
    {
        margin-left: 50%;
    }
    .offset-sm-7
    {
        margin-left: 58.33333%;
    }
    .offset-sm-8
    {
        margin-left: 66.66667%;
    }
    .offset-sm-9
    {
        margin-left: 75%;
    }
    .offset-sm-10
    {
        margin-left: 83.33333%;
    }
    .offset-sm-11
    {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px)
{
    .col-md
    {
        max-width: 100%; 

        flex-basis: 0;
        flex-grow: 1;
    }
    .row-cols-md-1 > *
    {
        max-width: 100%; 

        flex: 0 0 100%;
    }
    .row-cols-md-2 > *
    {
        max-width: 50%; 

        flex: 0 0 50%;
    }
    .row-cols-md-3 > *
    {
        max-width: 33.33333%; 

        flex: 0 0 33.33333%;
    }
    .row-cols-md-4 > *
    {
        max-width: 25%; 

        flex: 0 0 25%;
    }
    .row-cols-md-5 > *
    {
        max-width: 20%; 

        flex: 0 0 20%;
    }
    .row-cols-md-6 > *
    {
        max-width: 16.66667%; 

        flex: 0 0 16.66667%;
    }
    .col-md-auto
    {
        width: auto;
        max-width: 100%; 

        flex: 0 0 auto;
    }
    .col-md-1
    {
        max-width: 8.33333%; 

        flex: 0 0 8.33333%;
    }
    .col-md-2
    {
        max-width: 16.66667%; 

        flex: 0 0 16.66667%;
    }
    .col-md-3
    {
        max-width: 25%; 

        flex: 0 0 25%;
    }
    .col-md-4
    {
        max-width: 33.33333%; 

        flex: 0 0 33.33333%;
    }
    .col-md-5
    {
        max-width: 41.66667%; 

        flex: 0 0 41.66667%;
    }
    .col-md-6
    {
        max-width: 50%; 

        flex: 0 0 50%;
    }
    .col-md-7
    {
        max-width: 58.33333%; 

        flex: 0 0 58.33333%;
    }
    .col-md-8
    {
        max-width: 66.66667%; 

        flex: 0 0 66.66667%;
    }
    .col-md-9
    {
        max-width: 75%; 

        flex: 0 0 75%;
    }
    .col-md-10
    {
        max-width: 83.33333%; 

        flex: 0 0 83.33333%;
    }
    .col-md-11
    {
        max-width: 91.66667%; 

        flex: 0 0 91.66667%;
    }
    .col-md-12
    {
        max-width: 100%; 

        flex: 0 0 100%;
    }
    .order-md-first
    {
        order: -1;
    }
    .order-md-last
    {
        order: 13;
    }
    .order-md-0
    {
        order: 0;
    }
    .order-md-1
    {
        order: 1;
    }
    .order-md-2
    {
        order: 2;
    }
    .order-md-3
    {
        order: 3;
    }
    .order-md-4
    {
        order: 4;
    }
    .order-md-5
    {
        order: 5;
    }
    .order-md-6
    {
        order: 6;
    }
    .order-md-7
    {
        order: 7;
    }
    .order-md-8
    {
        order: 8;
    }
    .order-md-9
    {
        order: 9;
    }
    .order-md-10
    {
        order: 10;
    }
    .order-md-11
    {
        order: 11;
    }
    .order-md-12
    {
        order: 12;
    }
    .offset-md-0
    {
        margin-left: 0;
    }
    .offset-md-1
    {
        margin-left: 8.33333%;
    }
    .offset-md-2
    {
        margin-left: 16.66667%;
    }
    .offset-md-3
    {
        margin-left: 25%;
    }
    .offset-md-4
    {
        margin-left: 33.33333%;
    }
    .offset-md-5
    {
        margin-left: 41.66667%;
    }
    .offset-md-6
    {
        margin-left: 50%;
    }
    .offset-md-7
    {
        margin-left: 58.33333%;
    }
    .offset-md-8
    {
        margin-left: 66.66667%;
    }
    .offset-md-9
    {
        margin-left: 75%;
    }
    .offset-md-10
    {
        margin-left: 83.33333%;
    }
    .offset-md-11
    {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px)
{
    .col-lg
    {
        max-width: 100%; 

        flex-basis: 0;
        flex-grow: 1;
    }
    .row-cols-lg-1 > *
    {
        max-width: 100%; 

        flex: 0 0 100%;
    }
    .row-cols-lg-2 > *
    {
        max-width: 50%; 

        flex: 0 0 50%;
    }
    .row-cols-lg-3 > *
    {
        max-width: 33.33333%; 

        flex: 0 0 33.33333%;
    }
    .row-cols-lg-4 > *
    {
        max-width: 25%; 

        flex: 0 0 25%;
    }
    .row-cols-lg-5 > *
    {
        max-width: 20%; 

        flex: 0 0 20%;
    }
    .row-cols-lg-6 > *
    {
        max-width: 16.66667%; 

        flex: 0 0 16.66667%;
    }
    .col-lg-auto
    {
        width: auto;
        max-width: 100%; 

        flex: 0 0 auto;
    }
    .col-lg-1
    {
        max-width: 8.33333%; 

        flex: 0 0 8.33333%;
    }
    .col-lg-2
    {
        max-width: 16.66667%; 

        flex: 0 0 16.66667%;
    }
    .col-lg-3
    {
        max-width: 25%; 

        flex: 0 0 25%;
    }
    .col-lg-4
    {
        max-width: 33.33333%; 

        flex: 0 0 33.33333%;
    }
    .col-lg-5
    {
        max-width: 41.66667%; 

        flex: 0 0 41.66667%;
    }
    .col-lg-6
    {
        max-width: 50%; 

        flex: 0 0 50%;
    }
    .col-lg-7
    {
        max-width: 58.33333%; 

        flex: 0 0 58.33333%;
    }
    .col-lg-8
    {
        max-width: 66.66667%; 

        flex: 0 0 66.66667%;
    }
    .col-lg-9
    {
        max-width: 75%; 

        flex: 0 0 75%;
    }
    .col-lg-10
    {
        max-width: 83.33333%; 

        flex: 0 0 83.33333%;
    }
    .col-lg-11
    {
        max-width: 91.66667%; 

        flex: 0 0 91.66667%;
    }
    .col-lg-12
    {
        max-width: 100%; 

        flex: 0 0 100%;
    }
    .order-lg-first
    {
        order: -1;
    }
    .order-lg-last
    {
        order: 13;
    }
    .order-lg-0
    {
        order: 0;
    }
    .order-lg-1
    {
        order: 1;
    }
    .order-lg-2
    {
        order: 2;
    }
    .order-lg-3
    {
        order: 3;
    }
    .order-lg-4
    {
        order: 4;
    }
    .order-lg-5
    {
        order: 5;
    }
    .order-lg-6
    {
        order: 6;
    }
    .order-lg-7
    {
        order: 7;
    }
    .order-lg-8
    {
        order: 8;
    }
    .order-lg-9
    {
        order: 9;
    }
    .order-lg-10
    {
        order: 10;
    }
    .order-lg-11
    {
        order: 11;
    }
    .order-lg-12
    {
        order: 12;
    }
    .offset-lg-0
    {
        margin-left: 0;
    }
    .offset-lg-1
    {
        margin-left: 8.33333%;
    }
    .offset-lg-2
    {
        margin-left: 16.66667%;
    }
    .offset-lg-3
    {
        margin-left: 25%;
    }
    .offset-lg-4
    {
        margin-left: 33.33333%;
    }
    .offset-lg-5
    {
        margin-left: 41.66667%;
    }
    .offset-lg-6
    {
        margin-left: 50%;
    }
    .offset-lg-7
    {
        margin-left: 58.33333%;
    }
    .offset-lg-8
    {
        margin-left: 66.66667%;
    }
    .offset-lg-9
    {
        margin-left: 75%;
    }
    .offset-lg-10
    {
        margin-left: 83.33333%;
    }
    .offset-lg-11
    {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px)
{
    .col-xl
    {
        max-width: 100%; 

        flex-basis: 0;
        flex-grow: 1;
    }
    .row-cols-xl-1 > *
    {
        max-width: 100%; 

        flex: 0 0 100%;
    }
    .row-cols-xl-2 > *
    {
        max-width: 50%; 

        flex: 0 0 50%;
    }
    .row-cols-xl-3 > *
    {
        max-width: 33.33333%; 

        flex: 0 0 33.33333%;
    }
    .row-cols-xl-4 > *
    {
        max-width: 25%; 

        flex: 0 0 25%;
    }
    .row-cols-xl-5 > *
    {
        max-width: 20%; 

        flex: 0 0 20%;
    }
    .row-cols-xl-6 > *
    {
        max-width: 16.66667%; 

        flex: 0 0 16.66667%;
    }
    .col-xl-auto
    {
        width: auto;
        max-width: 100%; 

        flex: 0 0 auto;
    }
    .col-xl-1
    {
        max-width: 8.33333%; 

        flex: 0 0 8.33333%;
    }
    .col-xl-2
    {
        max-width: 16.66667%; 

        flex: 0 0 16.66667%;
    }
    .col-xl-3
    {
        max-width: 25%; 

        flex: 0 0 25%;
    }
    .col-xl-4
    {
        max-width: 33.33333%; 

        flex: 0 0 33.33333%;
    }
    .col-xl-5
    {
        max-width: 41.66667%; 

        flex: 0 0 41.66667%;
    }
    .col-xl-6
    {
        max-width: 50%; 

        flex: 0 0 50%;
    }
    .col-xl-7
    {
        max-width: 58.33333%; 

        flex: 0 0 58.33333%;
    }
    .col-xl-8
    {
        max-width: 66.66667%; 

        flex: 0 0 66.66667%;
    }
    .col-xl-9
    {
        max-width: 75%; 

        flex: 0 0 75%;
    }
    .col-xl-10
    {
        max-width: 83.33333%; 

        flex: 0 0 83.33333%;
    }
    .col-xl-11
    {
        max-width: 91.66667%; 

        flex: 0 0 91.66667%;
    }
    .col-xl-12
    {
        max-width: 100%; 

        flex: 0 0 100%;
    }
    .order-xl-first
    {
        order: -1;
    }
    .order-xl-last
    {
        order: 13;
    }
    .order-xl-0
    {
        order: 0;
    }
    .order-xl-1
    {
        order: 1;
    }
    .order-xl-2
    {
        order: 2;
    }
    .order-xl-3
    {
        order: 3;
    }
    .order-xl-4
    {
        order: 4;
    }
    .order-xl-5
    {
        order: 5;
    }
    .order-xl-6
    {
        order: 6;
    }
    .order-xl-7
    {
        order: 7;
    }
    .order-xl-8
    {
        order: 8;
    }
    .order-xl-9
    {
        order: 9;
    }
    .order-xl-10
    {
        order: 10;
    }
    .order-xl-11
    {
        order: 11;
    }
    .order-xl-12
    {
        order: 12;
    }
    .offset-xl-0
    {
        margin-left: 0;
    }
    .offset-xl-1
    {
        margin-left: 8.33333%;
    }
    .offset-xl-2
    {
        margin-left: 16.66667%;
    }
    .offset-xl-3
    {
        margin-left: 25%;
    }
    .offset-xl-4
    {
        margin-left: 33.33333%;
    }
    .offset-xl-5
    {
        margin-left: 41.66667%;
    }
    .offset-xl-6
    {
        margin-left: 50%;
    }
    .offset-xl-7
    {
        margin-left: 58.33333%;
    }
    .offset-xl-8
    {
        margin-left: 66.66667%;
    }
    .offset-xl-9
    {
        margin-left: 75%;
    }
    .offset-xl-10
    {
        margin-left: 83.33333%;
    }
    .offset-xl-11
    {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1600px)
{
    .col-xxl
    {
        max-width: 100%; 

        flex-basis: 0;
        flex-grow: 1;
    }
    .row-cols-xxl-1 > *
    {
        max-width: 100%; 

        flex: 0 0 100%;
    }
    .row-cols-xxl-2 > *
    {
        max-width: 50%; 

        flex: 0 0 50%;
    }
    .row-cols-xxl-3 > *
    {
        max-width: 33.33333%; 

        flex: 0 0 33.33333%;
    }
    .row-cols-xxl-4 > *
    {
        max-width: 25%; 

        flex: 0 0 25%;
    }
    .row-cols-xxl-5 > *
    {
        max-width: 20%; 

        flex: 0 0 20%;
    }
    .row-cols-xxl-6 > *
    {
        max-width: 16.66667%; 

        flex: 0 0 16.66667%;
    }
    .col-xxl-auto
    {
        width: auto;
        max-width: 100%; 

        flex: 0 0 auto;
    }
    .col-xxl-1
    {
        max-width: 8.33333%; 

        flex: 0 0 8.33333%;
    }
    .col-xxl-2
    {
        max-width: 16.66667%; 

        flex: 0 0 16.66667%;
    }
    .col-xxl-3
    {
        max-width: 25%; 

        flex: 0 0 25%;
    }
    .col-xxl-4
    {
        max-width: 33.33333%; 

        flex: 0 0 33.33333%;
    }
    .col-xxl-5
    {
        max-width: 41.66667%; 

        flex: 0 0 41.66667%;
    }
    .col-xxl-6
    {
        max-width: 50%; 

        flex: 0 0 50%;
    }
    .col-xxl-7
    {
        max-width: 58.33333%; 

        flex: 0 0 58.33333%;
    }
    .col-xxl-8
    {
        max-width: 66.66667%; 

        flex: 0 0 66.66667%;
    }
    .col-xxl-9
    {
        max-width: 75%; 

        flex: 0 0 75%;
    }
    .col-xxl-10
    {
        max-width: 83.33333%; 

        flex: 0 0 83.33333%;
    }
    .col-xxl-11
    {
        max-width: 91.66667%; 

        flex: 0 0 91.66667%;
    }
    .col-xxl-12
    {
        max-width: 100%; 

        flex: 0 0 100%;
    }
    .order-xxl-first
    {
        order: -1;
    }
    .order-xxl-last
    {
        order: 13;
    }
    .order-xxl-0
    {
        order: 0;
    }
    .order-xxl-1
    {
        order: 1;
    }
    .order-xxl-2
    {
        order: 2;
    }
    .order-xxl-3
    {
        order: 3;
    }
    .order-xxl-4
    {
        order: 4;
    }
    .order-xxl-5
    {
        order: 5;
    }
    .order-xxl-6
    {
        order: 6;
    }
    .order-xxl-7
    {
        order: 7;
    }
    .order-xxl-8
    {
        order: 8;
    }
    .order-xxl-9
    {
        order: 9;
    }
    .order-xxl-10
    {
        order: 10;
    }
    .order-xxl-11
    {
        order: 11;
    }
    .order-xxl-12
    {
        order: 12;
    }
    .offset-xxl-0
    {
        margin-left: 0;
    }
    .offset-xxl-1
    {
        margin-left: 8.33333%;
    }
    .offset-xxl-2
    {
        margin-left: 16.66667%;
    }
    .offset-xxl-3
    {
        margin-left: 25%;
    }
    .offset-xxl-4
    {
        margin-left: 33.33333%;
    }
    .offset-xxl-5
    {
        margin-left: 41.66667%;
    }
    .offset-xxl-6
    {
        margin-left: 50%;
    }
    .offset-xxl-7
    {
        margin-left: 58.33333%;
    }
    .offset-xxl-8
    {
        margin-left: 66.66667%;
    }
    .offset-xxl-9
    {
        margin-left: 75%;
    }
    .offset-xxl-10
    {
        margin-left: 83.33333%;
    }
    .offset-xxl-11
    {
        margin-left: 91.66667%;
    }
}

.table
{
    width: 100%;
    margin-bottom: 1rem;

    color: #1a1e2c;
    background-color: transparent;
}
.table th,
.table td
{
    padding: .75rem;

    vertical-align: top;

    border-top: 1px solid #d2d4db;
}
.table thead th
{
    vertical-align: bottom;

    border-bottom: 2px solid #d2d4db;
}
.table tbody + tbody
{
    border-top: 2px solid #d2d4db;
}

.table-sm th,
.table-sm td
{
    padding: .3rem;
}

.table-bordered
{
    border: 1px solid #d2d4db;
}
.table-bordered th,
.table-bordered td
{
    border: 1px solid #d2d4db;
}
.table-bordered thead th,
.table-bordered thead td
{
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody
{
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd)
{
    background-color: rgba(0, 0, 0, .05);
}

.table-hover tbody tr:hover
{
    color: #1a1e2c;
    background-color: rgba(0, 0, 0, .075);
}

.table-primary,
.table-primary > th,
.table-primary > td
{
    background-color: #fbc2c8;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody
{
    border-color: #f88e98;
}

.table-hover .table-primary:hover
{
    background-color: #f9aab2;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th
{
    background-color: #f9aab2;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td
{
    background-color: #c0c3cc;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody
{
    border-color: #8b8fa0;
}

.table-hover .table-secondary:hover
{
    background-color: #b2b6c1;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th
{
    background-color: #b2b6c1;
}

.table-success,
.table-success > th,
.table-success > td
{
    background-color: #c4f1d8;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody
{
    border-color: #92e5b7;
}

.table-hover .table-success:hover
{
    background-color: #afecca;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th
{
    background-color: #afecca;
}

.table-info,
.table-info > th,
.table-info > td
{
    background-color: #caeffb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody
{
    border-color: #9ce1f8;
}

.table-hover .table-info:hover
{
    background-color: #b2e8f9;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th
{
    background-color: #b2e8f9;
}

.table-warning,
.table-warning > th,
.table-warning > td
{
    background-color: #fef1d2;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody
{
    border-color: #fde4ab;
}

.table-hover .table-warning:hover
{
    background-color: #fde9b9;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th
{
    background-color: #fde9b9;
}

.table-danger,
.table-danger > th,
.table-danger > td
{
    background-color: #fccfcf;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody
{
    border-color: #faa6a6;
}

.table-hover .table-danger:hover
{
    background-color: #fbb7b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th
{
    background-color: #fbb7b7;
}

.table-light,
.table-light > th,
.table-light > td
{
    background-color: #fcfcfc;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody
{
    border-color: #fafafa;
}

.table-hover .table-light:hover
{
    background-color: #efefef;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th
{
    background-color: #efefef;
}

.table-dark,
.table-dark > th,
.table-dark > td
{
    background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody
{
    border-color: #95999c;
}

.table-hover .table-dark:hover
{
    background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th
{
    background-color: #b9bbbe;
}

.table-blue-light,
.table-blue-light > th,
.table-blue-light > td
{
    background-color: #fcfdfe;
}

.table-blue-light th,
.table-blue-light td,
.table-blue-light thead th,
.table-blue-light tbody + tbody
{
    border-color: #f9fbfd;
}

.table-hover .table-blue-light:hover
{
    background-color: #e9f0f8;
}
.table-hover .table-blue-light:hover > td,
.table-hover .table-blue-light:hover > th
{
    background-color: #e9f0f8;
}

.table-active,
.table-active > th,
.table-active > td
{
    background-color: rgba(0, 0, 0, .075);
}

.table-hover .table-active:hover
{
    background-color: rgba(0, 0, 0, .075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th
{
    background-color: rgba(0, 0, 0, .075);
}

.table .thead-dark th
{
    color: #fff;
    border-color: #282e44; 
    background-color: #1a1e2c;
}

.table .thead-light th
{
    color: #5a5c63;
    border-color: #d2d4db; 
    background-color: #e8e8e9;
}

.table-dark
{
    color: #fff;
    background-color: #1a1e2c;
}
.table-dark th,
.table-dark td,
.table-dark thead th
{
    border-color: #282e44;
}
.table-dark.table-bordered
{
    border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd)
{
    background-color: rgba(255, 255, 255, .05);
}
.table-dark.table-hover tbody tr:hover
{
    color: #fff;
    background-color: rgba(255, 255, 255, .075);
}

@media (max-width: 575.98px)
{
    .table-responsive-sm
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm > .table-bordered
    {
        border: 0;
    }
}

@media (max-width: 767.98px)
{
    .table-responsive-md
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md > .table-bordered
    {
        border: 0;
    }
}

@media (max-width: 991.98px)
{
    .table-responsive-lg
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg > .table-bordered
    {
        border: 0;
    }
}

@media (max-width: 1199.98px)
{
    .table-responsive-xl
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl > .table-bordered
    {
        border: 0;
    }
}

@media (max-width: 1599.98px)
{
    .table-responsive-xxl
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xxl > .table-bordered
    {
        border: 0;
    }
}

.table-responsive
{
    display: block;
    overflow-x: auto;

    width: 100%;

    -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered
{
    border: 0;
}

.form-control
{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;

    display: block;

    width: 100%;
    height: calc(2.9375rem + 2px);
    padding: .8rem .75rem;

    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; 

    color: #343a40;
    border: 1px solid #cdced3;
    border-radius: 0;
    background-color: #fff;
    background-clip: padding-box;
}
@media (prefers-reduced-motion: reduce)
{
    .form-control
    {
        transition: none;
    }
}
.form-control::-ms-expand
{
    border: 0; 
    background-color: transparent;
}
.form-control:-moz-focusring
{
    color: transparent;
    text-shadow: 0 0 0 #343a40;
}
.form-control:focus
{
    color: #343a40;
    border-color: #f99ea6;
    outline: 0;
    background-color: #fff;
    box-shadow: 0 0 0 .2rem rgba(241, 38, 57, .25);
}
.form-control::placeholder
{
    opacity: 1; 
    color: #87888d;
}
.form-control:disabled,
.form-control[readonly]
{
    opacity: 1; 
    background-color: #e8e8e9;
}

input[type='date'].form-control,
input[type='time'].form-control,
input[type='datetime-local'].form-control,
input[type='month'].form-control
{
    appearance: none;
}

select.form-control:focus::-ms-value
{
    color: #343a40;
    background-color: #fff;
}

.form-control-file,
.form-control-range
{
    display: block;

    width: 100%;
}

.col-form-label
{
    font-size: inherit;
    line-height: 1.2; 

    margin-bottom: 0;
    padding-top: calc(.8rem + 1px);
    padding-bottom: calc(.8rem + 1px);
}

.col-form-label-lg
{
    font-size: 1.125rem;
    line-height: 1.2; 

    padding-top: calc(.8rem + 1px);
    padding-bottom: calc(.8rem + 1px);
}

.col-form-label-sm
{
    font-size: .875rem;
    line-height: 1; 

    padding-top: calc(.8rem + 1px);
    padding-bottom: calc(.8rem + 1px);
}

.form-control-plaintext
{
    font-size: 1rem;
    line-height: 1.2;

    display: block;

    width: 100%;
    margin-bottom: 0;
    padding: .8rem 0;

    color: #1a1e2c;
    border: solid transparent;
    border-width: 1px 0; 
    background-color: transparent;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg
{
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm
{
    font-size: .875rem;
    line-height: 1;

    height: calc(2.18828rem + 2px);
    padding: .8rem .5rem;

    border-radius: 0;
}

.form-control-lg
{
    font-size: 1.125rem;
    line-height: 1.2;

    height: calc(3.75038rem + 2px);
    padding: .8rem 1rem;

    border-radius: 0;
}

select.form-control[size],
select.form-control[multiple]
{
    height: auto;
}

textarea.form-control
{
    height: auto;
}

.form-group
{
    margin-bottom: 1rem;
}

.form-text
{
    display: block;

    margin-top: .25rem;
}

.form-row
{
    display: flex;

    margin-right: -5px;
    margin-left: -5px; 

    flex-wrap: wrap;
}
.form-row > .col,
.form-row > [class*='col-']
{
    padding-right: 5px;
    padding-left: 5px;
}

.form-check
{
    position: relative;

    display: block;

    padding-left: 1.25rem;
}

.form-check-input
{
    position: absolute;

    margin-top: .3rem;
    margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label
{
    color: #6c757d;
}

.form-check-label
{
    margin-bottom: 0;
}

.form-check-inline
{
    display: inline-flex;

    margin-right: .75rem; 
    padding-left: 0;

    align-items: center;
}
.form-check-inline .form-check-input
{
    position: static;

    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0;
}

.valid-feedback
{
    font-size: 83.35%;

    display: none;

    width: 100%;
    margin-top: .25rem;

    color: #2ecd75;
}

.valid-tooltip
{
    font-size: .94219rem;
    line-height: 1.5;

    position: absolute;
    z-index: 5;
    top: 100%;
    left: 0;

    display: none;

    max-width: 100%;
    margin-top: .1rem;
    padding: .25rem .5rem;

    color: #fff;
    border-radius: .25rem; 
    background-color: rgba(46, 205, 117, .9);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid
{
    padding-right: 2.9375rem;

    border-color: #2ecd75;
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%232ecd75\' d=\'M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z\'/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right calc(.3em + .4rem) center;
    background-size: calc(.6em + .8rem) calc(.6em + .8rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus
{
    border-color: #2ecd75;
    box-shadow: 0 0 0 .2rem rgba(46, 205, 117, .25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid
{
    padding-right: 2.9375rem;

    background-position: top calc(.3em + .4rem) right calc(.3em + .4rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid
{
    padding-right: calc((1em + 1.6rem) * 3 / 4 + 1.75rem);

    border-color: #2ecd75;
    background: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'%3e%3cpath fill=\'%23343a40\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/%3e%3c/svg%3e') no-repeat right .75rem center/8px 10px, url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%232ecd75\' d=\'M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z\'/%3e%3c/svg%3e') #fff no-repeat center right 1.75rem/calc(.6em + .8rem) calc(.6em + .8rem);
}
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus
{
    border-color: #2ecd75;
    box-shadow: 0 0 0 .2rem rgba(46, 205, 117, .25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label
{
    color: #2ecd75;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label
{
    color: #2ecd75;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before
{
    border-color: #2ecd75;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before
{
    border-color: #55d990;
    background-color: #55d990;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before
{
    box-shadow: 0 0 0 .2rem rgba(46, 205, 117, .25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before
{
    border-color: #2ecd75;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label
{
    border-color: #2ecd75;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label
{
    border-color: #2ecd75;
    box-shadow: 0 0 0 .2rem rgba(46, 205, 117, .25);
}

.invalid-feedback
{
    font-size: 83.35%;

    display: none;

    width: 100%;
    margin-top: .25rem;

    color: #f55454;
}

.invalid-tooltip
{
    font-size: .94219rem;
    line-height: 1.5;

    position: absolute;
    z-index: 5;
    top: 100%;
    left: 0;

    display: none;

    max-width: 100%;
    margin-top: .1rem;
    padding: .25rem .5rem;

    color: #fff;
    border-radius: .25rem; 
    background-color: rgba(245, 84, 84, .9);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid
{
    padding-right: 2.9375rem;

    border-color: #f55454;
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23f55454\' viewBox=\'-2 -2 7 7\'%3e%3cpath stroke=\'%23f55454\' d=\'M0 0l3 3m0-3L0 3\'/%3e%3ccircle r=\'.5\'/%3e%3ccircle cx=\'3\' r=\'.5\'/%3e%3ccircle cy=\'3\' r=\'.5\'/%3e%3ccircle cx=\'3\' cy=\'3\' r=\'.5\'/%3e%3c/svg%3E');
    background-repeat: no-repeat;
    background-position: right calc(.3em + .4rem) center;
    background-size: calc(.6em + .8rem) calc(.6em + .8rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus
{
    border-color: #f55454;
    box-shadow: 0 0 0 .2rem rgba(245, 84, 84, .25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid
{
    padding-right: 2.9375rem;

    background-position: top calc(.3em + .4rem) right calc(.3em + .4rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid
{
    padding-right: calc((1em + 1.6rem) * 3 / 4 + 1.75rem);

    border-color: #f55454;
    background: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'%3e%3cpath fill=\'%23343a40\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/%3e%3c/svg%3e') no-repeat right .75rem center/8px 10px, url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23f55454\' viewBox=\'-2 -2 7 7\'%3e%3cpath stroke=\'%23f55454\' d=\'M0 0l3 3m0-3L0 3\'/%3e%3ccircle r=\'.5\'/%3e%3ccircle cx=\'3\' r=\'.5\'/%3e%3ccircle cy=\'3\' r=\'.5\'/%3e%3ccircle cx=\'3\' cy=\'3\' r=\'.5\'/%3e%3c/svg%3E') #fff no-repeat center right 1.75rem/calc(.6em + .8rem) calc(.6em + .8rem);
}
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus
{
    border-color: #f55454;
    box-shadow: 0 0 0 .2rem rgba(245, 84, 84, .25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label
{
    color: #f55454;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label
{
    color: #f55454;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before
{
    border-color: #f55454;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before
{
    border-color: #f88484;
    background-color: #f88484;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before
{
    box-shadow: 0 0 0 .2rem rgba(245, 84, 84, .25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before
{
    border-color: #f55454;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label
{
    border-color: #f55454;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label
{
    border-color: #f55454;
    box-shadow: 0 0 0 .2rem rgba(245, 84, 84, .25);
}

.form-inline
{
    display: flex;

    flex-flow: row wrap;
    align-items: center;
}
.form-inline .form-check
{
    width: 100%;
}
@media (min-width: 576px)
{
    .form-inline label
    {
        display: flex;

        margin-bottom: 0; 

        align-items: center;
        justify-content: center;
    }
    .form-inline .form-group
    {
        display: flex;

        margin-bottom: 0; 

        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
    }
    .form-inline .form-control
    {
        display: inline-block;

        width: auto;

        vertical-align: middle;
    }
    .form-inline .form-control-plaintext
    {
        display: inline-block;
    }
    .form-inline .input-group,
    .form-inline .custom-select
    {
        width: auto;
    }
    .form-inline .form-check
    {
        display: flex;

        width: auto;
        padding-left: 0; 

        align-items: center;
        justify-content: center;
    }
    .form-inline .form-check-input
    {
        position: relative;

        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0; 

        flex-shrink: 0;
    }
    .form-inline .custom-control
    {
        align-items: center;
        justify-content: center;
    }
    .form-inline .custom-control-label
    {
        margin-bottom: 0;
    }
}

.btn
{
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5;

    display: inline-block;

    padding: .625rem .75rem;

    user-select: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; 
    text-align: center;
    vertical-align: middle;

    color: #1a1e2c;
    border: 1px solid transparent;
    border-radius: 0;
    background-color: transparent;
}
@media (prefers-reduced-motion: reduce)
{
    .btn
    {
        transition: none;
    }
}
.btn:hover
{
    text-decoration: none; 

    color: #1a1e2c;
}
.btn:focus,
.btn.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(241, 38, 57, .25);
}
.btn.disabled,
.btn:disabled
{
    opacity: .65;
}
.btn:not(:disabled):not(.disabled)
{
    cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn
{
    pointer-events: none;
}

.btn-primary
{
    color: #fff;
    border-color: #f12639; 
    background-color: #f12639;
}
.btn-primary:hover
{
    color: #fff;
    border-color: #d60e21; 
    background-color: #e20f22;
}
.btn-primary:focus,
.btn-primary.focus
{
    color: #fff;
    border-color: #d60e21;
    background-color: #e20f22;
    box-shadow: 0 0 0 .2rem rgba(243, 71, 87, .5);
}
.btn-primary.disabled,
.btn-primary:disabled
{
    color: #fff;
    border-color: #f12639; 
    background-color: #f12639;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle
{
    color: #fff;
    border-color: #ca0d1f; 
    background-color: #d60e21;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(243, 71, 87, .5);
}

.btn-secondary
{
    color: #fff;
    border-color: #1f2749; 
    background-color: #1f2749;
}
.btn-secondary:hover
{
    color: #fff;
    border-color: #101425; 
    background-color: #14192e;
}
.btn-secondary:focus,
.btn-secondary.focus
{
    color: #fff;
    border-color: #101425;
    background-color: #14192e;
    box-shadow: 0 0 0 .2rem rgba(65, 71, 100, .5);
}
.btn-secondary.disabled,
.btn-secondary:disabled
{
    color: #fff;
    border-color: #1f2749; 
    background-color: #1f2749;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle
{
    color: #fff;
    border-color: #0c0f1c; 
    background-color: #101425;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(65, 71, 100, .5);
}

.btn-success
{
    color: #fff;
    border-color: #2ecd75; 
    background-color: #2ecd75;
}
.btn-success:hover
{
    color: #fff;
    border-color: #25a35d; 
    background-color: #27ae63;
}
.btn-success:focus,
.btn-success.focus
{
    color: #fff;
    border-color: #25a35d;
    background-color: #27ae63;
    box-shadow: 0 0 0 .2rem rgba(77, 213, 138, .5);
}
.btn-success.disabled,
.btn-success:disabled
{
    color: #fff;
    border-color: #2ecd75; 
    background-color: #2ecd75;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle
{
    color: #fff;
    border-color: #229957; 
    background-color: #25a35d;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(77, 213, 138, .5);
}

.btn-info
{
    color: #fff;
    border-color: #41c6f2; 
    background-color: #41c6f2;
}
.btn-info:hover
{
    color: #fff;
    border-color: #11b8ef; 
    background-color: #1dbbf0;
}
.btn-info:focus,
.btn-info.focus
{
    color: #fff;
    border-color: #11b8ef;
    background-color: #1dbbf0;
    box-shadow: 0 0 0 .2rem rgba(94, 207, 244, .5);
}
.btn-info.disabled,
.btn-info:disabled
{
    color: #fff;
    border-color: #41c6f2; 
    background-color: #41c6f2;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle
{
    color: #fff;
    border-color: #10afe4; 
    background-color: #11b8ef;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(94, 207, 244, .5);
}

.btn-warning
{
    color: #fff;
    border-color: #fbcc5d; 
    background-color: #fbcc5d;
}
.btn-warning:hover
{
    color: #fff;
    border-color: #fabc2b; 
    background-color: #fac038;
}
.btn-warning:focus,
.btn-warning.focus
{
    color: #fff;
    border-color: #fabc2b;
    background-color: #fac038;
    box-shadow: 0 0 0 .2rem rgba(252, 212, 117, .5);
}
.btn-warning.disabled,
.btn-warning:disabled
{
    color: #fff;
    border-color: #fbcc5d; 
    background-color: #fbcc5d;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle
{
    color: #fff;
    border-color: #f9b81f; 
    background-color: #fabc2b;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(252, 212, 117, .5);
}

.btn-danger
{
    color: #fff;
    border-color: #f55454; 
    background-color: #f55454;
}
.btn-danger:hover
{
    color: #fff;
    border-color: #f22424; 
    background-color: #f33030;
}
.btn-danger:focus,
.btn-danger.focus
{
    color: #fff;
    border-color: #f22424;
    background-color: #f33030;
    box-shadow: 0 0 0 .2rem rgba(247, 110, 110, .5);
}
.btn-danger.disabled,
.btn-danger:disabled
{
    color: #fff;
    border-color: #f55454; 
    background-color: #f55454;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle
{
    color: #fff;
    border-color: #f11818; 
    background-color: #f22424;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(247, 110, 110, .5);
}

.btn-light
{
    color: #1a1e2c;
    border-color: #f5f5f5; 
    background-color: #f5f5f5;
}
.btn-light:hover
{
    color: #1a1e2c;
    border-color: gainsboro; 
    background-color: #e2e2e2;
}
.btn-light:focus,
.btn-light.focus
{
    color: #1a1e2c;
    border-color: gainsboro;
    background-color: #e2e2e2;
    box-shadow: 0 0 0 .2rem rgba(212, 213, 215, .5);
}
.btn-light.disabled,
.btn-light:disabled
{
    color: #1a1e2c;
    border-color: #f5f5f5; 
    background-color: #f5f5f5;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle
{
    color: #fff;
    border-color: #d5d5d5; 
    background-color: gainsboro;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(212, 213, 215, .5);
}

.btn-dark
{
    color: #fff;
    border-color: #343a40; 
    background-color: #343a40;
}
.btn-dark:hover
{
    color: #fff;
    border-color: #1d2124; 
    background-color: #23272b;
}
.btn-dark:focus,
.btn-dark.focus
{
    color: #fff;
    border-color: #1d2124;
    background-color: #23272b;
    box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5);
}
.btn-dark.disabled,
.btn-dark:disabled
{
    color: #fff;
    border-color: #343a40; 
    background-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle
{
    color: #fff;
    border-color: #171a1d; 
    background-color: #1d2124;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5);
}

.btn-blue-light
{
    color: #1a1e2c;
    border-color: #f3f8fc; 
    background-color: #f3f8fc;
}
.btn-blue-light:hover
{
    color: #1a1e2c;
    border-color: #cae0f2; 
    background-color: #d4e6f4;
}
.btn-blue-light:focus,
.btn-blue-light.focus
{
    color: #1a1e2c;
    border-color: #cae0f2;
    background-color: #d4e6f4;
    box-shadow: 0 0 0 .2rem rgba(210, 215, 221, .5);
}
.btn-blue-light.disabled,
.btn-blue-light:disabled
{
    color: #1a1e2c;
    border-color: #f3f8fc; 
    background-color: #f3f8fc;
}
.btn-blue-light:not(:disabled):not(.disabled):active,
.btn-blue-light:not(:disabled):not(.disabled).active,
.show > .btn-blue-light.dropdown-toggle
{
    color: #fff;
    border-color: #c0daef; 
    background-color: #cae0f2;
}
.btn-blue-light:not(:disabled):not(.disabled):active:focus,
.btn-blue-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue-light.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(210, 215, 221, .5);
}

.btn-outline-primary
{
    color: #f12639;
    border-color: #f12639;
}
.btn-outline-primary:hover
{
    color: #fff;
    border-color: #f12639; 
    background-color: #f12639;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus
{
    box-shadow: 0 0 0 .2rem rgba(241, 38, 57, .5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled
{
    color: #f12639;
    background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle
{
    color: #fff;
    border-color: #f12639; 
    background-color: #f12639;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(241, 38, 57, .5);
}

.btn-outline-secondary
{
    color: #1f2749;
    border-color: #1f2749;
}
.btn-outline-secondary:hover
{
    color: #fff;
    border-color: #1f2749; 
    background-color: #1f2749;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus
{
    box-shadow: 0 0 0 .2rem rgba(31, 39, 73, .5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled
{
    color: #1f2749;
    background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle
{
    color: #fff;
    border-color: #1f2749; 
    background-color: #1f2749;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(31, 39, 73, .5);
}

.btn-outline-success
{
    color: #2ecd75;
    border-color: #2ecd75;
}
.btn-outline-success:hover
{
    color: #fff;
    border-color: #2ecd75; 
    background-color: #2ecd75;
}
.btn-outline-success:focus,
.btn-outline-success.focus
{
    box-shadow: 0 0 0 .2rem rgba(46, 205, 117, .5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled
{
    color: #2ecd75;
    background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle
{
    color: #fff;
    border-color: #2ecd75; 
    background-color: #2ecd75;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(46, 205, 117, .5);
}

.btn-outline-info
{
    color: #41c6f2;
    border-color: #41c6f2;
}
.btn-outline-info:hover
{
    color: #fff;
    border-color: #41c6f2; 
    background-color: #41c6f2;
}
.btn-outline-info:focus,
.btn-outline-info.focus
{
    box-shadow: 0 0 0 .2rem rgba(65, 198, 242, .5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled
{
    color: #41c6f2;
    background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle
{
    color: #fff;
    border-color: #41c6f2; 
    background-color: #41c6f2;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(65, 198, 242, .5);
}

.btn-outline-warning
{
    color: #fbcc5d;
    border-color: #fbcc5d;
}
.btn-outline-warning:hover
{
    color: #fff;
    border-color: #fbcc5d; 
    background-color: #fbcc5d;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus
{
    box-shadow: 0 0 0 .2rem rgba(251, 204, 93, .5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled
{
    color: #fbcc5d;
    background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle
{
    color: #fff;
    border-color: #fbcc5d; 
    background-color: #fbcc5d;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(251, 204, 93, .5);
}

.btn-outline-danger
{
    color: #f55454;
    border-color: #f55454;
}
.btn-outline-danger:hover
{
    color: #fff;
    border-color: #f55454; 
    background-color: #f55454;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus
{
    box-shadow: 0 0 0 .2rem rgba(245, 84, 84, .5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled
{
    color: #f55454;
    background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle
{
    color: #fff;
    border-color: #f55454; 
    background-color: #f55454;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(245, 84, 84, .5);
}

.btn-outline-light
{
    color: #f5f5f5;
    border-color: #f5f5f5;
}
.btn-outline-light:hover
{
    color: #1a1e2c;
    border-color: #f5f5f5; 
    background-color: #f5f5f5;
}
.btn-outline-light:focus,
.btn-outline-light.focus
{
    box-shadow: 0 0 0 .2rem rgba(245, 245, 245, .5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled
{
    color: #f5f5f5;
    background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle
{
    color: #1a1e2c;
    border-color: #f5f5f5; 
    background-color: #f5f5f5;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(245, 245, 245, .5);
}

.btn-outline-dark
{
    color: #343a40;
    border-color: #343a40;
}
.btn-outline-dark:hover
{
    color: #fff;
    border-color: #343a40; 
    background-color: #343a40;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus
{
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled
{
    color: #343a40;
    background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle
{
    color: #fff;
    border-color: #343a40; 
    background-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
}

.btn-outline-blue-light
{
    color: #f3f8fc;
    border-color: #f3f8fc;
}
.btn-outline-blue-light:hover
{
    color: #1a1e2c;
    border-color: #f3f8fc; 
    background-color: #f3f8fc;
}
.btn-outline-blue-light:focus,
.btn-outline-blue-light.focus
{
    box-shadow: 0 0 0 .2rem rgba(243, 248, 252, .5);
}
.btn-outline-blue-light.disabled,
.btn-outline-blue-light:disabled
{
    color: #f3f8fc;
    background-color: transparent;
}
.btn-outline-blue-light:not(:disabled):not(.disabled):active,
.btn-outline-blue-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue-light.dropdown-toggle
{
    color: #1a1e2c;
    border-color: #f3f8fc; 
    background-color: #f3f8fc;
}
.btn-outline-blue-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-blue-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue-light.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(243, 248, 252, .5);
}

.btn-link
{
    font-weight: 400;

    text-decoration: none; 

    color: #f12639;
}
.btn-link:hover
{
    text-decoration: underline; 

    color: #be0c1d;
}
.btn-link:focus,
.btn-link.focus
{
    text-decoration: underline;
}
.btn-link:disabled,
.btn-link.disabled
{
    pointer-events: none; 

    color: #6c757d;
}

.btn-lg,
.btn-group-lg > .btn
{
    font-size: 1.40625rem;
    line-height: 1.5;

    padding: 1.032rem 1rem;

    border-radius: 0;
}

.btn-sm,
.btn-group-sm > .btn
{
    font-size: .94219rem;
    line-height: 1.5;

    padding: .25rem .5rem;

    border-radius: 0;
}

.btn-block
{
    display: block;

    width: 100%;
}
.btn-block + .btn-block
{
    margin-top: .5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block
{
    width: 100%;
}

.fade
{
    transition: opacity .15s linear;
}
@media (prefers-reduced-motion: reduce)
{
    .fade
    {
        transition: none;
    }
}
.fade:not(.show)
{
    opacity: 0;
}

.collapse:not(.show)
{
    display: none;
}

.collapsing
{
    position: relative;

    overflow: hidden;

    height: 0;

    transition: height .35s ease;
}
@media (prefers-reduced-motion: reduce)
{
    .collapsing
    {
        transition: none;
    }
}

.dropup,
.dropright,
.dropdown,
.dropleft
{
    position: relative;
}

.dropdown-toggle
{
    white-space: nowrap;
}
.dropdown-toggle::after
{
    display: inline-block;

    margin-left: .255em;

    content: '';
    vertical-align: .255em;

    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropdown-menu
{
    font-size: 1.125rem;

    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 0;

    display: none;
    float: left;

    min-width: 10rem;
    margin: .125rem 0 0;
    padding: .5rem 0;

    list-style: none;

    text-align: left;

    color: #1a1e2c;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem; 
    background-color: #fff;
    background-clip: padding-box;
}

.dropdown-menu-left
{
    right: auto;
    left: 0;
}

.dropdown-menu-right
{
    right: 0;
    left: auto;
}

@media (min-width: 576px)
{
    .dropdown-menu-sm-left
    {
        right: auto;
        left: 0;
    }
    .dropdown-menu-sm-right
    {
        right: 0;
        left: auto;
    }
}

@media (min-width: 768px)
{
    .dropdown-menu-md-left
    {
        right: auto;
        left: 0;
    }
    .dropdown-menu-md-right
    {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px)
{
    .dropdown-menu-lg-left
    {
        right: auto;
        left: 0;
    }
    .dropdown-menu-lg-right
    {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1200px)
{
    .dropdown-menu-xl-left
    {
        right: auto;
        left: 0;
    }
    .dropdown-menu-xl-right
    {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1600px)
{
    .dropdown-menu-xxl-left
    {
        right: auto;
        left: 0;
    }
    .dropdown-menu-xxl-right
    {
        right: 0;
        left: auto;
    }
}

.dropup .dropdown-menu
{
    top: auto;
    bottom: 100%;

    margin-top: 0;
    margin-bottom: .125rem;
}

.dropup .dropdown-toggle::after
{
    display: inline-block;

    margin-left: .255em;

    content: '';
    vertical-align: .255em;

    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent;
}

.dropup .dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropright .dropdown-menu
{
    top: 0;
    right: auto;
    left: 100%;

    margin-top: 0;
    margin-left: .125rem;
}

.dropright .dropdown-toggle::after
{
    display: inline-block;

    margin-left: .255em;

    content: '';
    vertical-align: .255em;

    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
}

.dropright .dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropright .dropdown-toggle::after
{
    vertical-align: 0;
}

.dropleft .dropdown-menu
{
    top: 0;
    right: 100%;
    left: auto;

    margin-top: 0;
    margin-right: .125rem;
}

.dropleft .dropdown-toggle::after
{
    display: inline-block;

    margin-left: .255em;

    content: ''; 
    vertical-align: .255em;
}

.dropleft .dropdown-toggle::after
{
    display: none;
}

.dropleft .dropdown-toggle::before
{
    display: inline-block;

    margin-right: .255em;

    content: '';
    vertical-align: .255em;

    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropleft .dropdown-toggle::before
{
    vertical-align: 0;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left']
{
    right: auto;
    bottom: auto;
}

.dropdown-divider
{
    overflow: hidden;

    height: 0;
    margin: .5rem 0;

    border-top: 1px solid #e8e8e9;
}

.dropdown-item
{
    font-weight: 400;

    display: block;
    clear: both;

    width: 100%;
    padding: .25rem 1.5rem;

    text-align: inherit;
    white-space: nowrap;

    color: #1a1e2c;
    border: 0; 
    background-color: transparent;
}
.dropdown-item:hover,
.dropdown-item:focus
{
    text-decoration: none;

    color: #11131c;
    background-color: #f5f5f5;
}
.dropdown-item.active,
.dropdown-item:active
{
    text-decoration: none;

    color: #fff;
    background-color: #f12639;
}
.dropdown-item.disabled,
.dropdown-item:disabled
{
    pointer-events: none;

    color: #6c757d;
    background-color: transparent;
}

.dropdown-menu.show
{
    display: block;
}

.dropdown-header
{
    font-size: .94219rem;

    display: block;

    margin-bottom: 0;
    padding: .5rem 1.5rem;

    white-space: nowrap; 

    color: #6c757d;
}

.dropdown-item-text
{
    display: block;

    padding: .25rem 1.5rem;

    color: #1a1e2c;
}

.btn-group,
.btn-group-vertical
{
    position: relative;

    display: inline-flex;

    vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn
{
    position: relative;

    flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover
{
    z-index: 1;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active
{
    z-index: 1;
}

.btn-toolbar
{
    display: flex;

    flex-wrap: wrap;
    justify-content: flex-start;
}
.btn-toolbar .input-group
{
    width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child)
{
    margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split
{
    padding-right: .5625rem;
    padding-left: .5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after
{
    margin-left: 0;
}
.dropleft .dropdown-toggle-split::before
{
    margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split
{
    padding-right: .375rem;
    padding-left: .375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split
{
    padding-right: .75rem;
    padding-left: .75rem;
}

.btn-group-vertical
{
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group
{
    width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child)
{
    margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn
{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn
{
    margin-bottom: 0;
}
.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox']
{
    position: absolute;

    clip: rect(0, 0, 0, 0);

    pointer-events: none;
}

.input-group
{
    position: relative;

    display: flex;

    width: 100%; 

    flex-wrap: wrap;
    align-items: stretch;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file
{
    position: relative;

    width: 1%;
    min-width: 0;
    margin-bottom: 0; 

    flex: 1 1 auto;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file
{
    margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label
{
    z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus
{
    z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child)
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child)
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group > .custom-file
{
    display: flex;

    align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append
{
    display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn
{
    position: relative;
    z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus
{
    z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn
{
    margin-left: -1px;
}

.input-group-prepend
{
    margin-right: -1px;
}

.input-group-append
{
    margin-left: -1px;
}

.input-group-text
{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;

    display: flex;

    margin-bottom: 0;
    padding: .8rem .75rem;

    text-align: center;
    white-space: nowrap;

    color: #343a40;
    border: 1px solid #cdced3;
    border-radius: 0; 
    background-color: #e8e8e9;

    align-items: center;
}
.input-group-text input[type='radio'],
.input-group-text input[type='checkbox']
{
    margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select
{
    height: calc(3.75038rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn
{
    font-size: 1.125rem;
    line-height: 1.2;

    padding: .8rem 1rem;

    border-radius: 0;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select
{
    height: calc(2.18828rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn
{
    font-size: .875rem;
    line-height: 1;

    padding: .8rem .5rem;

    border-radius: 0;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select
{
    padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child)
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child)
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control
{
    position: relative;
    z-index: 1;

    display: block;

    min-height: 1.6875rem;
    padding-left: 1.5rem;
}

.custom-control-inline
{
    display: inline-flex;

    margin-right: 1rem;
}

.custom-control-input
{
    position: absolute;
    z-index: -1;
    left: 0;

    width: 1rem;
    height: 1.34375rem;

    opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before
{
    color: #fff;
    border-color: #f12639;
    background-color: #f12639;
}
.custom-control-input:focus ~ .custom-control-label::before
{
    box-shadow: 0 0 0 .2rem rgba(241, 38, 57, .25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before
{
    border-color: #f99ea6;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before
{
    color: #fff;
    border-color: #fcced2; 
    background-color: #fcced2;
}
.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label
{
    color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before
{
    background-color: #e8e8e9;
}

.custom-control-label
{
    position: relative;

    margin-bottom: 0;

    vertical-align: top;
}
.custom-control-label::before
{
    position: absolute;
    top: .34375rem;
    left: -1.5rem;

    display: block;

    width: 1rem;
    height: 1rem;

    content: '';
    pointer-events: none;

    border: #87888d solid 1px; 
    background-color: #fff;
}
.custom-control-label::after
{
    position: absolute;
    top: .34375rem;
    left: -1.5rem;

    display: block;

    width: 1rem;
    height: 1rem;

    content: '';

    background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before
{
    border-radius: .25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after
{
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%23fff\' d=\'M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z\'/%3e%3c/svg%3e');
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before
{
    border-color: #f12639;
    background-color: #f12639;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after
{
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 4\'%3e%3cpath stroke=\'%23fff\' d=\'M0 2h4\'/%3e%3c/svg%3e');
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before
{
    background-color: rgba(241, 38, 57, .5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before
{
    background-color: rgba(241, 38, 57, .5);
}

.custom-radio .custom-control-label::before
{
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after
{
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'-4 -4 8 8\'%3e%3ccircle r=\'3\' fill=\'%23fff\'/%3e%3c/svg%3e');
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before
{
    background-color: rgba(241, 38, 57, .5);
}

.custom-switch
{
    padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before
{
    left: -2.25rem;

    width: 1.75rem;

    pointer-events: all;

    border-radius: .5rem;
}
.custom-switch .custom-control-label::after
{
    top: calc(.34375rem + 2px);
    left: calc(-2.25rem + 2px);

    width: calc(1rem - 4px);
    height: calc(1rem - 4px);

    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; 

    border-radius: .5rem;
    background-color: #87888d;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-switch .custom-control-label::after
    {
        transition: none;
    }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after
{
    transform: translateX(.75rem); 

    background-color: #fff;
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before
{
    background-color: rgba(241, 38, 57, .5);
}

.custom-select
{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;

    display: inline-block;

    width: 100%;
    height: calc(2.9375rem + 2px);
    padding: .8rem 1.75rem .8rem .75rem;

    vertical-align: middle;

    color: #343a40;
    border: 1px solid #cdced3;
    border-radius: .25rem;
    background: #fff url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'%3e%3cpath fill=\'%23343a40\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/%3e%3c/svg%3e') no-repeat right .75rem center/8px 10px;

    appearance: none;
}
.custom-select:focus
{
    border-color: #f99ea6;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(241, 38, 57, .25);
}
.custom-select:focus::-ms-value
{
    color: #343a40;
    background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size='1'])
{
    height: auto;
    padding-right: .75rem;

    background-image: none;
}
.custom-select:disabled
{
    color: #6c757d;
    background-color: #e8e8e9;
}
.custom-select::-ms-expand
{
    display: none;
}
.custom-select:-moz-focusring
{
    color: transparent;
    text-shadow: 0 0 0 #343a40;
}

.custom-select-sm
{
    font-size: .875rem; 

    height: calc(2.18828rem + 2px);
    padding-top: .8rem;
    padding-bottom: .8rem;
    padding-left: .5rem;
}

.custom-select-lg
{
    font-size: 1.125rem; 

    height: calc(3.75038rem + 2px);
    padding-top: .8rem;
    padding-bottom: .8rem;
    padding-left: 1rem;
}

.custom-file
{
    position: relative;

    display: inline-block;

    width: 100%;
    height: calc(2.9375rem + 2px);
    margin-bottom: 0;
}

.custom-file-input
{
    position: relative;
    z-index: 2;

    width: 100%;
    height: calc(2.9375rem + 2px);
    margin: 0;

    opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label
{
    border-color: #f99ea6;
    box-shadow: 0 0 0 .2rem rgba(241, 38, 57, .25);
}
.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label
{
    background-color: #e8e8e9;
}
.custom-file-input:lang(en) ~ .custom-file-label::after
{
    content: 'Browse';
}
.custom-file-input ~ .custom-file-label[data-browse]::after
{
    content: attr(data-browse);
}

.custom-file-label
{
    font-weight: 400;
    line-height: 1.2;

    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;

    height: calc(2.9375rem + 2px);
    padding: .8rem .75rem;

    color: #343a40;
    border: 1px solid #cdced3;
    border-radius: 0; 
    background-color: #fff;
}
.custom-file-label::after
{
    line-height: 1.2;

    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;

    display: block;

    height: 2.9375rem;
    padding: .8rem .75rem;

    content: 'Browse';

    color: #343a40;
    border-left: inherit;
    border-radius: 0 0 0 0; 
    background-color: #e8e8e9;
}

.custom-range
{
    width: 100%;
    height: 1.4rem;
    padding: 0;

    background-color: transparent;

    appearance: none;
}
.custom-range:focus
{
    outline: none;
}
.custom-range:focus::-webkit-slider-thumb
{
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(241, 38, 57, .25);
}
.custom-range:focus::-moz-range-thumb
{
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(241, 38, 57, .25);
}
.custom-range:focus::-ms-thumb
{
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(241, 38, 57, .25);
}
.custom-range::-moz-focus-outer
{
    border: 0;
}
.custom-range::-webkit-slider-thumb
{
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;

    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    border: 0;
    border-radius: 1rem;
    background-color: #f12639;

    appearance: none;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-range::-webkit-slider-thumb
    {
        transition: none;
    }
}
.custom-range::-webkit-slider-thumb:active
{
    background-color: #fcced2;
}
.custom-range::-webkit-slider-runnable-track
{
    width: 100%;
    height: .5rem;

    cursor: pointer;

    color: transparent;
    border-color: transparent;
    border-radius: 1rem; 
    background-color: #d2d4db;
}
.custom-range::-moz-range-thumb
{
    width: 1rem;
    height: 1rem;

    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    border: 0;
    border-radius: 1rem;
    background-color: #f12639;

    appearance: none;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-range::-moz-range-thumb
    {
        transition: none;
    }
}
.custom-range::-moz-range-thumb:active
{
    background-color: #fcced2;
}
.custom-range::-moz-range-track
{
    width: 100%;
    height: .5rem;

    cursor: pointer;

    color: transparent;
    border-color: transparent;
    border-radius: 1rem; 
    background-color: #d2d4db;
}
.custom-range::-ms-thumb
{
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: .2rem;
    margin-left: .2rem;

    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    border: 0;
    border-radius: 1rem;
    background-color: #f12639;

    appearance: none;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-range::-ms-thumb
    {
        transition: none;
    }
}
.custom-range::-ms-thumb:active
{
    background-color: #fcced2;
}
.custom-range::-ms-track
{
    width: 100%;
    height: .5rem;

    cursor: pointer;

    color: transparent;
    border-width: .5rem; 
    border-color: transparent;
    background-color: transparent;
}
.custom-range::-ms-fill-lower
{
    border-radius: 1rem; 
    background-color: #d2d4db;
}
.custom-range::-ms-fill-upper
{
    margin-right: 15px;

    border-radius: 1rem; 
    background-color: #d2d4db;
}
.custom-range:disabled::-webkit-slider-thumb
{
    background-color: #87888d;
}
.custom-range:disabled::-webkit-slider-runnable-track
{
    cursor: default;
}
.custom-range:disabled::-moz-range-thumb
{
    background-color: #87888d;
}
.custom-range:disabled::-moz-range-track
{
    cursor: default;
}
.custom-range:disabled::-ms-thumb
{
    background-color: #87888d;
}

.custom-control-label::before,
.custom-file-label,
.custom-select
{
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-control-label::before,
    .custom-file-label,
    .custom-select
    {
        transition: none;
    }
}

.nav
{
    display: flex;

    margin-bottom: 0;
    padding-left: 0;

    list-style: none; 

    flex-wrap: wrap;
}

.nav-link
{
    display: block;

    padding: .5rem 1rem;
}
.nav-link:hover,
.nav-link:focus
{
    text-decoration: none;
}
.nav-link.disabled
{
    cursor: default; 
    pointer-events: none;

    color: #6c757d;
}

.nav-tabs
{
    border-bottom: 1px solid #d2d4db;
}
.nav-tabs .nav-item
{
    margin-bottom: -1px;
}
.nav-tabs .nav-link
{
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus
{
    border-color: #e8e8e9 #e8e8e9 #d2d4db;
}
.nav-tabs .nav-link.disabled
{
    color: #6c757d;
    border-color: transparent; 
    background-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link
{
    color: #5a5c63;
    border-color: #d2d4db #d2d4db #fff; 
    background-color: #fff;
}
.nav-tabs .dropdown-menu
{
    margin-top: -1px;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link
{
    border-radius: .25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link
{
    color: #fff;
    background-color: #f12639;
}

.nav-fill > .nav-link,
.nav-fill .nav-item
{
    text-align: center; 

    flex: 1 1 auto;
}

.nav-justified > .nav-link,
.nav-justified .nav-item
{
    text-align: center; 

    flex-basis: 0;
    flex-grow: 1;
}

.tab-content > .tab-pane
{
    display: none;
}

.tab-content > .active
{
    display: block;
}

.navbar
{
    position: relative;

    display: flex;

    padding: .5rem 1rem; 

    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl
{
    display: flex;

    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand
{
    font-size: 1.40625rem;
    line-height: inherit;

    display: inline-block;

    margin-right: 1rem;
    padding-top: 0;
    padding-bottom: 0;

    white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus
{
    text-decoration: none;
}

.navbar-nav
{
    display: flex;
    flex-direction: column;

    margin-bottom: 0;
    padding-left: 0;

    list-style: none;
}
.navbar-nav .nav-link
{
    padding-right: 0;
    padding-left: 0;
}
.navbar-nav .dropdown-menu
{
    position: static;

    float: none;
}

.navbar-text
{
    display: inline-block;

    padding-top: .5rem;
    padding-bottom: .5rem;
}

.navbar-collapse
{
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.navbar-toggler
{
    font-size: 1.40625rem;
    line-height: 1;

    padding: .25rem .75rem;

    border: 1px solid transparent;
    border-radius: 0; 
    background-color: transparent;
}
.navbar-toggler:hover,
.navbar-toggler:focus
{
    text-decoration: none;
}

.navbar-toggler-icon
{
    display: inline-block;

    width: 1.5em;
    height: 1.5em;

    content: '';
    vertical-align: middle;

    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px)
{
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-xl,
    .navbar-expand-sm > .container-xxl
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px)
{
    .navbar-expand-sm
    {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav
    {
        flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link
    {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-sm .navbar-collapse
    {
        display: flex !important;

        flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler
    {
        display: none;
    }
}

@media (max-width: 767.98px)
{
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-xl,
    .navbar-expand-md > .container-xxl
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px)
{
    .navbar-expand-md
    {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav
    {
        flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link
    {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-md .navbar-collapse
    {
        display: flex !important;

        flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler
    {
        display: none;
    }
}

@media (max-width: 991.98px)
{
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-xl,
    .navbar-expand-lg > .container-xxl
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px)
{
    .navbar-expand-lg
    {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav
    {
        flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link
    {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-lg .navbar-collapse
    {
        display: flex !important;

        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler
    {
        display: none;
    }
}

@media (max-width: 1199.98px)
{
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-xl,
    .navbar-expand-xl > .container-xxl
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px)
{
    .navbar-expand-xl
    {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav
    {
        flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link
    {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-xl .navbar-collapse
    {
        display: flex !important;

        flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler
    {
        display: none;
    }
}

@media (max-width: 1599.98px)
{
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid,
    .navbar-expand-xxl > .container-sm,
    .navbar-expand-xxl > .container-md,
    .navbar-expand-xxl > .container-lg,
    .navbar-expand-xxl > .container-xl,
    .navbar-expand-xxl > .container-xxl
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1600px)
{
    .navbar-expand-xxl
    {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-xxl .navbar-nav
    {
        flex-direction: row;
    }
    .navbar-expand-xxl .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-xxl .navbar-nav .nav-link
    {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-xxl .navbar-collapse
    {
        display: flex !important;

        flex-basis: auto;
    }
    .navbar-expand-xxl .navbar-toggler
    {
        display: none;
    }
}

.navbar-expand
{
    flex-wrap: nowrap;
    justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl
{
    padding-right: 0;
    padding-left: 0;
}
.navbar-expand .navbar-nav
{
    flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu
{
    position: absolute;
}
.navbar-expand .navbar-nav .nav-link
{
    padding-right: .5rem;
    padding-left: .5rem;
}
.navbar-expand .navbar-collapse
{
    display: flex !important;

    flex-basis: auto;
}
.navbar-expand .navbar-toggler
{
    display: none;
}

.navbar-light .navbar-brand
{
    color: rgba(0, 0, 0, .9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus
{
    color: rgba(0, 0, 0, .9);
}

.navbar-light .navbar-nav .nav-link
{
    color: rgba(0, 0, 0, .5);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus
{
    color: rgba(0, 0, 0, .7);
}
.navbar-light .navbar-nav .nav-link.disabled
{
    color: rgba(0, 0, 0, .3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active
{
    color: rgba(0, 0, 0, .9);
}

.navbar-light .navbar-toggler
{
    color: rgba(0, 0, 0, .5);
    border-color: rgba(0, 0, 0, .1);
}

.navbar-light .navbar-toggler-icon
{
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox=\'0 0 30 30\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath stroke=\'rgba%280, 0, 0, 0.5%29\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' d=\'M4 7h22M4 15h22M4 23h22\'/%3E%3C/svg%3E');
}

.navbar-light .navbar-text
{
    color: rgba(0, 0, 0, .5);
}
.navbar-light .navbar-text a
{
    color: rgba(0, 0, 0, .9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus
{
    color: rgba(0, 0, 0, .9);
}

.navbar-dark .navbar-brand
{
    color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus
{
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link
{
    color: #fff;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus
{
    color: #fff;
}
.navbar-dark .navbar-nav .nav-link.disabled
{
    color: rgba(255, 255, 255, .25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active
{
    color: #fff;
}

.navbar-dark .navbar-toggler
{
    color: #fff;
    border-color: rgba(255, 255, 255, .1);
}

.navbar-dark .navbar-toggler-icon
{
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox=\'0 0 30 30\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath stroke=\'%23fff\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' d=\'M4 7h22M4 15h22M4 23h22\'/%3E%3C/svg%3E');
}

.navbar-dark .navbar-text
{
    color: #fff;
}
.navbar-dark .navbar-text a
{
    color: #fff;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus
{
    color: #fff;
}

.card
{
    position: relative;

    display: flex;
    flex-direction: column;

    min-width: 0;

    word-wrap: break-word;

    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0; 
    background-color: #fff;
    background-clip: border-box;
}
.card > hr
{
    margin-right: 0;
    margin-left: 0;
}
.card > .list-group
{
    border-top: inherit;
    border-bottom: inherit;
}
.card > .list-group:first-child
{
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.card > .list-group:last-child
{
    border-bottom-width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer
{
    border-top: 0;
}

.card-body
{
    min-height: 1px;
    padding: 1.25rem; 

    flex: 1 1 auto;
}

.card-title
{
    margin-bottom: .75rem;
}

.card-subtitle
{
    margin-top: -.375rem;
    margin-bottom: 0;
}

.card-text:last-child
{
    margin-bottom: 0;
}

.card-link:hover
{
    text-decoration: none;
}

.card-link + .card-link
{
    margin-left: 1.25rem;
}

.card-header
{
    margin-bottom: 0;
    padding: .75rem 1.25rem;

    border-bottom: 1px solid rgba(0, 0, 0, .125); 
    background-color: rgba(0, 0, 0, .03);
}
.card-header:first-child
{
    border-radius: 0 0 0 0;
}

.card-footer
{
    padding: .75rem 1.25rem;

    border-top: 1px solid rgba(0, 0, 0, .125); 
    background-color: rgba(0, 0, 0, .03);
}
.card-footer:last-child
{
    border-radius: 0 0 0 0;
}

.card-header-tabs
{
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;

    border-bottom: 0;
}

.card-header-pills
{
    margin-right: -.625rem;
    margin-left: -.625rem;
}

.card-img-overlay
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 1.25rem;

    border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom
{
    width: 100%; 

    flex-shrink: 0;
}

.card-img,
.card-img-top
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.card-img,
.card-img-bottom
{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.card-deck .card
{
    margin-bottom: 15px;
}

@media (min-width: 576px)
{
    .card-deck
    {
        display: flex;

        margin-right: -15px;
        margin-left: -15px; 

        flex-flow: row wrap;
    }
    .card-deck .card
    {
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; 

        flex: 1 0;
    }
}

.card-group > .card
{
    margin-bottom: 15px;
}

@media (min-width: 576px)
{
    .card-group
    {
        display: flex;

        flex-flow: row wrap;
    }
    .card-group > .card
    {
        margin-bottom: 0; 

        flex: 1 0;
    }
    .card-group > .card + .card
    {
        margin-left: 0;

        border-left: 0;
    }
    .card-group > .card:not(:last-child)
    {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header
    {
        border-top-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer
    {
        border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:first-child)
    {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header
    {
        border-top-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer
    {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card
{
    margin-bottom: .75rem;
}

@media (min-width: 576px)
{
    .card-columns
    {
        column-count: 4;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
    .card-columns .card
    {
        display: inline-block;

        width: 100%;
    }
}

.accordion
{
    overflow-anchor: none;
}
.accordion > .card
{
    overflow: hidden;
}
.accordion > .card:not(:last-of-type)
{
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type)
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.accordion > .card > .card-header
{
    margin-bottom: -1px; 

    border-radius: 0;
}

.breadcrumb
{
    display: flex;

    margin-bottom: 1rem;
    padding: .75rem 1rem;

    list-style: none;

    border-radius: .25rem; 
    background-color: #e8e8e9;

    flex-wrap: wrap;
}

.breadcrumb-item
{
    display: flex;
}
.breadcrumb-item + .breadcrumb-item
{
    padding-left: .5rem;
}
.breadcrumb-item + .breadcrumb-item::before
{
    display: inline-block;

    padding-right: .5rem;

    content: '/'; 

    color: #6c757d;
}
.breadcrumb-item + .breadcrumb-item:hover::before
{
    text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before
{
    text-decoration: none;
}
.breadcrumb-item.active
{
    color: #6c757d;
}

.pagination
{
    display: flex;

    padding-left: 0;

    list-style: none;

    border-radius: .25rem;
}

.page-link
{
    line-height: 1.25;

    position: relative;

    display: block;

    margin-left: -1px;
    padding: .5rem .75rem;

    color: #5a5c63;
    border: 1px solid #d2d4db; 
    background-color: #fff;
}
.page-link:hover
{
    z-index: 2;

    text-decoration: none;

    color: #1a1e2c;
    border-color: #d2d4db; 
    background-color: #f5f5f5;
}
.page-link:focus
{
    z-index: 3;

    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(241, 38, 57, .25);
}

.page-item:first-child .page-link
{
    margin-left: 0;

    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.page-item:last-child .page-link
{
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

.page-item.active .page-link
{
    z-index: 3;

    color: #fff;
    border-color: #5a5c63; 
    background-color: #5a5c63;
}

.page-item.disabled .page-link
{
    cursor: auto;
    pointer-events: none;

    color: #87888d;
    border-color: #d2d4db; 
    background-color: #fff;
}

.pagination-lg .page-link
{
    font-size: 1.40625rem;
    line-height: 1.5; 

    padding: .75rem 1.5rem;
}

.pagination-lg .page-item:first-child .page-link
{
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link
{
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
}

.pagination-sm .page-link
{
    font-size: .94219rem;
    line-height: 1.5; 

    padding: .25rem .5rem;
}

.pagination-sm .page-item:first-child .page-link
{
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link
{
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem;
}

.badge
{
    font-size: 75%;
    font-weight: 700;
    line-height: 1;

    display: inline-block;

    padding: .25em .4em;

    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; 
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;

    border-radius: 0;
}
@media (prefers-reduced-motion: reduce)
{
    .badge
    {
        transition: none;
    }
}
a.badge:hover,
a.badge:focus
{
    text-decoration: none;
}
.badge:empty
{
    display: none;
}

.btn .badge
{
    position: relative;
    top: -1px;
}

.badge-pill
{
    padding-right: .6em;
    padding-left: .6em;

    border-radius: 10rem;
}

.badge-primary
{
    color: #fff;
    background-color: #f12639;
}
a.badge-primary:hover,
a.badge-primary:focus
{
    color: #fff;
    background-color: #d60e21;
}
a.badge-primary:focus,
a.badge-primary.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(241, 38, 57, .5);
}

.badge-secondary
{
    color: #fff;
    background-color: #1f2749;
}
a.badge-secondary:hover,
a.badge-secondary:focus
{
    color: #fff;
    background-color: #101425;
}
a.badge-secondary:focus,
a.badge-secondary.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(31, 39, 73, .5);
}

.badge-success
{
    color: #fff;
    background-color: #2ecd75;
}
a.badge-success:hover,
a.badge-success:focus
{
    color: #fff;
    background-color: #25a35d;
}
a.badge-success:focus,
a.badge-success.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(46, 205, 117, .5);
}

.badge-info
{
    color: #fff;
    background-color: #41c6f2;
}
a.badge-info:hover,
a.badge-info:focus
{
    color: #fff;
    background-color: #11b8ef;
}
a.badge-info:focus,
a.badge-info.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(65, 198, 242, .5);
}

.badge-warning
{
    color: #fff;
    background-color: #fbcc5d;
}
a.badge-warning:hover,
a.badge-warning:focus
{
    color: #fff;
    background-color: #fabc2b;
}
a.badge-warning:focus,
a.badge-warning.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(251, 204, 93, .5);
}

.badge-danger
{
    color: #fff;
    background-color: #f55454;
}
a.badge-danger:hover,
a.badge-danger:focus
{
    color: #fff;
    background-color: #f22424;
}
a.badge-danger:focus,
a.badge-danger.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(245, 84, 84, .5);
}

.badge-light
{
    color: #1a1e2c;
    background-color: #f5f5f5;
}
a.badge-light:hover,
a.badge-light:focus
{
    color: #1a1e2c;
    background-color: gainsboro;
}
a.badge-light:focus,
a.badge-light.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(245, 245, 245, .5);
}

.badge-dark
{
    color: #fff;
    background-color: #343a40;
}
a.badge-dark:hover,
a.badge-dark:focus
{
    color: #fff;
    background-color: #1d2124;
}
a.badge-dark:focus,
a.badge-dark.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
}

.badge-blue-light
{
    color: #1a1e2c;
    background-color: #f3f8fc;
}
a.badge-blue-light:hover,
a.badge-blue-light:focus
{
    color: #1a1e2c;
    background-color: #cae0f2;
}
a.badge-blue-light:focus,
a.badge-blue-light.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(243, 248, 252, .5);
}

.jumbotron
{
    margin-bottom: 2rem;
    padding: 2rem 1rem;

    border-radius: .3rem; 
    background-color: #e8e8e9;
}
@media (min-width: 576px)
{
    .jumbotron
    {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid
{
    padding-right: 0;
    padding-left: 0;

    border-radius: 0;
}

.alert
{
    position: relative;

    margin-bottom: 1rem;
    padding: .75rem 1.25rem;

    border: 1px solid transparent;
    border-radius: 0;
}

.alert-heading
{
    color: inherit;
}

.alert-link
{
    font-weight: 700;
}

.alert-dismissible
{
    padding-right: 4.1875rem;
}
.alert-dismissible .close
{
    position: absolute;
    top: 0;
    right: 0;

    padding: .75rem 1.25rem;

    color: inherit;
}

.alert-primary
{
    color: #fef6f7;
    border-color: #f12639; 
    background-color: #f12639;
}
.alert-primary hr
{
    border-top-color: #ee0f24;
}
.alert-primary .alert-link
{
    color: #f9c8ce;
}

.alert-secondary
{
    color: #f6f6f8;
    border-color: #1f2749; 
    background-color: #1f2749;
}
.alert-secondary hr
{
    border-top-color: #171d37;
}
.alert-secondary .alert-link
{
    color: #d9d9e2;
}

.alert-success
{
    color: #f7fdf9;
    border-color: #2ecd75; 
    background-color: #2ecd75;
}
.alert-success hr
{
    border-top-color: #29b869;
}
.alert-success .alert-link
{
    color: #cef3da;
}

.alert-info
{
    color: #f7fdfe;
    border-color: #41c6f2; 
    background-color: #41c6f2;
}
.alert-info hr
{
    border-top-color: #29bff0;
}
.alert-info .alert-link
{
    color: #caf2f8;
}

.alert-warning
{
    color: #fffdf9;
    border-color: #fbcc5d; 
    background-color: #fbcc5d;
}
.alert-warning hr
{
    border-top-color: #fac444;
}
.alert-warning .alert-link
{
    color: #ffecc6;
}

.alert-danger
{
    color: #fff8f8;
    border-color: #f55454; 
    background-color: #f55454;
}
.alert-danger hr
{
    border-top-color: #f43c3c;
}
.alert-danger .alert-link
{
    color: #ffc5c5;
}

.alert-light
{
    color: white;
    border-color: whitesmoke; 
    background-color: whitesmoke;
}
.alert-light hr
{
    border-top-color: #e8e8e8;
}
.alert-light .alert-link
{
    color: #e6e6e6;
}

.alert-dark
{
    color: #f7f7f7;
    border-color: #343a40; 
    background-color: #343a40;
}
.alert-dark hr
{
    border-top-color: #292d32;
}
.alert-dark .alert-link
{
    color: #dedede;
}

.alert-blue-light
{
    color: white;
    border-color: #f3f8fc; 
    background-color: #f3f8fc;
}
.alert-blue-light hr
{
    border-top-color: #dfecf7;
}
.alert-blue-light .alert-link
{
    color: #e6e6e6;
}

@keyframes progress-bar-stripes
{
    from
    {
        background-position: 1rem 0;
    }
    to
    {
        background-position: 0 0;
    }
}

.progress
{
    font-size: .84375rem;
    line-height: 0;

    display: flex;
    overflow: hidden;

    height: 1rem;

    border-radius: .25rem; 
    background-color: #e8e8e9;
}

.progress-bar
{
    display: flex;
    overflow: hidden;
    flex-direction: column;

    transition: width .6s ease; 
    text-align: center;
    white-space: nowrap;

    color: #fff;
    background-color: #f12639;

    justify-content: center;
}
@media (prefers-reduced-motion: reduce)
{
    .progress-bar
    {
        transition: none;
    }
}

.progress-bar-striped
{
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated
{
    animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce)
{
    .progress-bar-animated
    {
        animation: none;
    }
}

.media
{
    display: flex;

    align-items: flex-start;
}

.media-body
{
    flex: 1 1;
}

.list-group
{
    display: flex;
    flex-direction: column;

    margin-bottom: 0;
    padding-left: 0;

    border-radius: 0;
}

.list-group-item-action
{
    width: 100%;

    text-align: inherit; 

    color: #5a5c63;
}
.list-group-item-action:hover,
.list-group-item-action:focus
{
    z-index: 1;

    text-decoration: none;

    color: #5a5c63;
    background-color: #f5f5f5;
}
.list-group-item-action:active
{
    color: #1a1e2c;
    background-color: #e8e8e9;
}

.list-group-item
{
    position: relative;

    display: block;

    padding: .75rem 1.25rem;

    border: 1px solid rgba(0, 0, 0, .125); 
    background-color: #fff;
}
.list-group-item:first-child
{
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}
.list-group-item:last-child
{
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled
{
    pointer-events: none;

    color: #6c757d;
    background-color: #fff;
}
.list-group-item.active
{
    z-index: 2;

    color: #fff;
    border-color: #f12639; 
    background-color: #f12639;
}
.list-group-item + .list-group-item
{
    border-top-width: 0;
}
.list-group-item + .list-group-item.active
{
    margin-top: -1px;

    border-top-width: 1px;
}

.list-group-horizontal
{
    flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child
{
    border-top-right-radius: 0; 
    border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child
{
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active
{
    margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item
{
    border-top-width: 1px;
    border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active
{
    margin-left: -1px;

    border-left-width: 1px;
}

@media (min-width: 576px)
{
    .list-group-horizontal-sm
    {
        flex-direction: row;
    }
    .list-group-horizontal-sm > .list-group-item:first-child
    {
        border-top-right-radius: 0; 
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item:last-child
    {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item.active
    {
        margin-top: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item
    {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item.active
    {
        margin-left: -1px;

        border-left-width: 1px;
    }
}

@media (min-width: 768px)
{
    .list-group-horizontal-md
    {
        flex-direction: row;
    }
    .list-group-horizontal-md > .list-group-item:first-child
    {
        border-top-right-radius: 0; 
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item:last-child
    {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item.active
    {
        margin-top: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item
    {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item.active
    {
        margin-left: -1px;

        border-left-width: 1px;
    }
}

@media (min-width: 992px)
{
    .list-group-horizontal-lg
    {
        flex-direction: row;
    }
    .list-group-horizontal-lg > .list-group-item:first-child
    {
        border-top-right-radius: 0; 
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item:last-child
    {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item.active
    {
        margin-top: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item
    {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item.active
    {
        margin-left: -1px;

        border-left-width: 1px;
    }
}

@media (min-width: 1200px)
{
    .list-group-horizontal-xl
    {
        flex-direction: row;
    }
    .list-group-horizontal-xl > .list-group-item:first-child
    {
        border-top-right-radius: 0; 
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item:last-child
    {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item.active
    {
        margin-top: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item
    {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item.active
    {
        margin-left: -1px;

        border-left-width: 1px;
    }
}

@media (min-width: 1600px)
{
    .list-group-horizontal-xxl
    {
        flex-direction: row;
    }
    .list-group-horizontal-xxl > .list-group-item:first-child
    {
        border-top-right-radius: 0; 
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xxl > .list-group-item:last-child
    {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xxl > .list-group-item.active
    {
        margin-top: 0;
    }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item
    {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item.active
    {
        margin-left: -1px;

        border-left-width: 1px;
    }
}

.list-group-flush
{
    border-radius: 0;
}
.list-group-flush > .list-group-item
{
    border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child
{
    border-bottom-width: 0;
}

.list-group-item-primary
{
    color: #7d141e;
    background-color: #fbc2c8;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus
{
    color: #7d141e;
    background-color: #f9aab2;
}
.list-group-item-primary.list-group-item-action.active
{
    color: #fff;
    border-color: #7d141e; 
    background-color: #7d141e;
}

.list-group-item-secondary
{
    color: #101426;
    background-color: #c0c3cc;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus
{
    color: #101426;
    background-color: #b2b6c1;
}
.list-group-item-secondary.list-group-item-action.active
{
    color: #fff;
    border-color: #101426; 
    background-color: #101426;
}

.list-group-item-success
{
    color: #186b3d;
    background-color: #c4f1d8;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus
{
    color: #186b3d;
    background-color: #afecca;
}
.list-group-item-success.list-group-item-action.active
{
    color: #fff;
    border-color: #186b3d; 
    background-color: #186b3d;
}

.list-group-item-info
{
    color: #22677e;
    background-color: #caeffb;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus
{
    color: #22677e;
    background-color: #b2e8f9;
}
.list-group-item-info.list-group-item-action.active
{
    color: #fff;
    border-color: #22677e; 
    background-color: #22677e;
}

.list-group-item-warning
{
    color: #836a30;
    background-color: #fef1d2;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus
{
    color: #836a30;
    background-color: #fde9b9;
}
.list-group-item-warning.list-group-item-action.active
{
    color: #fff;
    border-color: #836a30; 
    background-color: #836a30;
}

.list-group-item-danger
{
    color: #7f2c2c;
    background-color: #fccfcf;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus
{
    color: #7f2c2c;
    background-color: #fbb7b7;
}
.list-group-item-danger.list-group-item-action.active
{
    color: #fff;
    border-color: #7f2c2c; 
    background-color: #7f2c2c;
}

.list-group-item-light
{
    color: #7f7f7f;
    background-color: #fcfcfc;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus
{
    color: #7f7f7f;
    background-color: #efefef;
}
.list-group-item-light.list-group-item-action.active
{
    color: #fff;
    border-color: #7f7f7f; 
    background-color: #7f7f7f;
}

.list-group-item-dark
{
    color: #1b1e21;
    background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus
{
    color: #1b1e21;
    background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active
{
    color: #fff;
    border-color: #1b1e21; 
    background-color: #1b1e21;
}

.list-group-item-blue-light
{
    color: #7e8183;
    background-color: #fcfdfe;
}
.list-group-item-blue-light.list-group-item-action:hover,
.list-group-item-blue-light.list-group-item-action:focus
{
    color: #7e8183;
    background-color: #e9f0f8;
}
.list-group-item-blue-light.list-group-item-action.active
{
    color: #fff;
    border-color: #7e8183; 
    background-color: #7e8183;
}

.close
{
    font-size: 1.6875rem;
    font-weight: 700;
    line-height: 1;

    float: right;

    opacity: .5; 
    color: #fff;
    text-shadow: 0 1px 0 #fff;
}
.close:hover
{
    text-decoration: none; 

    color: #fff;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus
{
    opacity: .75;
}

button.close
{
    padding: 0;

    border: 0; 
    background-color: transparent;
}

a.close.disabled
{
    pointer-events: none;
}

.toast
{
    font-size: .875rem;

    max-width: 350px;

    opacity: 0;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: .25rem; 
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);

    flex-basis: 350px;
}
.toast:not(:last-child)
{
    margin-bottom: .75rem;
}
.toast.showing
{
    opacity: 1;
}
.toast.show
{
    display: block;

    opacity: 1;
}
.toast.hide
{
    display: none;
}

.toast-header
{
    display: flex;

    padding: .25rem .75rem;

    color: #6c757d;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px); 
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;

    align-items: center;
}

.toast-body
{
    padding: .75rem;
}

.modal-open
{
    overflow: hidden;
}
.modal-open .modal
{
    overflow-x: hidden;
    overflow-y: auto;
}

.modal
{
    position: fixed;
    z-index: 1050;
    top: 0;
    left: 0;

    display: none;
    overflow: hidden;

    width: 100%;
    height: 100%;

    outline: 0;
}

.modal-dialog
{
    position: relative;

    width: auto;
    margin: .5rem;

    pointer-events: none;
}
.modal.fade .modal-dialog
{
    transition: transform .3s ease-out;
    transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce)
{
    .modal.fade .modal-dialog
    {
        transition: none;
    }
}
.modal.show .modal-dialog
{
    transform: none;
}
.modal.modal-static .modal-dialog
{
    transform: scale(1.02);
}

.modal-dialog-scrollable
{
    display: flex;

    max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content
{
    overflow: hidden; 

    max-height: calc(100vh - 1rem);
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer
{
    flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body
{
    overflow-y: auto;
}

.modal-dialog-centered
{
    display: flex;

    min-height: calc(100% - 1rem); 

    align-items: center;
}
.modal-dialog-centered::before
{
    display: block;

    height: calc(100vh - 1rem);
    height: min-content;

    content: '';
}
.modal-dialog-centered.modal-dialog-scrollable
{
    flex-direction: column;

    height: 100%; 

    justify-content: center;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content
{
    max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before
{
    content: none;
}

.modal-content
{
    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;

    pointer-events: auto;

    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0;
    outline: 0; 
    background-color: #fff;
    background-clip: padding-box;
}

.modal-backdrop
{
    position: fixed;
    z-index: 1040;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: #000;
}
.modal-backdrop.fade
{
    opacity: 0;
}
.modal-backdrop.show
{
    opacity: .5;
}

.modal-header
{
    display: flex;

    padding: 1rem;

    border-bottom: 1px solid #e8e8e9;
    border-top-left-radius: 0;
    border-top-right-radius: 0; 

    align-items: flex-start;
    justify-content: space-between;
}
.modal-header .close
{
    margin: -1rem -1rem -1rem auto; 
    padding: 1rem;
}

.modal-title
{
    line-height: 1.5; 

    margin-bottom: 0;
}

.modal-body
{
    position: relative;

    padding: 1rem; 

    flex: 1 1 auto;
}

.modal-footer
{
    display: flex;

    padding: .75rem;

    border-top: 1px solid #e8e8e9;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; 

    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}
.modal-footer > *
{
    margin: .25rem;
}

.modal-scrollbar-measure
{
    position: absolute;
    top: -9999px;

    overflow: scroll; 

    width: 50px;
    height: 50px;
}

@media (min-width: 576px)
{
    .modal-dialog
    {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-scrollable
    {
        max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content
    {
        max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered
    {
        min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before
    {
        height: calc(100vh - 3.5rem);
        height: min-content;
    }
    .modal-sm
    {
        max-width: 300px;
    }
}

@media (min-width: 992px)
{
    .modal-lg,
    .modal-xl
    {
        max-width: 800px;
    }
}

@media (min-width: 1200px)
{
    .modal-xl
    {
        max-width: 1140px;
    }
}

.tooltip
{
    font-family: 'Roboto', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: .94219rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5;

    position: absolute;
    z-index: 1070;

    display: block;

    margin: 0;

    text-align: left;
    text-align: start;
    white-space: normal;
    text-decoration: none;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    word-wrap: break-word;
    word-break: normal;

    opacity: 0; 
    text-shadow: none;

    line-break: auto;
}
.tooltip.show
{
    opacity: .9;
}
.tooltip .arrow
{
    position: absolute;

    display: block;

    width: .8rem;
    height: .4rem;
}
.tooltip .arrow::before
{
    position: absolute;

    content: '';

    border-style: solid; 
    border-color: transparent;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top']
{
    padding: .4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^='top'] .arrow
{
    bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before
{
    top: 0;

    border-width: .4rem .4rem 0;
    border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right']
{
    padding: 0 .4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow
{
    left: 0;

    width: .4rem;
    height: .8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before
{
    right: 0;

    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom']
{
    padding: .4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .arrow
{
    top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before
{
    bottom: 0;

    border-width: 0 .4rem .4rem;
    border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left']
{
    padding: 0 .4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow
{
    right: 0;

    width: .4rem;
    height: .8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before
{
    left: 0;

    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000;
}

.tooltip-inner
{
    max-width: 200px;
    padding: .25rem .5rem;

    text-align: center;

    color: #fff;
    border-radius: .25rem; 
    background-color: #000;
}

.popover
{
    font-family: 'Roboto', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: .94219rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5;

    position: absolute;
    z-index: 1060;
    top: 0;
    left: 0;

    display: block;

    max-width: 276px;

    text-align: left;
    text-align: start;
    white-space: normal;
    text-decoration: none;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    word-wrap: break-word;
    word-break: normal;

    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem; 
    background-color: #fff;
    background-clip: padding-box;
    text-shadow: none;

    line-break: auto;
}
.popover .arrow
{
    position: absolute;

    display: block;

    width: 1rem;
    height: .5rem;
    margin: 0 .3rem;
}
.popover .arrow::before,
.popover .arrow::after
{
    position: absolute;

    display: block;

    content: '';

    border-style: solid; 
    border-color: transparent;
}

.bs-popover-top,
.bs-popover-auto[x-placement^='top']
{
    margin-bottom: .5rem;
}
.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^='top'] > .arrow
{
    bottom: calc(-.5rem - 1px);
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^='top'] > .arrow::before
{
    bottom: 0;

    border-width: .5rem .5rem 0;
    border-top-color: rgba(0, 0, 0, .25);
}
.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^='top'] > .arrow::after
{
    bottom: 1px;

    border-width: .5rem .5rem 0;
    border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^='right']
{
    margin-left: .5rem;
}
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^='right'] > .arrow
{
    left: calc(-.5rem - 1px);

    width: .5rem;
    height: 1rem;
    margin: .3rem 0;
}
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^='right'] > .arrow::before
{
    left: 0;

    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(0, 0, 0, .25);
}
.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^='right'] > .arrow::after
{
    left: 1px;

    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom']
{
    margin-top: .5rem;
}
.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^='bottom'] > .arrow
{
    top: calc(-.5rem - 1px);
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^='bottom'] > .arrow::before
{
    top: 0;

    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: rgba(0, 0, 0, .25);
}
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^='bottom'] > .arrow::after
{
    top: 1px;

    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^='bottom'] .popover-header::before
{
    position: absolute;
    top: 0;
    left: 50%;

    display: block;

    width: 1rem;
    margin-left: -.5rem;

    content: '';

    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^='left']
{
    margin-right: .5rem;
}
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^='left'] > .arrow
{
    right: calc(-.5rem - 1px);

    width: .5rem;
    height: 1rem;
    margin: .3rem 0;
}
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^='left'] > .arrow::before
{
    right: 0;

    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(0, 0, 0, .25);
}
.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^='left'] > .arrow::after
{
    right: 1px;

    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff;
}

.popover-header
{
    font-size: 1.125rem;

    margin-bottom: 0;
    padding: .5rem .75rem;

    color: inherit;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px); 
    background-color: #f7f7f7;
}
.popover-header:empty
{
    display: none;
}

.popover-body
{
    padding: .5rem .75rem;

    color: #1a1e2c;
}

.carousel
{
    position: relative;
}

.carousel.pointer-event
{
    touch-action: pan-y;
}

.carousel-inner
{
    position: relative;

    overflow: hidden; 

    width: 100%;
}
.carousel-inner::after
{
    display: block;
    clear: both;

    content: '';
}

.carousel-item
{
    position: relative;

    display: none;
    float: left;

    width: 100%;
    margin-right: -100%;

    transition: transform .6s ease; 

    backface-visibility: hidden;
}
@media (prefers-reduced-motion: reduce)
{
    .carousel-item
    {
        transition: none;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev
{
    display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right
{
    transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left
{
    transform: translateX(-100%);
}

.carousel-fade .carousel-item
{
    transition-property: opacity;
    transform: none; 

    opacity: 0;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right
{
    z-index: 1;

    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right
{
    z-index: 0;

    transition: opacity 0s .6s; 

    opacity: 0;
}
@media (prefers-reduced-motion: reduce)
{
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right
    {
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next
{
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;

    display: flex;

    width: 15%;

    transition: opacity .15s ease; 
    text-align: center;

    opacity: .5;
    color: #fff;

    align-items: center;
    justify-content: center;
}
@media (prefers-reduced-motion: reduce)
{
    .carousel-control-prev,
    .carousel-control-next
    {
        transition: none;
    }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus
{
    text-decoration: none;

    opacity: .9; 
    color: #fff;
    outline: 0;
}

.carousel-control-prev
{
    left: 0;
}

.carousel-control-next
{
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon
{
    display: inline-block;

    width: 20px;
    height: 20px;

    background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon
{
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23fff\' viewBox=\'0 0 8 8\'%3E%3Cpath d=\'M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z\'/%3E%3C/svg%3E');
}

.carousel-control-next-icon
{
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23fff\' viewBox=\'0 0 8 8\'%3E%3Cpath d=\'M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z\'/%3E%3C/svg%3E');
}

.carousel-indicators
{
    position: absolute;
    z-index: 15;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;

    margin-right: 15%;
    margin-left: 15%;
    padding-left: 0;

    list-style: none; 

    justify-content: center;
}
.carousel-indicators li
{
    box-sizing: content-box;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;

    cursor: pointer;
    transition: opacity .6s ease; 
    text-indent: -999px;

    opacity: .5;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    background-color: #fff;
    background-clip: padding-box;

    flex: 0 1 auto;
}
@media (prefers-reduced-motion: reduce)
{
    .carousel-indicators li
    {
        transition: none;
    }
}
.carousel-indicators .active
{
    opacity: 1;
}

.carousel-caption
{
    position: absolute;
    z-index: 10;
    right: 15%;
    bottom: 20px;
    left: 15%;

    padding-top: 20px;
    padding-bottom: 20px;

    text-align: center; 

    color: #fff;
}

@keyframes spinner-border
{
    to
    {
        transform: rotate(360deg);
    }
}

.spinner-border
{
    display: inline-block;

    width: 2rem;
    height: 2rem;

    animation: spinner-border .75s linear infinite; 
    vertical-align: text-bottom;

    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
}

.spinner-border-sm
{
    width: 1rem;
    height: 1rem;

    border-width: .2em;
}

@keyframes spinner-grow
{
    0%
    {
        transform: scale(0);
    }
    50%
    {
        transform: none; 

        opacity: 1;
    }
}

.spinner-grow
{
    display: inline-block;

    width: 2rem;
    height: 2rem;

    animation: spinner-grow .75s linear infinite; 
    vertical-align: text-bottom;

    opacity: 0;
    border-radius: 50%;
    background-color: currentColor;
}

.spinner-grow-sm
{
    width: 1rem;
    height: 1rem;
}

.align-baseline
{
    vertical-align: baseline !important;
}

.align-top
{
    vertical-align: top !important;
}

.align-middle
{
    vertical-align: middle !important;
}

.align-bottom
{
    vertical-align: bottom !important;
}

.align-text-bottom
{
    vertical-align: text-bottom !important;
}

.align-text-top
{
    vertical-align: text-top !important;
}

.bg-primary
{
    background-color: #f12639 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus
{
    background-color: #d60e21 !important;
}

.bg-secondary
{
    background-color: #1f2749 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus
{
    background-color: #101425 !important;
}

.bg-success
{
    background-color: #2ecd75 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus
{
    background-color: #25a35d !important;
}

.bg-info
{
    background-color: #41c6f2 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus
{
    background-color: #11b8ef !important;
}

.bg-warning
{
    background-color: #fbcc5d !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus
{
    background-color: #fabc2b !important;
}

.bg-danger
{
    background-color: #f55454 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus
{
    background-color: #f22424 !important;
}

.bg-light
{
    background-color: #f5f5f5 !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus
{
    background-color: gainsboro !important;
}

.bg-dark
{
    background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus
{
    background-color: #1d2124 !important;
}

.bg-blue-light
{
    background-color: #f3f8fc !important;
}

a.bg-blue-light:hover,
a.bg-blue-light:focus,
button.bg-blue-light:hover,
button.bg-blue-light:focus
{
    background-color: #cae0f2 !important;
}

.bg-white
{
    background-color: #fff !important;
}

.bg-transparent
{
    background-color: transparent !important;
}

.border
{
    border: 1px solid #d2d4db !important;
}

.border-top
{
    border-top: 1px solid #d2d4db !important;
}

.border-right
{
    border-right: 1px solid #d2d4db !important;
}

.border-bottom
{
    border-bottom: 1px solid #d2d4db !important;
}

.border-left
{
    border-left: 1px solid #d2d4db !important;
}

.border-0
{
    border: 0 !important;
}

.border-top-0
{
    border-top: 0 !important;
}

.border-right-0
{
    border-right: 0 !important;
}

.border-bottom-0
{
    border-bottom: 0 !important;
}

.border-left-0
{
    border-left: 0 !important;
}

.border-primary
{
    border-color: #f12639 !important;
}

.border-secondary
{
    border-color: #1f2749 !important;
}

.border-success
{
    border-color: #2ecd75 !important;
}

.border-info
{
    border-color: #41c6f2 !important;
}

.border-warning
{
    border-color: #fbcc5d !important;
}

.border-danger
{
    border-color: #f55454 !important;
}

.border-light
{
    border-color: #f5f5f5 !important;
}

.border-dark
{
    border-color: #343a40 !important;
}

.border-blue-light
{
    border-color: #f3f8fc !important;
}

.border-white
{
    border-color: #fff !important;
}

.rounded-sm
{
    border-radius: .2rem !important;
}

.rounded
{
    border-radius: .25rem !important;
}

.rounded-top
{
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
}

.rounded-right
{
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
}

.rounded-bottom
{
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
}

.rounded-left
{
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
}

.rounded-lg
{
    border-radius: .3rem !important;
}

.rounded-circle
{
    border-radius: 50% !important;
}

.rounded-pill
{
    border-radius: 50rem !important;
}

.rounded-0
{
    border-radius: 0 !important;
}

.clearfix::after
{
    display: block;
    clear: both;

    content: '';
}

.d-none
{
    display: none !important;
}

.d-inline
{
    display: inline !important;
}

.d-inline-block
{
    display: inline-block !important;
}

.d-block
{
    display: block !important;
}

.d-table
{
    display: table !important;
}

.d-table-row
{
    display: table-row !important;
}

.d-table-cell
{
    display: table-cell !important;
}

.d-flex
{
    display: flex !important;
}

.d-inline-flex
{
    display: inline-flex !important;
}

@media (min-width: 576px)
{
    .d-sm-none
    {
        display: none !important;
    }
    .d-sm-inline
    {
        display: inline !important;
    }
    .d-sm-inline-block
    {
        display: inline-block !important;
    }
    .d-sm-block
    {
        display: block !important;
    }
    .d-sm-table
    {
        display: table !important;
    }
    .d-sm-table-row
    {
        display: table-row !important;
    }
    .d-sm-table-cell
    {
        display: table-cell !important;
    }
    .d-sm-flex
    {
        display: flex !important;
    }
    .d-sm-inline-flex
    {
        display: inline-flex !important;
    }
}

@media (min-width: 768px)
{
    .d-md-none
    {
        display: none !important;
    }
    .d-md-inline
    {
        display: inline !important;
    }
    .d-md-inline-block
    {
        display: inline-block !important;
    }
    .d-md-block
    {
        display: block !important;
    }
    .d-md-table
    {
        display: table !important;
    }
    .d-md-table-row
    {
        display: table-row !important;
    }
    .d-md-table-cell
    {
        display: table-cell !important;
    }
    .d-md-flex
    {
        display: flex !important;
    }
    .d-md-inline-flex
    {
        display: inline-flex !important;
    }
}

@media (min-width: 992px)
{
    .d-lg-none
    {
        display: none !important;
    }
    .d-lg-inline
    {
        display: inline !important;
    }
    .d-lg-inline-block
    {
        display: inline-block !important;
    }
    .d-lg-block
    {
        display: block !important;
    }
    .d-lg-table
    {
        display: table !important;
    }
    .d-lg-table-row
    {
        display: table-row !important;
    }
    .d-lg-table-cell
    {
        display: table-cell !important;
    }
    .d-lg-flex
    {
        display: flex !important;
    }
    .d-lg-inline-flex
    {
        display: inline-flex !important;
    }
}

@media (min-width: 1200px)
{
    .d-xl-none
    {
        display: none !important;
    }
    .d-xl-inline
    {
        display: inline !important;
    }
    .d-xl-inline-block
    {
        display: inline-block !important;
    }
    .d-xl-block
    {
        display: block !important;
    }
    .d-xl-table
    {
        display: table !important;
    }
    .d-xl-table-row
    {
        display: table-row !important;
    }
    .d-xl-table-cell
    {
        display: table-cell !important;
    }
    .d-xl-flex
    {
        display: flex !important;
    }
    .d-xl-inline-flex
    {
        display: inline-flex !important;
    }
}

@media (min-width: 1600px)
{
    .d-xxl-none
    {
        display: none !important;
    }
    .d-xxl-inline
    {
        display: inline !important;
    }
    .d-xxl-inline-block
    {
        display: inline-block !important;
    }
    .d-xxl-block
    {
        display: block !important;
    }
    .d-xxl-table
    {
        display: table !important;
    }
    .d-xxl-table-row
    {
        display: table-row !important;
    }
    .d-xxl-table-cell
    {
        display: table-cell !important;
    }
    .d-xxl-flex
    {
        display: flex !important;
    }
    .d-xxl-inline-flex
    {
        display: inline-flex !important;
    }
}

@media print
{
    .d-print-none
    {
        display: none !important;
    }
    .d-print-inline
    {
        display: inline !important;
    }
    .d-print-inline-block
    {
        display: inline-block !important;
    }
    .d-print-block
    {
        display: block !important;
    }
    .d-print-table
    {
        display: table !important;
    }
    .d-print-table-row
    {
        display: table-row !important;
    }
    .d-print-table-cell
    {
        display: table-cell !important;
    }
    .d-print-flex
    {
        display: flex !important;
    }
    .d-print-inline-flex
    {
        display: inline-flex !important;
    }
}

.embed-responsive
{
    position: relative;

    display: block;
    overflow: hidden; 

    width: 100%;
    padding: 0;
}
.embed-responsive::before
{
    display: block;

    content: '';
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border: 0;
}

.embed-responsive-21by9::before
{
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before
{
    padding-top: 56.25%;
}

.embed-responsive-4by3::before
{
    padding-top: 75%;
}

.embed-responsive-1by1::before
{
    padding-top: 100%;
}

.embed-responsive-21by9::before
{
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before
{
    padding-top: 56.25%;
}

.embed-responsive-4by3::before
{
    padding-top: 75%;
}

.embed-responsive-1by1::before
{
    padding-top: 100%;
}

.flex-row
{
    flex-direction: row !important;
}

.flex-column
{
    flex-direction: column !important;
}

.flex-row-reverse
{
    flex-direction: row-reverse !important;
}

.flex-column-reverse
{
    flex-direction: column-reverse !important;
}

.flex-wrap
{
    flex-wrap: wrap !important;
}

.flex-nowrap
{
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse
{
    flex-wrap: wrap-reverse !important;
}

.flex-fill
{
    flex: 1 1 auto !important;
}

.flex-grow-0
{
    flex-grow: 0 !important;
}

.flex-grow-1
{
    flex-grow: 1 !important;
}

.flex-shrink-0
{
    flex-shrink: 0 !important;
}

.flex-shrink-1
{
    flex-shrink: 1 !important;
}

.justify-content-start
{
    justify-content: flex-start !important;
}

.justify-content-end
{
    justify-content: flex-end !important;
}

.justify-content-center
{
    justify-content: center !important;
}

.justify-content-between
{
    justify-content: space-between !important;
}

.justify-content-around
{
    justify-content: space-around !important;
}

.align-items-start
{
    align-items: flex-start !important;
}

.align-items-end
{
    align-items: flex-end !important;
}

.align-items-center
{
    align-items: center !important;
}

.align-items-baseline
{
    align-items: baseline !important;
}

.align-items-stretch
{
    align-items: stretch !important;
}

.align-content-start
{
    align-content: flex-start !important;
}

.align-content-end
{
    align-content: flex-end !important;
}

.align-content-center
{
    align-content: center !important;
}

.align-content-between
{
    align-content: space-between !important;
}

.align-content-around
{
    align-content: space-around !important;
}

.align-content-stretch
{
    align-content: stretch !important;
}

.align-self-auto
{
    align-self: auto !important;
}

.align-self-start
{
    align-self: flex-start !important;
}

.align-self-end
{
    align-self: flex-end !important;
}

.align-self-center
{
    align-self: center !important;
}

.align-self-baseline
{
    align-self: baseline !important;
}

.align-self-stretch
{
    align-self: stretch !important;
}

@media (min-width: 576px)
{
    .flex-sm-row
    {
        flex-direction: row !important;
    }
    .flex-sm-column
    {
        flex-direction: column !important;
    }
    .flex-sm-row-reverse
    {
        flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse
    {
        flex-direction: column-reverse !important;
    }
    .flex-sm-wrap
    {
        flex-wrap: wrap !important;
    }
    .flex-sm-nowrap
    {
        flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse
    {
        flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill
    {
        flex: 1 1 auto !important;
    }
    .flex-sm-grow-0
    {
        flex-grow: 0 !important;
    }
    .flex-sm-grow-1
    {
        flex-grow: 1 !important;
    }
    .flex-sm-shrink-0
    {
        flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1
    {
        flex-shrink: 1 !important;
    }
    .justify-content-sm-start
    {
        justify-content: flex-start !important;
    }
    .justify-content-sm-end
    {
        justify-content: flex-end !important;
    }
    .justify-content-sm-center
    {
        justify-content: center !important;
    }
    .justify-content-sm-between
    {
        justify-content: space-between !important;
    }
    .justify-content-sm-around
    {
        justify-content: space-around !important;
    }
    .align-items-sm-start
    {
        align-items: flex-start !important;
    }
    .align-items-sm-end
    {
        align-items: flex-end !important;
    }
    .align-items-sm-center
    {
        align-items: center !important;
    }
    .align-items-sm-baseline
    {
        align-items: baseline !important;
    }
    .align-items-sm-stretch
    {
        align-items: stretch !important;
    }
    .align-content-sm-start
    {
        align-content: flex-start !important;
    }
    .align-content-sm-end
    {
        align-content: flex-end !important;
    }
    .align-content-sm-center
    {
        align-content: center !important;
    }
    .align-content-sm-between
    {
        align-content: space-between !important;
    }
    .align-content-sm-around
    {
        align-content: space-around !important;
    }
    .align-content-sm-stretch
    {
        align-content: stretch !important;
    }
    .align-self-sm-auto
    {
        align-self: auto !important;
    }
    .align-self-sm-start
    {
        align-self: flex-start !important;
    }
    .align-self-sm-end
    {
        align-self: flex-end !important;
    }
    .align-self-sm-center
    {
        align-self: center !important;
    }
    .align-self-sm-baseline
    {
        align-self: baseline !important;
    }
    .align-self-sm-stretch
    {
        align-self: stretch !important;
    }
}

@media (min-width: 768px)
{
    .flex-md-row
    {
        flex-direction: row !important;
    }
    .flex-md-column
    {
        flex-direction: column !important;
    }
    .flex-md-row-reverse
    {
        flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse
    {
        flex-direction: column-reverse !important;
    }
    .flex-md-wrap
    {
        flex-wrap: wrap !important;
    }
    .flex-md-nowrap
    {
        flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse
    {
        flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill
    {
        flex: 1 1 auto !important;
    }
    .flex-md-grow-0
    {
        flex-grow: 0 !important;
    }
    .flex-md-grow-1
    {
        flex-grow: 1 !important;
    }
    .flex-md-shrink-0
    {
        flex-shrink: 0 !important;
    }
    .flex-md-shrink-1
    {
        flex-shrink: 1 !important;
    }
    .justify-content-md-start
    {
        justify-content: flex-start !important;
    }
    .justify-content-md-end
    {
        justify-content: flex-end !important;
    }
    .justify-content-md-center
    {
        justify-content: center !important;
    }
    .justify-content-md-between
    {
        justify-content: space-between !important;
    }
    .justify-content-md-around
    {
        justify-content: space-around !important;
    }
    .align-items-md-start
    {
        align-items: flex-start !important;
    }
    .align-items-md-end
    {
        align-items: flex-end !important;
    }
    .align-items-md-center
    {
        align-items: center !important;
    }
    .align-items-md-baseline
    {
        align-items: baseline !important;
    }
    .align-items-md-stretch
    {
        align-items: stretch !important;
    }
    .align-content-md-start
    {
        align-content: flex-start !important;
    }
    .align-content-md-end
    {
        align-content: flex-end !important;
    }
    .align-content-md-center
    {
        align-content: center !important;
    }
    .align-content-md-between
    {
        align-content: space-between !important;
    }
    .align-content-md-around
    {
        align-content: space-around !important;
    }
    .align-content-md-stretch
    {
        align-content: stretch !important;
    }
    .align-self-md-auto
    {
        align-self: auto !important;
    }
    .align-self-md-start
    {
        align-self: flex-start !important;
    }
    .align-self-md-end
    {
        align-self: flex-end !important;
    }
    .align-self-md-center
    {
        align-self: center !important;
    }
    .align-self-md-baseline
    {
        align-self: baseline !important;
    }
    .align-self-md-stretch
    {
        align-self: stretch !important;
    }
}

@media (min-width: 992px)
{
    .flex-lg-row
    {
        flex-direction: row !important;
    }
    .flex-lg-column
    {
        flex-direction: column !important;
    }
    .flex-lg-row-reverse
    {
        flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse
    {
        flex-direction: column-reverse !important;
    }
    .flex-lg-wrap
    {
        flex-wrap: wrap !important;
    }
    .flex-lg-nowrap
    {
        flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse
    {
        flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill
    {
        flex: 1 1 auto !important;
    }
    .flex-lg-grow-0
    {
        flex-grow: 0 !important;
    }
    .flex-lg-grow-1
    {
        flex-grow: 1 !important;
    }
    .flex-lg-shrink-0
    {
        flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1
    {
        flex-shrink: 1 !important;
    }
    .justify-content-lg-start
    {
        justify-content: flex-start !important;
    }
    .justify-content-lg-end
    {
        justify-content: flex-end !important;
    }
    .justify-content-lg-center
    {
        justify-content: center !important;
    }
    .justify-content-lg-between
    {
        justify-content: space-between !important;
    }
    .justify-content-lg-around
    {
        justify-content: space-around !important;
    }
    .align-items-lg-start
    {
        align-items: flex-start !important;
    }
    .align-items-lg-end
    {
        align-items: flex-end !important;
    }
    .align-items-lg-center
    {
        align-items: center !important;
    }
    .align-items-lg-baseline
    {
        align-items: baseline !important;
    }
    .align-items-lg-stretch
    {
        align-items: stretch !important;
    }
    .align-content-lg-start
    {
        align-content: flex-start !important;
    }
    .align-content-lg-end
    {
        align-content: flex-end !important;
    }
    .align-content-lg-center
    {
        align-content: center !important;
    }
    .align-content-lg-between
    {
        align-content: space-between !important;
    }
    .align-content-lg-around
    {
        align-content: space-around !important;
    }
    .align-content-lg-stretch
    {
        align-content: stretch !important;
    }
    .align-self-lg-auto
    {
        align-self: auto !important;
    }
    .align-self-lg-start
    {
        align-self: flex-start !important;
    }
    .align-self-lg-end
    {
        align-self: flex-end !important;
    }
    .align-self-lg-center
    {
        align-self: center !important;
    }
    .align-self-lg-baseline
    {
        align-self: baseline !important;
    }
    .align-self-lg-stretch
    {
        align-self: stretch !important;
    }
}

@media (min-width: 1200px)
{
    .flex-xl-row
    {
        flex-direction: row !important;
    }
    .flex-xl-column
    {
        flex-direction: column !important;
    }
    .flex-xl-row-reverse
    {
        flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse
    {
        flex-direction: column-reverse !important;
    }
    .flex-xl-wrap
    {
        flex-wrap: wrap !important;
    }
    .flex-xl-nowrap
    {
        flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse
    {
        flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill
    {
        flex: 1 1 auto !important;
    }
    .flex-xl-grow-0
    {
        flex-grow: 0 !important;
    }
    .flex-xl-grow-1
    {
        flex-grow: 1 !important;
    }
    .flex-xl-shrink-0
    {
        flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1
    {
        flex-shrink: 1 !important;
    }
    .justify-content-xl-start
    {
        justify-content: flex-start !important;
    }
    .justify-content-xl-end
    {
        justify-content: flex-end !important;
    }
    .justify-content-xl-center
    {
        justify-content: center !important;
    }
    .justify-content-xl-between
    {
        justify-content: space-between !important;
    }
    .justify-content-xl-around
    {
        justify-content: space-around !important;
    }
    .align-items-xl-start
    {
        align-items: flex-start !important;
    }
    .align-items-xl-end
    {
        align-items: flex-end !important;
    }
    .align-items-xl-center
    {
        align-items: center !important;
    }
    .align-items-xl-baseline
    {
        align-items: baseline !important;
    }
    .align-items-xl-stretch
    {
        align-items: stretch !important;
    }
    .align-content-xl-start
    {
        align-content: flex-start !important;
    }
    .align-content-xl-end
    {
        align-content: flex-end !important;
    }
    .align-content-xl-center
    {
        align-content: center !important;
    }
    .align-content-xl-between
    {
        align-content: space-between !important;
    }
    .align-content-xl-around
    {
        align-content: space-around !important;
    }
    .align-content-xl-stretch
    {
        align-content: stretch !important;
    }
    .align-self-xl-auto
    {
        align-self: auto !important;
    }
    .align-self-xl-start
    {
        align-self: flex-start !important;
    }
    .align-self-xl-end
    {
        align-self: flex-end !important;
    }
    .align-self-xl-center
    {
        align-self: center !important;
    }
    .align-self-xl-baseline
    {
        align-self: baseline !important;
    }
    .align-self-xl-stretch
    {
        align-self: stretch !important;
    }
}

@media (min-width: 1600px)
{
    .flex-xxl-row
    {
        flex-direction: row !important;
    }
    .flex-xxl-column
    {
        flex-direction: column !important;
    }
    .flex-xxl-row-reverse
    {
        flex-direction: row-reverse !important;
    }
    .flex-xxl-column-reverse
    {
        flex-direction: column-reverse !important;
    }
    .flex-xxl-wrap
    {
        flex-wrap: wrap !important;
    }
    .flex-xxl-nowrap
    {
        flex-wrap: nowrap !important;
    }
    .flex-xxl-wrap-reverse
    {
        flex-wrap: wrap-reverse !important;
    }
    .flex-xxl-fill
    {
        flex: 1 1 auto !important;
    }
    .flex-xxl-grow-0
    {
        flex-grow: 0 !important;
    }
    .flex-xxl-grow-1
    {
        flex-grow: 1 !important;
    }
    .flex-xxl-shrink-0
    {
        flex-shrink: 0 !important;
    }
    .flex-xxl-shrink-1
    {
        flex-shrink: 1 !important;
    }
    .justify-content-xxl-start
    {
        justify-content: flex-start !important;
    }
    .justify-content-xxl-end
    {
        justify-content: flex-end !important;
    }
    .justify-content-xxl-center
    {
        justify-content: center !important;
    }
    .justify-content-xxl-between
    {
        justify-content: space-between !important;
    }
    .justify-content-xxl-around
    {
        justify-content: space-around !important;
    }
    .align-items-xxl-start
    {
        align-items: flex-start !important;
    }
    .align-items-xxl-end
    {
        align-items: flex-end !important;
    }
    .align-items-xxl-center
    {
        align-items: center !important;
    }
    .align-items-xxl-baseline
    {
        align-items: baseline !important;
    }
    .align-items-xxl-stretch
    {
        align-items: stretch !important;
    }
    .align-content-xxl-start
    {
        align-content: flex-start !important;
    }
    .align-content-xxl-end
    {
        align-content: flex-end !important;
    }
    .align-content-xxl-center
    {
        align-content: center !important;
    }
    .align-content-xxl-between
    {
        align-content: space-between !important;
    }
    .align-content-xxl-around
    {
        align-content: space-around !important;
    }
    .align-content-xxl-stretch
    {
        align-content: stretch !important;
    }
    .align-self-xxl-auto
    {
        align-self: auto !important;
    }
    .align-self-xxl-start
    {
        align-self: flex-start !important;
    }
    .align-self-xxl-end
    {
        align-self: flex-end !important;
    }
    .align-self-xxl-center
    {
        align-self: center !important;
    }
    .align-self-xxl-baseline
    {
        align-self: baseline !important;
    }
    .align-self-xxl-stretch
    {
        align-self: stretch !important;
    }
}

.float-left
{
    float: left !important;
}

.float-right
{
    float: right !important;
}

.float-none
{
    float: none !important;
}

@media (min-width: 576px)
{
    .float-sm-left
    {
        float: left !important;
    }
    .float-sm-right
    {
        float: right !important;
    }
    .float-sm-none
    {
        float: none !important;
    }
}

@media (min-width: 768px)
{
    .float-md-left
    {
        float: left !important;
    }
    .float-md-right
    {
        float: right !important;
    }
    .float-md-none
    {
        float: none !important;
    }
}

@media (min-width: 992px)
{
    .float-lg-left
    {
        float: left !important;
    }
    .float-lg-right
    {
        float: right !important;
    }
    .float-lg-none
    {
        float: none !important;
    }
}

@media (min-width: 1200px)
{
    .float-xl-left
    {
        float: left !important;
    }
    .float-xl-right
    {
        float: right !important;
    }
    .float-xl-none
    {
        float: none !important;
    }
}

@media (min-width: 1600px)
{
    .float-xxl-left
    {
        float: left !important;
    }
    .float-xxl-right
    {
        float: right !important;
    }
    .float-xxl-none
    {
        float: none !important;
    }
}

.user-select-all
{
    user-select: all !important;
}

.user-select-auto
{
    user-select: auto !important;
}

.user-select-none
{
    user-select: none !important;
}

.overflow-auto
{
    overflow: auto !important;
}

.overflow-hidden
{
    overflow: hidden !important;
}

.position-static
{
    position: static !important;
}

.position-relative
{
    position: relative !important;
}

.position-absolute
{
    position: absolute !important;
}

.position-fixed
{
    position: fixed !important;
}

.position-sticky
{
    position: sticky !important;
}

.fixed-top
{
    position: fixed;
    z-index: 1030; 
    top: 0;
    right: 0;
    left: 0;
}

.fixed-bottom
{
    position: fixed;
    z-index: 1030; 
    right: 0;
    bottom: 0;
    left: 0;
}

@supports (position: sticky)
{
    .sticky-top
    {
        position: sticky;
        z-index: 1020; 
        top: 0;
    }
}

.sr-only
{
    position: absolute;

    overflow: hidden;
    clip: rect(0, 0, 0, 0);

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;

    white-space: nowrap;

    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus
{
    position: static;

    overflow: visible;
    clip: auto;

    width: auto;
    height: auto;

    white-space: normal;
}

.shadow-sm
{
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.shadow
{
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.shadow-lg
{
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

.shadow-none
{
    box-shadow: none !important;
}

.w-25
{
    width: 25% !important;
}

.w-50
{
    width: 50% !important;
}

.w-75
{
    width: 75% !important;
}

.w-100
{
    width: 100% !important;
}

.w-auto
{
    width: auto !important;
}

.h-25
{
    height: 25% !important;
}

.h-50
{
    height: 50% !important;
}

.h-75
{
    height: 75% !important;
}

.h-100
{
    height: 100% !important;
}

.h-auto
{
    height: auto !important;
}

.mw-100
{
    max-width: 100% !important;
}

.mh-100
{
    max-height: 100% !important;
}

.min-vw-100
{
    min-width: 100vw !important;
}

.min-vh-100
{
    min-height: 100vh !important;
}

.vw-100
{
    width: 100vw !important;
}

.vh-100
{
    height: 100vh !important;
}

.m-0
{
    margin: 0 !important;
}

.mt-0,
.my-0
{
    margin-top: 0 !important;
}

.mr-0,
.mx-0
{
    margin-right: 0 !important;
}

.mb-0,
.my-0
{
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0
{
    margin-left: 0 !important;
}

.m-1
{
    margin: .25rem !important;
}

.mt-1,
.my-1
{
    margin-top: .25rem !important;
}

.mr-1,
.mx-1
{
    margin-right: .25rem !important;
}

.mb-1,
.my-1
{
    margin-bottom: .25rem !important;
}

.ml-1,
.mx-1
{
    margin-left: .25rem !important;
}

.m-2
{
    margin: .5rem !important;
}

.mt-2,
.my-2
{
    margin-top: .5rem !important;
}

.mr-2,
.mx-2
{
    margin-right: .5rem !important;
}

.mb-2,
.my-2
{
    margin-bottom: .5rem !important;
}

.ml-2,
.mx-2
{
    margin-left: .5rem !important;
}

.m-3
{
    margin: 1rem !important;
}

.mt-3,
.my-3
{
    margin-top: 1rem !important;
}

.mr-3,
.mx-3
{
    margin-right: 1rem !important;
}

.mb-3,
.my-3
{
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3
{
    margin-left: 1rem !important;
}

.m-4
{
    margin: 1.5rem !important;
}

.mt-4,
.my-4
{
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4
{
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4
{
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4
{
    margin-left: 1.5rem !important;
}

.m-5
{
    margin: 3rem !important;
}

.mt-5,
.my-5
{
    margin-top: 3rem !important;
}

.mr-5,
.mx-5
{
    margin-right: 3rem !important;
}

.mb-5,
.my-5
{
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5
{
    margin-left: 3rem !important;
}

.m-6
{
    margin: 4.5rem !important;
}

.mt-6,
.my-6
{
    margin-top: 4.5rem !important;
}

.mr-6,
.mx-6
{
    margin-right: 4.5rem !important;
}

.mb-6,
.my-6
{
    margin-bottom: 4.5rem !important;
}

.ml-6,
.mx-6
{
    margin-left: 4.5rem !important;
}

.m-7
{
    margin: 6rem !important;
}

.mt-7,
.my-7
{
    margin-top: 6rem !important;
}

.mr-7,
.mx-7
{
    margin-right: 6rem !important;
}

.mb-7,
.my-7
{
    margin-bottom: 6rem !important;
}

.ml-7,
.mx-7
{
    margin-left: 6rem !important;
}

.m-8
{
    margin: 7.5rem !important;
}

.mt-8,
.my-8
{
    margin-top: 7.5rem !important;
}

.mr-8,
.mx-8
{
    margin-right: 7.5rem !important;
}

.mb-8,
.my-8
{
    margin-bottom: 7.5rem !important;
}

.ml-8,
.mx-8
{
    margin-left: 7.5rem !important;
}

.m-9
{
    margin: 9rem !important;
}

.mt-9,
.my-9
{
    margin-top: 9rem !important;
}

.mr-9,
.mx-9
{
    margin-right: 9rem !important;
}

.mb-9,
.my-9
{
    margin-bottom: 9rem !important;
}

.ml-9,
.mx-9
{
    margin-left: 9rem !important;
}

.m-10
{
    margin: 10rem !important;
}

.mt-10,
.my-10
{
    margin-top: 10rem !important;
}

.mr-10,
.mx-10
{
    margin-right: 10rem !important;
}

.mb-10,
.my-10
{
    margin-bottom: 10rem !important;
}

.ml-10,
.mx-10
{
    margin-left: 10rem !important;
}

.m-11
{
    margin: 12rem !important;
}

.mt-11,
.my-11
{
    margin-top: 12rem !important;
}

.mr-11,
.mx-11
{
    margin-right: 12rem !important;
}

.mb-11,
.my-11
{
    margin-bottom: 12rem !important;
}

.ml-11,
.mx-11
{
    margin-left: 12rem !important;
}

.m-12
{
    margin: 14.5rem !important;
}

.mt-12,
.my-12
{
    margin-top: 14.5rem !important;
}

.mr-12,
.mx-12
{
    margin-right: 14.5rem !important;
}

.mb-12,
.my-12
{
    margin-bottom: 14.5rem !important;
}

.ml-12,
.mx-12
{
    margin-left: 14.5rem !important;
}

.m-13
{
    margin: 15rem !important;
}

.mt-13,
.my-13
{
    margin-top: 15rem !important;
}

.mr-13,
.mx-13
{
    margin-right: 15rem !important;
}

.mb-13,
.my-13
{
    margin-bottom: 15rem !important;
}

.ml-13,
.mx-13
{
    margin-left: 15rem !important;
}

.m-14
{
    margin: 16rem !important;
}

.mt-14,
.my-14
{
    margin-top: 16rem !important;
}

.mr-14,
.mx-14
{
    margin-right: 16rem !important;
}

.mb-14,
.my-14
{
    margin-bottom: 16rem !important;
}

.ml-14,
.mx-14
{
    margin-left: 16rem !important;
}

.m-15
{
    margin: 17rem !important;
}

.mt-15,
.my-15
{
    margin-top: 17rem !important;
}

.mr-15,
.mx-15
{
    margin-right: 17rem !important;
}

.mb-15,
.my-15
{
    margin-bottom: 17rem !important;
}

.ml-15,
.mx-15
{
    margin-left: 17rem !important;
}

.p-0
{
    padding: 0 !important;
}

.pt-0,
.py-0
{
    padding-top: 0 !important;
}

.pr-0,
.px-0
{
    padding-right: 0 !important;
}

.pb-0,
.py-0
{
    padding-bottom: 0 !important;
}

.pl-0,
.px-0
{
    padding-left: 0 !important;
}

.p-1
{
    padding: .25rem !important;
}

.pt-1,
.py-1
{
    padding-top: .25rem !important;
}

.pr-1,
.px-1
{
    padding-right: .25rem !important;
}

.pb-1,
.py-1
{
    padding-bottom: .25rem !important;
}

.pl-1,
.px-1
{
    padding-left: .25rem !important;
}

.p-2
{
    padding: .5rem !important;
}

.pt-2,
.py-2
{
    padding-top: .5rem !important;
}

.pr-2,
.px-2
{
    padding-right: .5rem !important;
}

.pb-2,
.py-2
{
    padding-bottom: .5rem !important;
}

.pl-2,
.px-2
{
    padding-left: .5rem !important;
}

.p-3
{
    padding: 1rem !important;
}

.pt-3,
.py-3
{
    padding-top: 1rem !important;
}

.pr-3,
.px-3
{
    padding-right: 1rem !important;
}

.pb-3,
.py-3
{
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3
{
    padding-left: 1rem !important;
}

.p-4
{
    padding: 1.5rem !important;
}

.pt-4,
.py-4
{
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4
{
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4
{
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4
{
    padding-left: 1.5rem !important;
}

.p-5
{
    padding: 3rem !important;
}

.pt-5,
.py-5
{
    padding-top: 3rem !important;
}

.pr-5,
.px-5
{
    padding-right: 3rem !important;
}

.pb-5,
.py-5
{
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5
{
    padding-left: 3rem !important;
}

.p-6
{
    padding: 4.5rem !important;
}

.pt-6,
.py-6
{
    padding-top: 4.5rem !important;
}

.pr-6,
.px-6
{
    padding-right: 4.5rem !important;
}

.pb-6,
.py-6
{
    padding-bottom: 4.5rem !important;
}

.pl-6,
.px-6
{
    padding-left: 4.5rem !important;
}

.p-7
{
    padding: 6rem !important;
}

.pt-7,
.py-7
{
    padding-top: 6rem !important;
}

.pr-7,
.px-7
{
    padding-right: 6rem !important;
}

.pb-7,
.py-7
{
    padding-bottom: 6rem !important;
}

.pl-7,
.px-7
{
    padding-left: 6rem !important;
}

.p-8
{
    padding: 7.5rem !important;
}

.pt-8,
.py-8
{
    padding-top: 7.5rem !important;
}

.pr-8,
.px-8
{
    padding-right: 7.5rem !important;
}

.pb-8,
.py-8
{
    padding-bottom: 7.5rem !important;
}

.pl-8,
.px-8
{
    padding-left: 7.5rem !important;
}

.p-9
{
    padding: 9rem !important;
}

.pt-9,
.py-9
{
    padding-top: 9rem !important;
}

.pr-9,
.px-9
{
    padding-right: 9rem !important;
}

.pb-9,
.py-9
{
    padding-bottom: 9rem !important;
}

.pl-9,
.px-9
{
    padding-left: 9rem !important;
}

.p-10
{
    padding: 10rem !important;
}

.pt-10,
.py-10
{
    padding-top: 10rem !important;
}

.pr-10,
.px-10
{
    padding-right: 10rem !important;
}

.pb-10,
.py-10
{
    padding-bottom: 10rem !important;
}

.pl-10,
.px-10
{
    padding-left: 10rem !important;
}

.p-11
{
    padding: 12rem !important;
}

.pt-11,
.py-11
{
    padding-top: 12rem !important;
}

.pr-11,
.px-11
{
    padding-right: 12rem !important;
}

.pb-11,
.py-11
{
    padding-bottom: 12rem !important;
}

.pl-11,
.px-11
{
    padding-left: 12rem !important;
}

.p-12
{
    padding: 14.5rem !important;
}

.pt-12,
.py-12
{
    padding-top: 14.5rem !important;
}

.pr-12,
.px-12
{
    padding-right: 14.5rem !important;
}

.pb-12,
.py-12
{
    padding-bottom: 14.5rem !important;
}

.pl-12,
.px-12
{
    padding-left: 14.5rem !important;
}

.p-13
{
    padding: 15rem !important;
}

.pt-13,
.py-13
{
    padding-top: 15rem !important;
}

.pr-13,
.px-13
{
    padding-right: 15rem !important;
}

.pb-13,
.py-13
{
    padding-bottom: 15rem !important;
}

.pl-13,
.px-13
{
    padding-left: 15rem !important;
}

.p-14
{
    padding: 16rem !important;
}

.pt-14,
.py-14
{
    padding-top: 16rem !important;
}

.pr-14,
.px-14
{
    padding-right: 16rem !important;
}

.pb-14,
.py-14
{
    padding-bottom: 16rem !important;
}

.pl-14,
.px-14
{
    padding-left: 16rem !important;
}

.p-15
{
    padding: 17rem !important;
}

.pt-15,
.py-15
{
    padding-top: 17rem !important;
}

.pr-15,
.px-15
{
    padding-right: 17rem !important;
}

.pb-15,
.py-15
{
    padding-bottom: 17rem !important;
}

.pl-15,
.px-15
{
    padding-left: 17rem !important;
}

.m-n1
{
    margin: -.25rem !important;
}

.mt-n1,
.my-n1
{
    margin-top: -.25rem !important;
}

.mr-n1,
.mx-n1
{
    margin-right: -.25rem !important;
}

.mb-n1,
.my-n1
{
    margin-bottom: -.25rem !important;
}

.ml-n1,
.mx-n1
{
    margin-left: -.25rem !important;
}

.m-n2
{
    margin: -.5rem !important;
}

.mt-n2,
.my-n2
{
    margin-top: -.5rem !important;
}

.mr-n2,
.mx-n2
{
    margin-right: -.5rem !important;
}

.mb-n2,
.my-n2
{
    margin-bottom: -.5rem !important;
}

.ml-n2,
.mx-n2
{
    margin-left: -.5rem !important;
}

.m-n3
{
    margin: -1rem !important;
}

.mt-n3,
.my-n3
{
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3
{
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3
{
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3
{
    margin-left: -1rem !important;
}

.m-n4
{
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4
{
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4
{
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4
{
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4
{
    margin-left: -1.5rem !important;
}

.m-n5
{
    margin: -3rem !important;
}

.mt-n5,
.my-n5
{
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5
{
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5
{
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5
{
    margin-left: -3rem !important;
}

.m-n6
{
    margin: -4.5rem !important;
}

.mt-n6,
.my-n6
{
    margin-top: -4.5rem !important;
}

.mr-n6,
.mx-n6
{
    margin-right: -4.5rem !important;
}

.mb-n6,
.my-n6
{
    margin-bottom: -4.5rem !important;
}

.ml-n6,
.mx-n6
{
    margin-left: -4.5rem !important;
}

.m-n7
{
    margin: -6rem !important;
}

.mt-n7,
.my-n7
{
    margin-top: -6rem !important;
}

.mr-n7,
.mx-n7
{
    margin-right: -6rem !important;
}

.mb-n7,
.my-n7
{
    margin-bottom: -6rem !important;
}

.ml-n7,
.mx-n7
{
    margin-left: -6rem !important;
}

.m-n8
{
    margin: -7.5rem !important;
}

.mt-n8,
.my-n8
{
    margin-top: -7.5rem !important;
}

.mr-n8,
.mx-n8
{
    margin-right: -7.5rem !important;
}

.mb-n8,
.my-n8
{
    margin-bottom: -7.5rem !important;
}

.ml-n8,
.mx-n8
{
    margin-left: -7.5rem !important;
}

.m-n9
{
    margin: -9rem !important;
}

.mt-n9,
.my-n9
{
    margin-top: -9rem !important;
}

.mr-n9,
.mx-n9
{
    margin-right: -9rem !important;
}

.mb-n9,
.my-n9
{
    margin-bottom: -9rem !important;
}

.ml-n9,
.mx-n9
{
    margin-left: -9rem !important;
}

.m-n10
{
    margin: -10rem !important;
}

.mt-n10,
.my-n10
{
    margin-top: -10rem !important;
}

.mr-n10,
.mx-n10
{
    margin-right: -10rem !important;
}

.mb-n10,
.my-n10
{
    margin-bottom: -10rem !important;
}

.ml-n10,
.mx-n10
{
    margin-left: -10rem !important;
}

.m-n11
{
    margin: -12rem !important;
}

.mt-n11,
.my-n11
{
    margin-top: -12rem !important;
}

.mr-n11,
.mx-n11
{
    margin-right: -12rem !important;
}

.mb-n11,
.my-n11
{
    margin-bottom: -12rem !important;
}

.ml-n11,
.mx-n11
{
    margin-left: -12rem !important;
}

.m-n12
{
    margin: -14.5rem !important;
}

.mt-n12,
.my-n12
{
    margin-top: -14.5rem !important;
}

.mr-n12,
.mx-n12
{
    margin-right: -14.5rem !important;
}

.mb-n12,
.my-n12
{
    margin-bottom: -14.5rem !important;
}

.ml-n12,
.mx-n12
{
    margin-left: -14.5rem !important;
}

.m-n13
{
    margin: -15rem !important;
}

.mt-n13,
.my-n13
{
    margin-top: -15rem !important;
}

.mr-n13,
.mx-n13
{
    margin-right: -15rem !important;
}

.mb-n13,
.my-n13
{
    margin-bottom: -15rem !important;
}

.ml-n13,
.mx-n13
{
    margin-left: -15rem !important;
}

.m-n14
{
    margin: -16rem !important;
}

.mt-n14,
.my-n14
{
    margin-top: -16rem !important;
}

.mr-n14,
.mx-n14
{
    margin-right: -16rem !important;
}

.mb-n14,
.my-n14
{
    margin-bottom: -16rem !important;
}

.ml-n14,
.mx-n14
{
    margin-left: -16rem !important;
}

.m-n15
{
    margin: -17rem !important;
}

.mt-n15,
.my-n15
{
    margin-top: -17rem !important;
}

.mr-n15,
.mx-n15
{
    margin-right: -17rem !important;
}

.mb-n15,
.my-n15
{
    margin-bottom: -17rem !important;
}

.ml-n15,
.mx-n15
{
    margin-left: -17rem !important;
}

.m-auto
{
    margin: auto !important;
}

.mt-auto,
.my-auto
{
    margin-top: auto !important;
}

.mr-auto,
.mx-auto
{
    margin-right: auto !important;
}

.mb-auto,
.my-auto
{
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto
{
    margin-left: auto !important;
}

@media (min-width: 576px)
{
    .m-sm-0
    {
        margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0
    {
        margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0
    {
        margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0
    {
        margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0
    {
        margin-left: 0 !important;
    }
    .m-sm-1
    {
        margin: .25rem !important;
    }
    .mt-sm-1,
    .my-sm-1
    {
        margin-top: .25rem !important;
    }
    .mr-sm-1,
    .mx-sm-1
    {
        margin-right: .25rem !important;
    }
    .mb-sm-1,
    .my-sm-1
    {
        margin-bottom: .25rem !important;
    }
    .ml-sm-1,
    .mx-sm-1
    {
        margin-left: .25rem !important;
    }
    .m-sm-2
    {
        margin: .5rem !important;
    }
    .mt-sm-2,
    .my-sm-2
    {
        margin-top: .5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2
    {
        margin-right: .5rem !important;
    }
    .mb-sm-2,
    .my-sm-2
    {
        margin-bottom: .5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2
    {
        margin-left: .5rem !important;
    }
    .m-sm-3
    {
        margin: 1rem !important;
    }
    .mt-sm-3,
    .my-sm-3
    {
        margin-top: 1rem !important;
    }
    .mr-sm-3,
    .mx-sm-3
    {
        margin-right: 1rem !important;
    }
    .mb-sm-3,
    .my-sm-3
    {
        margin-bottom: 1rem !important;
    }
    .ml-sm-3,
    .mx-sm-3
    {
        margin-left: 1rem !important;
    }
    .m-sm-4
    {
        margin: 1.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4
    {
        margin-top: 1.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4
    {
        margin-right: 1.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4
    {
        margin-left: 1.5rem !important;
    }
    .m-sm-5
    {
        margin: 3rem !important;
    }
    .mt-sm-5,
    .my-sm-5
    {
        margin-top: 3rem !important;
    }
    .mr-sm-5,
    .mx-sm-5
    {
        margin-right: 3rem !important;
    }
    .mb-sm-5,
    .my-sm-5
    {
        margin-bottom: 3rem !important;
    }
    .ml-sm-5,
    .mx-sm-5
    {
        margin-left: 3rem !important;
    }
    .m-sm-6
    {
        margin: 4.5rem !important;
    }
    .mt-sm-6,
    .my-sm-6
    {
        margin-top: 4.5rem !important;
    }
    .mr-sm-6,
    .mx-sm-6
    {
        margin-right: 4.5rem !important;
    }
    .mb-sm-6,
    .my-sm-6
    {
        margin-bottom: 4.5rem !important;
    }
    .ml-sm-6,
    .mx-sm-6
    {
        margin-left: 4.5rem !important;
    }
    .m-sm-7
    {
        margin: 6rem !important;
    }
    .mt-sm-7,
    .my-sm-7
    {
        margin-top: 6rem !important;
    }
    .mr-sm-7,
    .mx-sm-7
    {
        margin-right: 6rem !important;
    }
    .mb-sm-7,
    .my-sm-7
    {
        margin-bottom: 6rem !important;
    }
    .ml-sm-7,
    .mx-sm-7
    {
        margin-left: 6rem !important;
    }
    .m-sm-8
    {
        margin: 7.5rem !important;
    }
    .mt-sm-8,
    .my-sm-8
    {
        margin-top: 7.5rem !important;
    }
    .mr-sm-8,
    .mx-sm-8
    {
        margin-right: 7.5rem !important;
    }
    .mb-sm-8,
    .my-sm-8
    {
        margin-bottom: 7.5rem !important;
    }
    .ml-sm-8,
    .mx-sm-8
    {
        margin-left: 7.5rem !important;
    }
    .m-sm-9
    {
        margin: 9rem !important;
    }
    .mt-sm-9,
    .my-sm-9
    {
        margin-top: 9rem !important;
    }
    .mr-sm-9,
    .mx-sm-9
    {
        margin-right: 9rem !important;
    }
    .mb-sm-9,
    .my-sm-9
    {
        margin-bottom: 9rem !important;
    }
    .ml-sm-9,
    .mx-sm-9
    {
        margin-left: 9rem !important;
    }
    .m-sm-10
    {
        margin: 10rem !important;
    }
    .mt-sm-10,
    .my-sm-10
    {
        margin-top: 10rem !important;
    }
    .mr-sm-10,
    .mx-sm-10
    {
        margin-right: 10rem !important;
    }
    .mb-sm-10,
    .my-sm-10
    {
        margin-bottom: 10rem !important;
    }
    .ml-sm-10,
    .mx-sm-10
    {
        margin-left: 10rem !important;
    }
    .m-sm-11
    {
        margin: 12rem !important;
    }
    .mt-sm-11,
    .my-sm-11
    {
        margin-top: 12rem !important;
    }
    .mr-sm-11,
    .mx-sm-11
    {
        margin-right: 12rem !important;
    }
    .mb-sm-11,
    .my-sm-11
    {
        margin-bottom: 12rem !important;
    }
    .ml-sm-11,
    .mx-sm-11
    {
        margin-left: 12rem !important;
    }
    .m-sm-12
    {
        margin: 14.5rem !important;
    }
    .mt-sm-12,
    .my-sm-12
    {
        margin-top: 14.5rem !important;
    }
    .mr-sm-12,
    .mx-sm-12
    {
        margin-right: 14.5rem !important;
    }
    .mb-sm-12,
    .my-sm-12
    {
        margin-bottom: 14.5rem !important;
    }
    .ml-sm-12,
    .mx-sm-12
    {
        margin-left: 14.5rem !important;
    }
    .m-sm-13
    {
        margin: 15rem !important;
    }
    .mt-sm-13,
    .my-sm-13
    {
        margin-top: 15rem !important;
    }
    .mr-sm-13,
    .mx-sm-13
    {
        margin-right: 15rem !important;
    }
    .mb-sm-13,
    .my-sm-13
    {
        margin-bottom: 15rem !important;
    }
    .ml-sm-13,
    .mx-sm-13
    {
        margin-left: 15rem !important;
    }
    .m-sm-14
    {
        margin: 16rem !important;
    }
    .mt-sm-14,
    .my-sm-14
    {
        margin-top: 16rem !important;
    }
    .mr-sm-14,
    .mx-sm-14
    {
        margin-right: 16rem !important;
    }
    .mb-sm-14,
    .my-sm-14
    {
        margin-bottom: 16rem !important;
    }
    .ml-sm-14,
    .mx-sm-14
    {
        margin-left: 16rem !important;
    }
    .m-sm-15
    {
        margin: 17rem !important;
    }
    .mt-sm-15,
    .my-sm-15
    {
        margin-top: 17rem !important;
    }
    .mr-sm-15,
    .mx-sm-15
    {
        margin-right: 17rem !important;
    }
    .mb-sm-15,
    .my-sm-15
    {
        margin-bottom: 17rem !important;
    }
    .ml-sm-15,
    .mx-sm-15
    {
        margin-left: 17rem !important;
    }
    .p-sm-0
    {
        padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0
    {
        padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0
    {
        padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0
    {
        padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0
    {
        padding-left: 0 !important;
    }
    .p-sm-1
    {
        padding: .25rem !important;
    }
    .pt-sm-1,
    .py-sm-1
    {
        padding-top: .25rem !important;
    }
    .pr-sm-1,
    .px-sm-1
    {
        padding-right: .25rem !important;
    }
    .pb-sm-1,
    .py-sm-1
    {
        padding-bottom: .25rem !important;
    }
    .pl-sm-1,
    .px-sm-1
    {
        padding-left: .25rem !important;
    }
    .p-sm-2
    {
        padding: .5rem !important;
    }
    .pt-sm-2,
    .py-sm-2
    {
        padding-top: .5rem !important;
    }
    .pr-sm-2,
    .px-sm-2
    {
        padding-right: .5rem !important;
    }
    .pb-sm-2,
    .py-sm-2
    {
        padding-bottom: .5rem !important;
    }
    .pl-sm-2,
    .px-sm-2
    {
        padding-left: .5rem !important;
    }
    .p-sm-3
    {
        padding: 1rem !important;
    }
    .pt-sm-3,
    .py-sm-3
    {
        padding-top: 1rem !important;
    }
    .pr-sm-3,
    .px-sm-3
    {
        padding-right: 1rem !important;
    }
    .pb-sm-3,
    .py-sm-3
    {
        padding-bottom: 1rem !important;
    }
    .pl-sm-3,
    .px-sm-3
    {
        padding-left: 1rem !important;
    }
    .p-sm-4
    {
        padding: 1.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4
    {
        padding-top: 1.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4
    {
        padding-right: 1.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4
    {
        padding-left: 1.5rem !important;
    }
    .p-sm-5
    {
        padding: 3rem !important;
    }
    .pt-sm-5,
    .py-sm-5
    {
        padding-top: 3rem !important;
    }
    .pr-sm-5,
    .px-sm-5
    {
        padding-right: 3rem !important;
    }
    .pb-sm-5,
    .py-sm-5
    {
        padding-bottom: 3rem !important;
    }
    .pl-sm-5,
    .px-sm-5
    {
        padding-left: 3rem !important;
    }
    .p-sm-6
    {
        padding: 4.5rem !important;
    }
    .pt-sm-6,
    .py-sm-6
    {
        padding-top: 4.5rem !important;
    }
    .pr-sm-6,
    .px-sm-6
    {
        padding-right: 4.5rem !important;
    }
    .pb-sm-6,
    .py-sm-6
    {
        padding-bottom: 4.5rem !important;
    }
    .pl-sm-6,
    .px-sm-6
    {
        padding-left: 4.5rem !important;
    }
    .p-sm-7
    {
        padding: 6rem !important;
    }
    .pt-sm-7,
    .py-sm-7
    {
        padding-top: 6rem !important;
    }
    .pr-sm-7,
    .px-sm-7
    {
        padding-right: 6rem !important;
    }
    .pb-sm-7,
    .py-sm-7
    {
        padding-bottom: 6rem !important;
    }
    .pl-sm-7,
    .px-sm-7
    {
        padding-left: 6rem !important;
    }
    .p-sm-8
    {
        padding: 7.5rem !important;
    }
    .pt-sm-8,
    .py-sm-8
    {
        padding-top: 7.5rem !important;
    }
    .pr-sm-8,
    .px-sm-8
    {
        padding-right: 7.5rem !important;
    }
    .pb-sm-8,
    .py-sm-8
    {
        padding-bottom: 7.5rem !important;
    }
    .pl-sm-8,
    .px-sm-8
    {
        padding-left: 7.5rem !important;
    }
    .p-sm-9
    {
        padding: 9rem !important;
    }
    .pt-sm-9,
    .py-sm-9
    {
        padding-top: 9rem !important;
    }
    .pr-sm-9,
    .px-sm-9
    {
        padding-right: 9rem !important;
    }
    .pb-sm-9,
    .py-sm-9
    {
        padding-bottom: 9rem !important;
    }
    .pl-sm-9,
    .px-sm-9
    {
        padding-left: 9rem !important;
    }
    .p-sm-10
    {
        padding: 10rem !important;
    }
    .pt-sm-10,
    .py-sm-10
    {
        padding-top: 10rem !important;
    }
    .pr-sm-10,
    .px-sm-10
    {
        padding-right: 10rem !important;
    }
    .pb-sm-10,
    .py-sm-10
    {
        padding-bottom: 10rem !important;
    }
    .pl-sm-10,
    .px-sm-10
    {
        padding-left: 10rem !important;
    }
    .p-sm-11
    {
        padding: 12rem !important;
    }
    .pt-sm-11,
    .py-sm-11
    {
        padding-top: 12rem !important;
    }
    .pr-sm-11,
    .px-sm-11
    {
        padding-right: 12rem !important;
    }
    .pb-sm-11,
    .py-sm-11
    {
        padding-bottom: 12rem !important;
    }
    .pl-sm-11,
    .px-sm-11
    {
        padding-left: 12rem !important;
    }
    .p-sm-12
    {
        padding: 14.5rem !important;
    }
    .pt-sm-12,
    .py-sm-12
    {
        padding-top: 14.5rem !important;
    }
    .pr-sm-12,
    .px-sm-12
    {
        padding-right: 14.5rem !important;
    }
    .pb-sm-12,
    .py-sm-12
    {
        padding-bottom: 14.5rem !important;
    }
    .pl-sm-12,
    .px-sm-12
    {
        padding-left: 14.5rem !important;
    }
    .p-sm-13
    {
        padding: 15rem !important;
    }
    .pt-sm-13,
    .py-sm-13
    {
        padding-top: 15rem !important;
    }
    .pr-sm-13,
    .px-sm-13
    {
        padding-right: 15rem !important;
    }
    .pb-sm-13,
    .py-sm-13
    {
        padding-bottom: 15rem !important;
    }
    .pl-sm-13,
    .px-sm-13
    {
        padding-left: 15rem !important;
    }
    .p-sm-14
    {
        padding: 16rem !important;
    }
    .pt-sm-14,
    .py-sm-14
    {
        padding-top: 16rem !important;
    }
    .pr-sm-14,
    .px-sm-14
    {
        padding-right: 16rem !important;
    }
    .pb-sm-14,
    .py-sm-14
    {
        padding-bottom: 16rem !important;
    }
    .pl-sm-14,
    .px-sm-14
    {
        padding-left: 16rem !important;
    }
    .p-sm-15
    {
        padding: 17rem !important;
    }
    .pt-sm-15,
    .py-sm-15
    {
        padding-top: 17rem !important;
    }
    .pr-sm-15,
    .px-sm-15
    {
        padding-right: 17rem !important;
    }
    .pb-sm-15,
    .py-sm-15
    {
        padding-bottom: 17rem !important;
    }
    .pl-sm-15,
    .px-sm-15
    {
        padding-left: 17rem !important;
    }
    .m-sm-n1
    {
        margin: -.25rem !important;
    }
    .mt-sm-n1,
    .my-sm-n1
    {
        margin-top: -.25rem !important;
    }
    .mr-sm-n1,
    .mx-sm-n1
    {
        margin-right: -.25rem !important;
    }
    .mb-sm-n1,
    .my-sm-n1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-sm-n1,
    .mx-sm-n1
    {
        margin-left: -.25rem !important;
    }
    .m-sm-n2
    {
        margin: -.5rem !important;
    }
    .mt-sm-n2,
    .my-sm-n2
    {
        margin-top: -.5rem !important;
    }
    .mr-sm-n2,
    .mx-sm-n2
    {
        margin-right: -.5rem !important;
    }
    .mb-sm-n2,
    .my-sm-n2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-sm-n2,
    .mx-sm-n2
    {
        margin-left: -.5rem !important;
    }
    .m-sm-n3
    {
        margin: -1rem !important;
    }
    .mt-sm-n3,
    .my-sm-n3
    {
        margin-top: -1rem !important;
    }
    .mr-sm-n3,
    .mx-sm-n3
    {
        margin-right: -1rem !important;
    }
    .mb-sm-n3,
    .my-sm-n3
    {
        margin-bottom: -1rem !important;
    }
    .ml-sm-n3,
    .mx-sm-n3
    {
        margin-left: -1rem !important;
    }
    .m-sm-n4
    {
        margin: -1.5rem !important;
    }
    .mt-sm-n4,
    .my-sm-n4
    {
        margin-top: -1.5rem !important;
    }
    .mr-sm-n4,
    .mx-sm-n4
    {
        margin-right: -1.5rem !important;
    }
    .mb-sm-n4,
    .my-sm-n4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-sm-n4,
    .mx-sm-n4
    {
        margin-left: -1.5rem !important;
    }
    .m-sm-n5
    {
        margin: -3rem !important;
    }
    .mt-sm-n5,
    .my-sm-n5
    {
        margin-top: -3rem !important;
    }
    .mr-sm-n5,
    .mx-sm-n5
    {
        margin-right: -3rem !important;
    }
    .mb-sm-n5,
    .my-sm-n5
    {
        margin-bottom: -3rem !important;
    }
    .ml-sm-n5,
    .mx-sm-n5
    {
        margin-left: -3rem !important;
    }
    .m-sm-n6
    {
        margin: -4.5rem !important;
    }
    .mt-sm-n6,
    .my-sm-n6
    {
        margin-top: -4.5rem !important;
    }
    .mr-sm-n6,
    .mx-sm-n6
    {
        margin-right: -4.5rem !important;
    }
    .mb-sm-n6,
    .my-sm-n6
    {
        margin-bottom: -4.5rem !important;
    }
    .ml-sm-n6,
    .mx-sm-n6
    {
        margin-left: -4.5rem !important;
    }
    .m-sm-n7
    {
        margin: -6rem !important;
    }
    .mt-sm-n7,
    .my-sm-n7
    {
        margin-top: -6rem !important;
    }
    .mr-sm-n7,
    .mx-sm-n7
    {
        margin-right: -6rem !important;
    }
    .mb-sm-n7,
    .my-sm-n7
    {
        margin-bottom: -6rem !important;
    }
    .ml-sm-n7,
    .mx-sm-n7
    {
        margin-left: -6rem !important;
    }
    .m-sm-n8
    {
        margin: -7.5rem !important;
    }
    .mt-sm-n8,
    .my-sm-n8
    {
        margin-top: -7.5rem !important;
    }
    .mr-sm-n8,
    .mx-sm-n8
    {
        margin-right: -7.5rem !important;
    }
    .mb-sm-n8,
    .my-sm-n8
    {
        margin-bottom: -7.5rem !important;
    }
    .ml-sm-n8,
    .mx-sm-n8
    {
        margin-left: -7.5rem !important;
    }
    .m-sm-n9
    {
        margin: -9rem !important;
    }
    .mt-sm-n9,
    .my-sm-n9
    {
        margin-top: -9rem !important;
    }
    .mr-sm-n9,
    .mx-sm-n9
    {
        margin-right: -9rem !important;
    }
    .mb-sm-n9,
    .my-sm-n9
    {
        margin-bottom: -9rem !important;
    }
    .ml-sm-n9,
    .mx-sm-n9
    {
        margin-left: -9rem !important;
    }
    .m-sm-n10
    {
        margin: -10rem !important;
    }
    .mt-sm-n10,
    .my-sm-n10
    {
        margin-top: -10rem !important;
    }
    .mr-sm-n10,
    .mx-sm-n10
    {
        margin-right: -10rem !important;
    }
    .mb-sm-n10,
    .my-sm-n10
    {
        margin-bottom: -10rem !important;
    }
    .ml-sm-n10,
    .mx-sm-n10
    {
        margin-left: -10rem !important;
    }
    .m-sm-n11
    {
        margin: -12rem !important;
    }
    .mt-sm-n11,
    .my-sm-n11
    {
        margin-top: -12rem !important;
    }
    .mr-sm-n11,
    .mx-sm-n11
    {
        margin-right: -12rem !important;
    }
    .mb-sm-n11,
    .my-sm-n11
    {
        margin-bottom: -12rem !important;
    }
    .ml-sm-n11,
    .mx-sm-n11
    {
        margin-left: -12rem !important;
    }
    .m-sm-n12
    {
        margin: -14.5rem !important;
    }
    .mt-sm-n12,
    .my-sm-n12
    {
        margin-top: -14.5rem !important;
    }
    .mr-sm-n12,
    .mx-sm-n12
    {
        margin-right: -14.5rem !important;
    }
    .mb-sm-n12,
    .my-sm-n12
    {
        margin-bottom: -14.5rem !important;
    }
    .ml-sm-n12,
    .mx-sm-n12
    {
        margin-left: -14.5rem !important;
    }
    .m-sm-n13
    {
        margin: -15rem !important;
    }
    .mt-sm-n13,
    .my-sm-n13
    {
        margin-top: -15rem !important;
    }
    .mr-sm-n13,
    .mx-sm-n13
    {
        margin-right: -15rem !important;
    }
    .mb-sm-n13,
    .my-sm-n13
    {
        margin-bottom: -15rem !important;
    }
    .ml-sm-n13,
    .mx-sm-n13
    {
        margin-left: -15rem !important;
    }
    .m-sm-n14
    {
        margin: -16rem !important;
    }
    .mt-sm-n14,
    .my-sm-n14
    {
        margin-top: -16rem !important;
    }
    .mr-sm-n14,
    .mx-sm-n14
    {
        margin-right: -16rem !important;
    }
    .mb-sm-n14,
    .my-sm-n14
    {
        margin-bottom: -16rem !important;
    }
    .ml-sm-n14,
    .mx-sm-n14
    {
        margin-left: -16rem !important;
    }
    .m-sm-n15
    {
        margin: -17rem !important;
    }
    .mt-sm-n15,
    .my-sm-n15
    {
        margin-top: -17rem !important;
    }
    .mr-sm-n15,
    .mx-sm-n15
    {
        margin-right: -17rem !important;
    }
    .mb-sm-n15,
    .my-sm-n15
    {
        margin-bottom: -17rem !important;
    }
    .ml-sm-n15,
    .mx-sm-n15
    {
        margin-left: -17rem !important;
    }
    .m-sm-auto
    {
        margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto
    {
        margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto
    {
        margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto
    {
        margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto
    {
        margin-left: auto !important;
    }
}

@media (min-width: 768px)
{
    .m-md-0
    {
        margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0
    {
        margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0
    {
        margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0
    {
        margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0
    {
        margin-left: 0 !important;
    }
    .m-md-1
    {
        margin: .25rem !important;
    }
    .mt-md-1,
    .my-md-1
    {
        margin-top: .25rem !important;
    }
    .mr-md-1,
    .mx-md-1
    {
        margin-right: .25rem !important;
    }
    .mb-md-1,
    .my-md-1
    {
        margin-bottom: .25rem !important;
    }
    .ml-md-1,
    .mx-md-1
    {
        margin-left: .25rem !important;
    }
    .m-md-2
    {
        margin: .5rem !important;
    }
    .mt-md-2,
    .my-md-2
    {
        margin-top: .5rem !important;
    }
    .mr-md-2,
    .mx-md-2
    {
        margin-right: .5rem !important;
    }
    .mb-md-2,
    .my-md-2
    {
        margin-bottom: .5rem !important;
    }
    .ml-md-2,
    .mx-md-2
    {
        margin-left: .5rem !important;
    }
    .m-md-3
    {
        margin: 1rem !important;
    }
    .mt-md-3,
    .my-md-3
    {
        margin-top: 1rem !important;
    }
    .mr-md-3,
    .mx-md-3
    {
        margin-right: 1rem !important;
    }
    .mb-md-3,
    .my-md-3
    {
        margin-bottom: 1rem !important;
    }
    .ml-md-3,
    .mx-md-3
    {
        margin-left: 1rem !important;
    }
    .m-md-4
    {
        margin: 1.5rem !important;
    }
    .mt-md-4,
    .my-md-4
    {
        margin-top: 1.5rem !important;
    }
    .mr-md-4,
    .mx-md-4
    {
        margin-right: 1.5rem !important;
    }
    .mb-md-4,
    .my-md-4
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-md-4,
    .mx-md-4
    {
        margin-left: 1.5rem !important;
    }
    .m-md-5
    {
        margin: 3rem !important;
    }
    .mt-md-5,
    .my-md-5
    {
        margin-top: 3rem !important;
    }
    .mr-md-5,
    .mx-md-5
    {
        margin-right: 3rem !important;
    }
    .mb-md-5,
    .my-md-5
    {
        margin-bottom: 3rem !important;
    }
    .ml-md-5,
    .mx-md-5
    {
        margin-left: 3rem !important;
    }
    .m-md-6
    {
        margin: 4.5rem !important;
    }
    .mt-md-6,
    .my-md-6
    {
        margin-top: 4.5rem !important;
    }
    .mr-md-6,
    .mx-md-6
    {
        margin-right: 4.5rem !important;
    }
    .mb-md-6,
    .my-md-6
    {
        margin-bottom: 4.5rem !important;
    }
    .ml-md-6,
    .mx-md-6
    {
        margin-left: 4.5rem !important;
    }
    .m-md-7
    {
        margin: 6rem !important;
    }
    .mt-md-7,
    .my-md-7
    {
        margin-top: 6rem !important;
    }
    .mr-md-7,
    .mx-md-7
    {
        margin-right: 6rem !important;
    }
    .mb-md-7,
    .my-md-7
    {
        margin-bottom: 6rem !important;
    }
    .ml-md-7,
    .mx-md-7
    {
        margin-left: 6rem !important;
    }
    .m-md-8
    {
        margin: 7.5rem !important;
    }
    .mt-md-8,
    .my-md-8
    {
        margin-top: 7.5rem !important;
    }
    .mr-md-8,
    .mx-md-8
    {
        margin-right: 7.5rem !important;
    }
    .mb-md-8,
    .my-md-8
    {
        margin-bottom: 7.5rem !important;
    }
    .ml-md-8,
    .mx-md-8
    {
        margin-left: 7.5rem !important;
    }
    .m-md-9
    {
        margin: 9rem !important;
    }
    .mt-md-9,
    .my-md-9
    {
        margin-top: 9rem !important;
    }
    .mr-md-9,
    .mx-md-9
    {
        margin-right: 9rem !important;
    }
    .mb-md-9,
    .my-md-9
    {
        margin-bottom: 9rem !important;
    }
    .ml-md-9,
    .mx-md-9
    {
        margin-left: 9rem !important;
    }
    .m-md-10
    {
        margin: 10rem !important;
    }
    .mt-md-10,
    .my-md-10
    {
        margin-top: 10rem !important;
    }
    .mr-md-10,
    .mx-md-10
    {
        margin-right: 10rem !important;
    }
    .mb-md-10,
    .my-md-10
    {
        margin-bottom: 10rem !important;
    }
    .ml-md-10,
    .mx-md-10
    {
        margin-left: 10rem !important;
    }
    .m-md-11
    {
        margin: 12rem !important;
    }
    .mt-md-11,
    .my-md-11
    {
        margin-top: 12rem !important;
    }
    .mr-md-11,
    .mx-md-11
    {
        margin-right: 12rem !important;
    }
    .mb-md-11,
    .my-md-11
    {
        margin-bottom: 12rem !important;
    }
    .ml-md-11,
    .mx-md-11
    {
        margin-left: 12rem !important;
    }
    .m-md-12
    {
        margin: 14.5rem !important;
    }
    .mt-md-12,
    .my-md-12
    {
        margin-top: 14.5rem !important;
    }
    .mr-md-12,
    .mx-md-12
    {
        margin-right: 14.5rem !important;
    }
    .mb-md-12,
    .my-md-12
    {
        margin-bottom: 14.5rem !important;
    }
    .ml-md-12,
    .mx-md-12
    {
        margin-left: 14.5rem !important;
    }
    .m-md-13
    {
        margin: 15rem !important;
    }
    .mt-md-13,
    .my-md-13
    {
        margin-top: 15rem !important;
    }
    .mr-md-13,
    .mx-md-13
    {
        margin-right: 15rem !important;
    }
    .mb-md-13,
    .my-md-13
    {
        margin-bottom: 15rem !important;
    }
    .ml-md-13,
    .mx-md-13
    {
        margin-left: 15rem !important;
    }
    .m-md-14
    {
        margin: 16rem !important;
    }
    .mt-md-14,
    .my-md-14
    {
        margin-top: 16rem !important;
    }
    .mr-md-14,
    .mx-md-14
    {
        margin-right: 16rem !important;
    }
    .mb-md-14,
    .my-md-14
    {
        margin-bottom: 16rem !important;
    }
    .ml-md-14,
    .mx-md-14
    {
        margin-left: 16rem !important;
    }
    .m-md-15
    {
        margin: 17rem !important;
    }
    .mt-md-15,
    .my-md-15
    {
        margin-top: 17rem !important;
    }
    .mr-md-15,
    .mx-md-15
    {
        margin-right: 17rem !important;
    }
    .mb-md-15,
    .my-md-15
    {
        margin-bottom: 17rem !important;
    }
    .ml-md-15,
    .mx-md-15
    {
        margin-left: 17rem !important;
    }
    .p-md-0
    {
        padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0
    {
        padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0
    {
        padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0
    {
        padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0
    {
        padding-left: 0 !important;
    }
    .p-md-1
    {
        padding: .25rem !important;
    }
    .pt-md-1,
    .py-md-1
    {
        padding-top: .25rem !important;
    }
    .pr-md-1,
    .px-md-1
    {
        padding-right: .25rem !important;
    }
    .pb-md-1,
    .py-md-1
    {
        padding-bottom: .25rem !important;
    }
    .pl-md-1,
    .px-md-1
    {
        padding-left: .25rem !important;
    }
    .p-md-2
    {
        padding: .5rem !important;
    }
    .pt-md-2,
    .py-md-2
    {
        padding-top: .5rem !important;
    }
    .pr-md-2,
    .px-md-2
    {
        padding-right: .5rem !important;
    }
    .pb-md-2,
    .py-md-2
    {
        padding-bottom: .5rem !important;
    }
    .pl-md-2,
    .px-md-2
    {
        padding-left: .5rem !important;
    }
    .p-md-3
    {
        padding: 1rem !important;
    }
    .pt-md-3,
    .py-md-3
    {
        padding-top: 1rem !important;
    }
    .pr-md-3,
    .px-md-3
    {
        padding-right: 1rem !important;
    }
    .pb-md-3,
    .py-md-3
    {
        padding-bottom: 1rem !important;
    }
    .pl-md-3,
    .px-md-3
    {
        padding-left: 1rem !important;
    }
    .p-md-4
    {
        padding: 1.5rem !important;
    }
    .pt-md-4,
    .py-md-4
    {
        padding-top: 1.5rem !important;
    }
    .pr-md-4,
    .px-md-4
    {
        padding-right: 1.5rem !important;
    }
    .pb-md-4,
    .py-md-4
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-md-4,
    .px-md-4
    {
        padding-left: 1.5rem !important;
    }
    .p-md-5
    {
        padding: 3rem !important;
    }
    .pt-md-5,
    .py-md-5
    {
        padding-top: 3rem !important;
    }
    .pr-md-5,
    .px-md-5
    {
        padding-right: 3rem !important;
    }
    .pb-md-5,
    .py-md-5
    {
        padding-bottom: 3rem !important;
    }
    .pl-md-5,
    .px-md-5
    {
        padding-left: 3rem !important;
    }
    .p-md-6
    {
        padding: 4.5rem !important;
    }
    .pt-md-6,
    .py-md-6
    {
        padding-top: 4.5rem !important;
    }
    .pr-md-6,
    .px-md-6
    {
        padding-right: 4.5rem !important;
    }
    .pb-md-6,
    .py-md-6
    {
        padding-bottom: 4.5rem !important;
    }
    .pl-md-6,
    .px-md-6
    {
        padding-left: 4.5rem !important;
    }
    .p-md-7
    {
        padding: 6rem !important;
    }
    .pt-md-7,
    .py-md-7
    {
        padding-top: 6rem !important;
    }
    .pr-md-7,
    .px-md-7
    {
        padding-right: 6rem !important;
    }
    .pb-md-7,
    .py-md-7
    {
        padding-bottom: 6rem !important;
    }
    .pl-md-7,
    .px-md-7
    {
        padding-left: 6rem !important;
    }
    .p-md-8
    {
        padding: 7.5rem !important;
    }
    .pt-md-8,
    .py-md-8
    {
        padding-top: 7.5rem !important;
    }
    .pr-md-8,
    .px-md-8
    {
        padding-right: 7.5rem !important;
    }
    .pb-md-8,
    .py-md-8
    {
        padding-bottom: 7.5rem !important;
    }
    .pl-md-8,
    .px-md-8
    {
        padding-left: 7.5rem !important;
    }
    .p-md-9
    {
        padding: 9rem !important;
    }
    .pt-md-9,
    .py-md-9
    {
        padding-top: 9rem !important;
    }
    .pr-md-9,
    .px-md-9
    {
        padding-right: 9rem !important;
    }
    .pb-md-9,
    .py-md-9
    {
        padding-bottom: 9rem !important;
    }
    .pl-md-9,
    .px-md-9
    {
        padding-left: 9rem !important;
    }
    .p-md-10
    {
        padding: 10rem !important;
    }
    .pt-md-10,
    .py-md-10
    {
        padding-top: 10rem !important;
    }
    .pr-md-10,
    .px-md-10
    {
        padding-right: 10rem !important;
    }
    .pb-md-10,
    .py-md-10
    {
        padding-bottom: 10rem !important;
    }
    .pl-md-10,
    .px-md-10
    {
        padding-left: 10rem !important;
    }
    .p-md-11
    {
        padding: 12rem !important;
    }
    .pt-md-11,
    .py-md-11
    {
        padding-top: 12rem !important;
    }
    .pr-md-11,
    .px-md-11
    {
        padding-right: 12rem !important;
    }
    .pb-md-11,
    .py-md-11
    {
        padding-bottom: 12rem !important;
    }
    .pl-md-11,
    .px-md-11
    {
        padding-left: 12rem !important;
    }
    .p-md-12
    {
        padding: 14.5rem !important;
    }
    .pt-md-12,
    .py-md-12
    {
        padding-top: 14.5rem !important;
    }
    .pr-md-12,
    .px-md-12
    {
        padding-right: 14.5rem !important;
    }
    .pb-md-12,
    .py-md-12
    {
        padding-bottom: 14.5rem !important;
    }
    .pl-md-12,
    .px-md-12
    {
        padding-left: 14.5rem !important;
    }
    .p-md-13
    {
        padding: 15rem !important;
    }
    .pt-md-13,
    .py-md-13
    {
        padding-top: 15rem !important;
    }
    .pr-md-13,
    .px-md-13
    {
        padding-right: 15rem !important;
    }
    .pb-md-13,
    .py-md-13
    {
        padding-bottom: 15rem !important;
    }
    .pl-md-13,
    .px-md-13
    {
        padding-left: 15rem !important;
    }
    .p-md-14
    {
        padding: 16rem !important;
    }
    .pt-md-14,
    .py-md-14
    {
        padding-top: 16rem !important;
    }
    .pr-md-14,
    .px-md-14
    {
        padding-right: 16rem !important;
    }
    .pb-md-14,
    .py-md-14
    {
        padding-bottom: 16rem !important;
    }
    .pl-md-14,
    .px-md-14
    {
        padding-left: 16rem !important;
    }
    .p-md-15
    {
        padding: 17rem !important;
    }
    .pt-md-15,
    .py-md-15
    {
        padding-top: 17rem !important;
    }
    .pr-md-15,
    .px-md-15
    {
        padding-right: 17rem !important;
    }
    .pb-md-15,
    .py-md-15
    {
        padding-bottom: 17rem !important;
    }
    .pl-md-15,
    .px-md-15
    {
        padding-left: 17rem !important;
    }
    .m-md-n1
    {
        margin: -.25rem !important;
    }
    .mt-md-n1,
    .my-md-n1
    {
        margin-top: -.25rem !important;
    }
    .mr-md-n1,
    .mx-md-n1
    {
        margin-right: -.25rem !important;
    }
    .mb-md-n1,
    .my-md-n1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-md-n1,
    .mx-md-n1
    {
        margin-left: -.25rem !important;
    }
    .m-md-n2
    {
        margin: -.5rem !important;
    }
    .mt-md-n2,
    .my-md-n2
    {
        margin-top: -.5rem !important;
    }
    .mr-md-n2,
    .mx-md-n2
    {
        margin-right: -.5rem !important;
    }
    .mb-md-n2,
    .my-md-n2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-md-n2,
    .mx-md-n2
    {
        margin-left: -.5rem !important;
    }
    .m-md-n3
    {
        margin: -1rem !important;
    }
    .mt-md-n3,
    .my-md-n3
    {
        margin-top: -1rem !important;
    }
    .mr-md-n3,
    .mx-md-n3
    {
        margin-right: -1rem !important;
    }
    .mb-md-n3,
    .my-md-n3
    {
        margin-bottom: -1rem !important;
    }
    .ml-md-n3,
    .mx-md-n3
    {
        margin-left: -1rem !important;
    }
    .m-md-n4
    {
        margin: -1.5rem !important;
    }
    .mt-md-n4,
    .my-md-n4
    {
        margin-top: -1.5rem !important;
    }
    .mr-md-n4,
    .mx-md-n4
    {
        margin-right: -1.5rem !important;
    }
    .mb-md-n4,
    .my-md-n4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-md-n4,
    .mx-md-n4
    {
        margin-left: -1.5rem !important;
    }
    .m-md-n5
    {
        margin: -3rem !important;
    }
    .mt-md-n5,
    .my-md-n5
    {
        margin-top: -3rem !important;
    }
    .mr-md-n5,
    .mx-md-n5
    {
        margin-right: -3rem !important;
    }
    .mb-md-n5,
    .my-md-n5
    {
        margin-bottom: -3rem !important;
    }
    .ml-md-n5,
    .mx-md-n5
    {
        margin-left: -3rem !important;
    }
    .m-md-n6
    {
        margin: -4.5rem !important;
    }
    .mt-md-n6,
    .my-md-n6
    {
        margin-top: -4.5rem !important;
    }
    .mr-md-n6,
    .mx-md-n6
    {
        margin-right: -4.5rem !important;
    }
    .mb-md-n6,
    .my-md-n6
    {
        margin-bottom: -4.5rem !important;
    }
    .ml-md-n6,
    .mx-md-n6
    {
        margin-left: -4.5rem !important;
    }
    .m-md-n7
    {
        margin: -6rem !important;
    }
    .mt-md-n7,
    .my-md-n7
    {
        margin-top: -6rem !important;
    }
    .mr-md-n7,
    .mx-md-n7
    {
        margin-right: -6rem !important;
    }
    .mb-md-n7,
    .my-md-n7
    {
        margin-bottom: -6rem !important;
    }
    .ml-md-n7,
    .mx-md-n7
    {
        margin-left: -6rem !important;
    }
    .m-md-n8
    {
        margin: -7.5rem !important;
    }
    .mt-md-n8,
    .my-md-n8
    {
        margin-top: -7.5rem !important;
    }
    .mr-md-n8,
    .mx-md-n8
    {
        margin-right: -7.5rem !important;
    }
    .mb-md-n8,
    .my-md-n8
    {
        margin-bottom: -7.5rem !important;
    }
    .ml-md-n8,
    .mx-md-n8
    {
        margin-left: -7.5rem !important;
    }
    .m-md-n9
    {
        margin: -9rem !important;
    }
    .mt-md-n9,
    .my-md-n9
    {
        margin-top: -9rem !important;
    }
    .mr-md-n9,
    .mx-md-n9
    {
        margin-right: -9rem !important;
    }
    .mb-md-n9,
    .my-md-n9
    {
        margin-bottom: -9rem !important;
    }
    .ml-md-n9,
    .mx-md-n9
    {
        margin-left: -9rem !important;
    }
    .m-md-n10
    {
        margin: -10rem !important;
    }
    .mt-md-n10,
    .my-md-n10
    {
        margin-top: -10rem !important;
    }
    .mr-md-n10,
    .mx-md-n10
    {
        margin-right: -10rem !important;
    }
    .mb-md-n10,
    .my-md-n10
    {
        margin-bottom: -10rem !important;
    }
    .ml-md-n10,
    .mx-md-n10
    {
        margin-left: -10rem !important;
    }
    .m-md-n11
    {
        margin: -12rem !important;
    }
    .mt-md-n11,
    .my-md-n11
    {
        margin-top: -12rem !important;
    }
    .mr-md-n11,
    .mx-md-n11
    {
        margin-right: -12rem !important;
    }
    .mb-md-n11,
    .my-md-n11
    {
        margin-bottom: -12rem !important;
    }
    .ml-md-n11,
    .mx-md-n11
    {
        margin-left: -12rem !important;
    }
    .m-md-n12
    {
        margin: -14.5rem !important;
    }
    .mt-md-n12,
    .my-md-n12
    {
        margin-top: -14.5rem !important;
    }
    .mr-md-n12,
    .mx-md-n12
    {
        margin-right: -14.5rem !important;
    }
    .mb-md-n12,
    .my-md-n12
    {
        margin-bottom: -14.5rem !important;
    }
    .ml-md-n12,
    .mx-md-n12
    {
        margin-left: -14.5rem !important;
    }
    .m-md-n13
    {
        margin: -15rem !important;
    }
    .mt-md-n13,
    .my-md-n13
    {
        margin-top: -15rem !important;
    }
    .mr-md-n13,
    .mx-md-n13
    {
        margin-right: -15rem !important;
    }
    .mb-md-n13,
    .my-md-n13
    {
        margin-bottom: -15rem !important;
    }
    .ml-md-n13,
    .mx-md-n13
    {
        margin-left: -15rem !important;
    }
    .m-md-n14
    {
        margin: -16rem !important;
    }
    .mt-md-n14,
    .my-md-n14
    {
        margin-top: -16rem !important;
    }
    .mr-md-n14,
    .mx-md-n14
    {
        margin-right: -16rem !important;
    }
    .mb-md-n14,
    .my-md-n14
    {
        margin-bottom: -16rem !important;
    }
    .ml-md-n14,
    .mx-md-n14
    {
        margin-left: -16rem !important;
    }
    .m-md-n15
    {
        margin: -17rem !important;
    }
    .mt-md-n15,
    .my-md-n15
    {
        margin-top: -17rem !important;
    }
    .mr-md-n15,
    .mx-md-n15
    {
        margin-right: -17rem !important;
    }
    .mb-md-n15,
    .my-md-n15
    {
        margin-bottom: -17rem !important;
    }
    .ml-md-n15,
    .mx-md-n15
    {
        margin-left: -17rem !important;
    }
    .m-md-auto
    {
        margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto
    {
        margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto
    {
        margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto
    {
        margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto
    {
        margin-left: auto !important;
    }
}

@media (min-width: 992px)
{
    .m-lg-0
    {
        margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0
    {
        margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0
    {
        margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0
    {
        margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0
    {
        margin-left: 0 !important;
    }
    .m-lg-1
    {
        margin: .25rem !important;
    }
    .mt-lg-1,
    .my-lg-1
    {
        margin-top: .25rem !important;
    }
    .mr-lg-1,
    .mx-lg-1
    {
        margin-right: .25rem !important;
    }
    .mb-lg-1,
    .my-lg-1
    {
        margin-bottom: .25rem !important;
    }
    .ml-lg-1,
    .mx-lg-1
    {
        margin-left: .25rem !important;
    }
    .m-lg-2
    {
        margin: .5rem !important;
    }
    .mt-lg-2,
    .my-lg-2
    {
        margin-top: .5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2
    {
        margin-right: .5rem !important;
    }
    .mb-lg-2,
    .my-lg-2
    {
        margin-bottom: .5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2
    {
        margin-left: .5rem !important;
    }
    .m-lg-3
    {
        margin: 1rem !important;
    }
    .mt-lg-3,
    .my-lg-3
    {
        margin-top: 1rem !important;
    }
    .mr-lg-3,
    .mx-lg-3
    {
        margin-right: 1rem !important;
    }
    .mb-lg-3,
    .my-lg-3
    {
        margin-bottom: 1rem !important;
    }
    .ml-lg-3,
    .mx-lg-3
    {
        margin-left: 1rem !important;
    }
    .m-lg-4
    {
        margin: 1.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4
    {
        margin-top: 1.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4
    {
        margin-right: 1.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4
    {
        margin-left: 1.5rem !important;
    }
    .m-lg-5
    {
        margin: 3rem !important;
    }
    .mt-lg-5,
    .my-lg-5
    {
        margin-top: 3rem !important;
    }
    .mr-lg-5,
    .mx-lg-5
    {
        margin-right: 3rem !important;
    }
    .mb-lg-5,
    .my-lg-5
    {
        margin-bottom: 3rem !important;
    }
    .ml-lg-5,
    .mx-lg-5
    {
        margin-left: 3rem !important;
    }
    .m-lg-6
    {
        margin: 4.5rem !important;
    }
    .mt-lg-6,
    .my-lg-6
    {
        margin-top: 4.5rem !important;
    }
    .mr-lg-6,
    .mx-lg-6
    {
        margin-right: 4.5rem !important;
    }
    .mb-lg-6,
    .my-lg-6
    {
        margin-bottom: 4.5rem !important;
    }
    .ml-lg-6,
    .mx-lg-6
    {
        margin-left: 4.5rem !important;
    }
    .m-lg-7
    {
        margin: 6rem !important;
    }
    .mt-lg-7,
    .my-lg-7
    {
        margin-top: 6rem !important;
    }
    .mr-lg-7,
    .mx-lg-7
    {
        margin-right: 6rem !important;
    }
    .mb-lg-7,
    .my-lg-7
    {
        margin-bottom: 6rem !important;
    }
    .ml-lg-7,
    .mx-lg-7
    {
        margin-left: 6rem !important;
    }
    .m-lg-8
    {
        margin: 7.5rem !important;
    }
    .mt-lg-8,
    .my-lg-8
    {
        margin-top: 7.5rem !important;
    }
    .mr-lg-8,
    .mx-lg-8
    {
        margin-right: 7.5rem !important;
    }
    .mb-lg-8,
    .my-lg-8
    {
        margin-bottom: 7.5rem !important;
    }
    .ml-lg-8,
    .mx-lg-8
    {
        margin-left: 7.5rem !important;
    }
    .m-lg-9
    {
        margin: 9rem !important;
    }
    .mt-lg-9,
    .my-lg-9
    {
        margin-top: 9rem !important;
    }
    .mr-lg-9,
    .mx-lg-9
    {
        margin-right: 9rem !important;
    }
    .mb-lg-9,
    .my-lg-9
    {
        margin-bottom: 9rem !important;
    }
    .ml-lg-9,
    .mx-lg-9
    {
        margin-left: 9rem !important;
    }
    .m-lg-10
    {
        margin: 10rem !important;
    }
    .mt-lg-10,
    .my-lg-10
    {
        margin-top: 10rem !important;
    }
    .mr-lg-10,
    .mx-lg-10
    {
        margin-right: 10rem !important;
    }
    .mb-lg-10,
    .my-lg-10
    {
        margin-bottom: 10rem !important;
    }
    .ml-lg-10,
    .mx-lg-10
    {
        margin-left: 10rem !important;
    }
    .m-lg-11
    {
        margin: 12rem !important;
    }
    .mt-lg-11,
    .my-lg-11
    {
        margin-top: 12rem !important;
    }
    .mr-lg-11,
    .mx-lg-11
    {
        margin-right: 12rem !important;
    }
    .mb-lg-11,
    .my-lg-11
    {
        margin-bottom: 12rem !important;
    }
    .ml-lg-11,
    .mx-lg-11
    {
        margin-left: 12rem !important;
    }
    .m-lg-12
    {
        margin: 14.5rem !important;
    }
    .mt-lg-12,
    .my-lg-12
    {
        margin-top: 14.5rem !important;
    }
    .mr-lg-12,
    .mx-lg-12
    {
        margin-right: 14.5rem !important;
    }
    .mb-lg-12,
    .my-lg-12
    {
        margin-bottom: 14.5rem !important;
    }
    .ml-lg-12,
    .mx-lg-12
    {
        margin-left: 14.5rem !important;
    }
    .m-lg-13
    {
        margin: 15rem !important;
    }
    .mt-lg-13,
    .my-lg-13
    {
        margin-top: 15rem !important;
    }
    .mr-lg-13,
    .mx-lg-13
    {
        margin-right: 15rem !important;
    }
    .mb-lg-13,
    .my-lg-13
    {
        margin-bottom: 15rem !important;
    }
    .ml-lg-13,
    .mx-lg-13
    {
        margin-left: 15rem !important;
    }
    .m-lg-14
    {
        margin: 16rem !important;
    }
    .mt-lg-14,
    .my-lg-14
    {
        margin-top: 16rem !important;
    }
    .mr-lg-14,
    .mx-lg-14
    {
        margin-right: 16rem !important;
    }
    .mb-lg-14,
    .my-lg-14
    {
        margin-bottom: 16rem !important;
    }
    .ml-lg-14,
    .mx-lg-14
    {
        margin-left: 16rem !important;
    }
    .m-lg-15
    {
        margin: 17rem !important;
    }
    .mt-lg-15,
    .my-lg-15
    {
        margin-top: 17rem !important;
    }
    .mr-lg-15,
    .mx-lg-15
    {
        margin-right: 17rem !important;
    }
    .mb-lg-15,
    .my-lg-15
    {
        margin-bottom: 17rem !important;
    }
    .ml-lg-15,
    .mx-lg-15
    {
        margin-left: 17rem !important;
    }
    .p-lg-0
    {
        padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0
    {
        padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0
    {
        padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0
    {
        padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0
    {
        padding-left: 0 !important;
    }
    .p-lg-1
    {
        padding: .25rem !important;
    }
    .pt-lg-1,
    .py-lg-1
    {
        padding-top: .25rem !important;
    }
    .pr-lg-1,
    .px-lg-1
    {
        padding-right: .25rem !important;
    }
    .pb-lg-1,
    .py-lg-1
    {
        padding-bottom: .25rem !important;
    }
    .pl-lg-1,
    .px-lg-1
    {
        padding-left: .25rem !important;
    }
    .p-lg-2
    {
        padding: .5rem !important;
    }
    .pt-lg-2,
    .py-lg-2
    {
        padding-top: .5rem !important;
    }
    .pr-lg-2,
    .px-lg-2
    {
        padding-right: .5rem !important;
    }
    .pb-lg-2,
    .py-lg-2
    {
        padding-bottom: .5rem !important;
    }
    .pl-lg-2,
    .px-lg-2
    {
        padding-left: .5rem !important;
    }
    .p-lg-3
    {
        padding: 1rem !important;
    }
    .pt-lg-3,
    .py-lg-3
    {
        padding-top: 1rem !important;
    }
    .pr-lg-3,
    .px-lg-3
    {
        padding-right: 1rem !important;
    }
    .pb-lg-3,
    .py-lg-3
    {
        padding-bottom: 1rem !important;
    }
    .pl-lg-3,
    .px-lg-3
    {
        padding-left: 1rem !important;
    }
    .p-lg-4
    {
        padding: 1.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4
    {
        padding-top: 1.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4
    {
        padding-right: 1.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4
    {
        padding-left: 1.5rem !important;
    }
    .p-lg-5
    {
        padding: 3rem !important;
    }
    .pt-lg-5,
    .py-lg-5
    {
        padding-top: 3rem !important;
    }
    .pr-lg-5,
    .px-lg-5
    {
        padding-right: 3rem !important;
    }
    .pb-lg-5,
    .py-lg-5
    {
        padding-bottom: 3rem !important;
    }
    .pl-lg-5,
    .px-lg-5
    {
        padding-left: 3rem !important;
    }
    .p-lg-6
    {
        padding: 4.5rem !important;
    }
    .pt-lg-6,
    .py-lg-6
    {
        padding-top: 4.5rem !important;
    }
    .pr-lg-6,
    .px-lg-6
    {
        padding-right: 4.5rem !important;
    }
    .pb-lg-6,
    .py-lg-6
    {
        padding-bottom: 4.5rem !important;
    }
    .pl-lg-6,
    .px-lg-6
    {
        padding-left: 4.5rem !important;
    }
    .p-lg-7
    {
        padding: 6rem !important;
    }
    .pt-lg-7,
    .py-lg-7
    {
        padding-top: 6rem !important;
    }
    .pr-lg-7,
    .px-lg-7
    {
        padding-right: 6rem !important;
    }
    .pb-lg-7,
    .py-lg-7
    {
        padding-bottom: 6rem !important;
    }
    .pl-lg-7,
    .px-lg-7
    {
        padding-left: 6rem !important;
    }
    .p-lg-8
    {
        padding: 7.5rem !important;
    }
    .pt-lg-8,
    .py-lg-8
    {
        padding-top: 7.5rem !important;
    }
    .pr-lg-8,
    .px-lg-8
    {
        padding-right: 7.5rem !important;
    }
    .pb-lg-8,
    .py-lg-8
    {
        padding-bottom: 7.5rem !important;
    }
    .pl-lg-8,
    .px-lg-8
    {
        padding-left: 7.5rem !important;
    }
    .p-lg-9
    {
        padding: 9rem !important;
    }
    .pt-lg-9,
    .py-lg-9
    {
        padding-top: 9rem !important;
    }
    .pr-lg-9,
    .px-lg-9
    {
        padding-right: 9rem !important;
    }
    .pb-lg-9,
    .py-lg-9
    {
        padding-bottom: 9rem !important;
    }
    .pl-lg-9,
    .px-lg-9
    {
        padding-left: 9rem !important;
    }
    .p-lg-10
    {
        padding: 10rem !important;
    }
    .pt-lg-10,
    .py-lg-10
    {
        padding-top: 10rem !important;
    }
    .pr-lg-10,
    .px-lg-10
    {
        padding-right: 10rem !important;
    }
    .pb-lg-10,
    .py-lg-10
    {
        padding-bottom: 10rem !important;
    }
    .pl-lg-10,
    .px-lg-10
    {
        padding-left: 10rem !important;
    }
    .p-lg-11
    {
        padding: 12rem !important;
    }
    .pt-lg-11,
    .py-lg-11
    {
        padding-top: 12rem !important;
    }
    .pr-lg-11,
    .px-lg-11
    {
        padding-right: 12rem !important;
    }
    .pb-lg-11,
    .py-lg-11
    {
        padding-bottom: 12rem !important;
    }
    .pl-lg-11,
    .px-lg-11
    {
        padding-left: 12rem !important;
    }
    .p-lg-12
    {
        padding: 14.5rem !important;
    }
    .pt-lg-12,
    .py-lg-12
    {
        padding-top: 14.5rem !important;
    }
    .pr-lg-12,
    .px-lg-12
    {
        padding-right: 14.5rem !important;
    }
    .pb-lg-12,
    .py-lg-12
    {
        padding-bottom: 14.5rem !important;
    }
    .pl-lg-12,
    .px-lg-12
    {
        padding-left: 14.5rem !important;
    }
    .p-lg-13
    {
        padding: 15rem !important;
    }
    .pt-lg-13,
    .py-lg-13
    {
        padding-top: 15rem !important;
    }
    .pr-lg-13,
    .px-lg-13
    {
        padding-right: 15rem !important;
    }
    .pb-lg-13,
    .py-lg-13
    {
        padding-bottom: 15rem !important;
    }
    .pl-lg-13,
    .px-lg-13
    {
        padding-left: 15rem !important;
    }
    .p-lg-14
    {
        padding: 16rem !important;
    }
    .pt-lg-14,
    .py-lg-14
    {
        padding-top: 16rem !important;
    }
    .pr-lg-14,
    .px-lg-14
    {
        padding-right: 16rem !important;
    }
    .pb-lg-14,
    .py-lg-14
    {
        padding-bottom: 16rem !important;
    }
    .pl-lg-14,
    .px-lg-14
    {
        padding-left: 16rem !important;
    }
    .p-lg-15
    {
        padding: 17rem !important;
    }
    .pt-lg-15,
    .py-lg-15
    {
        padding-top: 17rem !important;
    }
    .pr-lg-15,
    .px-lg-15
    {
        padding-right: 17rem !important;
    }
    .pb-lg-15,
    .py-lg-15
    {
        padding-bottom: 17rem !important;
    }
    .pl-lg-15,
    .px-lg-15
    {
        padding-left: 17rem !important;
    }
    .m-lg-n1
    {
        margin: -.25rem !important;
    }
    .mt-lg-n1,
    .my-lg-n1
    {
        margin-top: -.25rem !important;
    }
    .mr-lg-n1,
    .mx-lg-n1
    {
        margin-right: -.25rem !important;
    }
    .mb-lg-n1,
    .my-lg-n1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-lg-n1,
    .mx-lg-n1
    {
        margin-left: -.25rem !important;
    }
    .m-lg-n2
    {
        margin: -.5rem !important;
    }
    .mt-lg-n2,
    .my-lg-n2
    {
        margin-top: -.5rem !important;
    }
    .mr-lg-n2,
    .mx-lg-n2
    {
        margin-right: -.5rem !important;
    }
    .mb-lg-n2,
    .my-lg-n2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-lg-n2,
    .mx-lg-n2
    {
        margin-left: -.5rem !important;
    }
    .m-lg-n3
    {
        margin: -1rem !important;
    }
    .mt-lg-n3,
    .my-lg-n3
    {
        margin-top: -1rem !important;
    }
    .mr-lg-n3,
    .mx-lg-n3
    {
        margin-right: -1rem !important;
    }
    .mb-lg-n3,
    .my-lg-n3
    {
        margin-bottom: -1rem !important;
    }
    .ml-lg-n3,
    .mx-lg-n3
    {
        margin-left: -1rem !important;
    }
    .m-lg-n4
    {
        margin: -1.5rem !important;
    }
    .mt-lg-n4,
    .my-lg-n4
    {
        margin-top: -1.5rem !important;
    }
    .mr-lg-n4,
    .mx-lg-n4
    {
        margin-right: -1.5rem !important;
    }
    .mb-lg-n4,
    .my-lg-n4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-lg-n4,
    .mx-lg-n4
    {
        margin-left: -1.5rem !important;
    }
    .m-lg-n5
    {
        margin: -3rem !important;
    }
    .mt-lg-n5,
    .my-lg-n5
    {
        margin-top: -3rem !important;
    }
    .mr-lg-n5,
    .mx-lg-n5
    {
        margin-right: -3rem !important;
    }
    .mb-lg-n5,
    .my-lg-n5
    {
        margin-bottom: -3rem !important;
    }
    .ml-lg-n5,
    .mx-lg-n5
    {
        margin-left: -3rem !important;
    }
    .m-lg-n6
    {
        margin: -4.5rem !important;
    }
    .mt-lg-n6,
    .my-lg-n6
    {
        margin-top: -4.5rem !important;
    }
    .mr-lg-n6,
    .mx-lg-n6
    {
        margin-right: -4.5rem !important;
    }
    .mb-lg-n6,
    .my-lg-n6
    {
        margin-bottom: -4.5rem !important;
    }
    .ml-lg-n6,
    .mx-lg-n6
    {
        margin-left: -4.5rem !important;
    }
    .m-lg-n7
    {
        margin: -6rem !important;
    }
    .mt-lg-n7,
    .my-lg-n7
    {
        margin-top: -6rem !important;
    }
    .mr-lg-n7,
    .mx-lg-n7
    {
        margin-right: -6rem !important;
    }
    .mb-lg-n7,
    .my-lg-n7
    {
        margin-bottom: -6rem !important;
    }
    .ml-lg-n7,
    .mx-lg-n7
    {
        margin-left: -6rem !important;
    }
    .m-lg-n8
    {
        margin: -7.5rem !important;
    }
    .mt-lg-n8,
    .my-lg-n8
    {
        margin-top: -7.5rem !important;
    }
    .mr-lg-n8,
    .mx-lg-n8
    {
        margin-right: -7.5rem !important;
    }
    .mb-lg-n8,
    .my-lg-n8
    {
        margin-bottom: -7.5rem !important;
    }
    .ml-lg-n8,
    .mx-lg-n8
    {
        margin-left: -7.5rem !important;
    }
    .m-lg-n9
    {
        margin: -9rem !important;
    }
    .mt-lg-n9,
    .my-lg-n9
    {
        margin-top: -9rem !important;
    }
    .mr-lg-n9,
    .mx-lg-n9
    {
        margin-right: -9rem !important;
    }
    .mb-lg-n9,
    .my-lg-n9
    {
        margin-bottom: -9rem !important;
    }
    .ml-lg-n9,
    .mx-lg-n9
    {
        margin-left: -9rem !important;
    }
    .m-lg-n10
    {
        margin: -10rem !important;
    }
    .mt-lg-n10,
    .my-lg-n10
    {
        margin-top: -10rem !important;
    }
    .mr-lg-n10,
    .mx-lg-n10
    {
        margin-right: -10rem !important;
    }
    .mb-lg-n10,
    .my-lg-n10
    {
        margin-bottom: -10rem !important;
    }
    .ml-lg-n10,
    .mx-lg-n10
    {
        margin-left: -10rem !important;
    }
    .m-lg-n11
    {
        margin: -12rem !important;
    }
    .mt-lg-n11,
    .my-lg-n11
    {
        margin-top: -12rem !important;
    }
    .mr-lg-n11,
    .mx-lg-n11
    {
        margin-right: -12rem !important;
    }
    .mb-lg-n11,
    .my-lg-n11
    {
        margin-bottom: -12rem !important;
    }
    .ml-lg-n11,
    .mx-lg-n11
    {
        margin-left: -12rem !important;
    }
    .m-lg-n12
    {
        margin: -14.5rem !important;
    }
    .mt-lg-n12,
    .my-lg-n12
    {
        margin-top: -14.5rem !important;
    }
    .mr-lg-n12,
    .mx-lg-n12
    {
        margin-right: -14.5rem !important;
    }
    .mb-lg-n12,
    .my-lg-n12
    {
        margin-bottom: -14.5rem !important;
    }
    .ml-lg-n12,
    .mx-lg-n12
    {
        margin-left: -14.5rem !important;
    }
    .m-lg-n13
    {
        margin: -15rem !important;
    }
    .mt-lg-n13,
    .my-lg-n13
    {
        margin-top: -15rem !important;
    }
    .mr-lg-n13,
    .mx-lg-n13
    {
        margin-right: -15rem !important;
    }
    .mb-lg-n13,
    .my-lg-n13
    {
        margin-bottom: -15rem !important;
    }
    .ml-lg-n13,
    .mx-lg-n13
    {
        margin-left: -15rem !important;
    }
    .m-lg-n14
    {
        margin: -16rem !important;
    }
    .mt-lg-n14,
    .my-lg-n14
    {
        margin-top: -16rem !important;
    }
    .mr-lg-n14,
    .mx-lg-n14
    {
        margin-right: -16rem !important;
    }
    .mb-lg-n14,
    .my-lg-n14
    {
        margin-bottom: -16rem !important;
    }
    .ml-lg-n14,
    .mx-lg-n14
    {
        margin-left: -16rem !important;
    }
    .m-lg-n15
    {
        margin: -17rem !important;
    }
    .mt-lg-n15,
    .my-lg-n15
    {
        margin-top: -17rem !important;
    }
    .mr-lg-n15,
    .mx-lg-n15
    {
        margin-right: -17rem !important;
    }
    .mb-lg-n15,
    .my-lg-n15
    {
        margin-bottom: -17rem !important;
    }
    .ml-lg-n15,
    .mx-lg-n15
    {
        margin-left: -17rem !important;
    }
    .m-lg-auto
    {
        margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto
    {
        margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto
    {
        margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto
    {
        margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto
    {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px)
{
    .m-xl-0
    {
        margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0
    {
        margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0
    {
        margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0
    {
        margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0
    {
        margin-left: 0 !important;
    }
    .m-xl-1
    {
        margin: .25rem !important;
    }
    .mt-xl-1,
    .my-xl-1
    {
        margin-top: .25rem !important;
    }
    .mr-xl-1,
    .mx-xl-1
    {
        margin-right: .25rem !important;
    }
    .mb-xl-1,
    .my-xl-1
    {
        margin-bottom: .25rem !important;
    }
    .ml-xl-1,
    .mx-xl-1
    {
        margin-left: .25rem !important;
    }
    .m-xl-2
    {
        margin: .5rem !important;
    }
    .mt-xl-2,
    .my-xl-2
    {
        margin-top: .5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2
    {
        margin-right: .5rem !important;
    }
    .mb-xl-2,
    .my-xl-2
    {
        margin-bottom: .5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2
    {
        margin-left: .5rem !important;
    }
    .m-xl-3
    {
        margin: 1rem !important;
    }
    .mt-xl-3,
    .my-xl-3
    {
        margin-top: 1rem !important;
    }
    .mr-xl-3,
    .mx-xl-3
    {
        margin-right: 1rem !important;
    }
    .mb-xl-3,
    .my-xl-3
    {
        margin-bottom: 1rem !important;
    }
    .ml-xl-3,
    .mx-xl-3
    {
        margin-left: 1rem !important;
    }
    .m-xl-4
    {
        margin: 1.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4
    {
        margin-top: 1.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4
    {
        margin-right: 1.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4
    {
        margin-left: 1.5rem !important;
    }
    .m-xl-5
    {
        margin: 3rem !important;
    }
    .mt-xl-5,
    .my-xl-5
    {
        margin-top: 3rem !important;
    }
    .mr-xl-5,
    .mx-xl-5
    {
        margin-right: 3rem !important;
    }
    .mb-xl-5,
    .my-xl-5
    {
        margin-bottom: 3rem !important;
    }
    .ml-xl-5,
    .mx-xl-5
    {
        margin-left: 3rem !important;
    }
    .m-xl-6
    {
        margin: 4.5rem !important;
    }
    .mt-xl-6,
    .my-xl-6
    {
        margin-top: 4.5rem !important;
    }
    .mr-xl-6,
    .mx-xl-6
    {
        margin-right: 4.5rem !important;
    }
    .mb-xl-6,
    .my-xl-6
    {
        margin-bottom: 4.5rem !important;
    }
    .ml-xl-6,
    .mx-xl-6
    {
        margin-left: 4.5rem !important;
    }
    .m-xl-7
    {
        margin: 6rem !important;
    }
    .mt-xl-7,
    .my-xl-7
    {
        margin-top: 6rem !important;
    }
    .mr-xl-7,
    .mx-xl-7
    {
        margin-right: 6rem !important;
    }
    .mb-xl-7,
    .my-xl-7
    {
        margin-bottom: 6rem !important;
    }
    .ml-xl-7,
    .mx-xl-7
    {
        margin-left: 6rem !important;
    }
    .m-xl-8
    {
        margin: 7.5rem !important;
    }
    .mt-xl-8,
    .my-xl-8
    {
        margin-top: 7.5rem !important;
    }
    .mr-xl-8,
    .mx-xl-8
    {
        margin-right: 7.5rem !important;
    }
    .mb-xl-8,
    .my-xl-8
    {
        margin-bottom: 7.5rem !important;
    }
    .ml-xl-8,
    .mx-xl-8
    {
        margin-left: 7.5rem !important;
    }
    .m-xl-9
    {
        margin: 9rem !important;
    }
    .mt-xl-9,
    .my-xl-9
    {
        margin-top: 9rem !important;
    }
    .mr-xl-9,
    .mx-xl-9
    {
        margin-right: 9rem !important;
    }
    .mb-xl-9,
    .my-xl-9
    {
        margin-bottom: 9rem !important;
    }
    .ml-xl-9,
    .mx-xl-9
    {
        margin-left: 9rem !important;
    }
    .m-xl-10
    {
        margin: 10rem !important;
    }
    .mt-xl-10,
    .my-xl-10
    {
        margin-top: 10rem !important;
    }
    .mr-xl-10,
    .mx-xl-10
    {
        margin-right: 10rem !important;
    }
    .mb-xl-10,
    .my-xl-10
    {
        margin-bottom: 10rem !important;
    }
    .ml-xl-10,
    .mx-xl-10
    {
        margin-left: 10rem !important;
    }
    .m-xl-11
    {
        margin: 12rem !important;
    }
    .mt-xl-11,
    .my-xl-11
    {
        margin-top: 12rem !important;
    }
    .mr-xl-11,
    .mx-xl-11
    {
        margin-right: 12rem !important;
    }
    .mb-xl-11,
    .my-xl-11
    {
        margin-bottom: 12rem !important;
    }
    .ml-xl-11,
    .mx-xl-11
    {
        margin-left: 12rem !important;
    }
    .m-xl-12
    {
        margin: 14.5rem !important;
    }
    .mt-xl-12,
    .my-xl-12
    {
        margin-top: 14.5rem !important;
    }
    .mr-xl-12,
    .mx-xl-12
    {
        margin-right: 14.5rem !important;
    }
    .mb-xl-12,
    .my-xl-12
    {
        margin-bottom: 14.5rem !important;
    }
    .ml-xl-12,
    .mx-xl-12
    {
        margin-left: 14.5rem !important;
    }
    .m-xl-13
    {
        margin: 15rem !important;
    }
    .mt-xl-13,
    .my-xl-13
    {
        margin-top: 15rem !important;
    }
    .mr-xl-13,
    .mx-xl-13
    {
        margin-right: 15rem !important;
    }
    .mb-xl-13,
    .my-xl-13
    {
        margin-bottom: 15rem !important;
    }
    .ml-xl-13,
    .mx-xl-13
    {
        margin-left: 15rem !important;
    }
    .m-xl-14
    {
        margin: 16rem !important;
    }
    .mt-xl-14,
    .my-xl-14
    {
        margin-top: 16rem !important;
    }
    .mr-xl-14,
    .mx-xl-14
    {
        margin-right: 16rem !important;
    }
    .mb-xl-14,
    .my-xl-14
    {
        margin-bottom: 16rem !important;
    }
    .ml-xl-14,
    .mx-xl-14
    {
        margin-left: 16rem !important;
    }
    .m-xl-15
    {
        margin: 17rem !important;
    }
    .mt-xl-15,
    .my-xl-15
    {
        margin-top: 17rem !important;
    }
    .mr-xl-15,
    .mx-xl-15
    {
        margin-right: 17rem !important;
    }
    .mb-xl-15,
    .my-xl-15
    {
        margin-bottom: 17rem !important;
    }
    .ml-xl-15,
    .mx-xl-15
    {
        margin-left: 17rem !important;
    }
    .p-xl-0
    {
        padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0
    {
        padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0
    {
        padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0
    {
        padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0
    {
        padding-left: 0 !important;
    }
    .p-xl-1
    {
        padding: .25rem !important;
    }
    .pt-xl-1,
    .py-xl-1
    {
        padding-top: .25rem !important;
    }
    .pr-xl-1,
    .px-xl-1
    {
        padding-right: .25rem !important;
    }
    .pb-xl-1,
    .py-xl-1
    {
        padding-bottom: .25rem !important;
    }
    .pl-xl-1,
    .px-xl-1
    {
        padding-left: .25rem !important;
    }
    .p-xl-2
    {
        padding: .5rem !important;
    }
    .pt-xl-2,
    .py-xl-2
    {
        padding-top: .5rem !important;
    }
    .pr-xl-2,
    .px-xl-2
    {
        padding-right: .5rem !important;
    }
    .pb-xl-2,
    .py-xl-2
    {
        padding-bottom: .5rem !important;
    }
    .pl-xl-2,
    .px-xl-2
    {
        padding-left: .5rem !important;
    }
    .p-xl-3
    {
        padding: 1rem !important;
    }
    .pt-xl-3,
    .py-xl-3
    {
        padding-top: 1rem !important;
    }
    .pr-xl-3,
    .px-xl-3
    {
        padding-right: 1rem !important;
    }
    .pb-xl-3,
    .py-xl-3
    {
        padding-bottom: 1rem !important;
    }
    .pl-xl-3,
    .px-xl-3
    {
        padding-left: 1rem !important;
    }
    .p-xl-4
    {
        padding: 1.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4
    {
        padding-top: 1.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4
    {
        padding-right: 1.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4
    {
        padding-left: 1.5rem !important;
    }
    .p-xl-5
    {
        padding: 3rem !important;
    }
    .pt-xl-5,
    .py-xl-5
    {
        padding-top: 3rem !important;
    }
    .pr-xl-5,
    .px-xl-5
    {
        padding-right: 3rem !important;
    }
    .pb-xl-5,
    .py-xl-5
    {
        padding-bottom: 3rem !important;
    }
    .pl-xl-5,
    .px-xl-5
    {
        padding-left: 3rem !important;
    }
    .p-xl-6
    {
        padding: 4.5rem !important;
    }
    .pt-xl-6,
    .py-xl-6
    {
        padding-top: 4.5rem !important;
    }
    .pr-xl-6,
    .px-xl-6
    {
        padding-right: 4.5rem !important;
    }
    .pb-xl-6,
    .py-xl-6
    {
        padding-bottom: 4.5rem !important;
    }
    .pl-xl-6,
    .px-xl-6
    {
        padding-left: 4.5rem !important;
    }
    .p-xl-7
    {
        padding: 6rem !important;
    }
    .pt-xl-7,
    .py-xl-7
    {
        padding-top: 6rem !important;
    }
    .pr-xl-7,
    .px-xl-7
    {
        padding-right: 6rem !important;
    }
    .pb-xl-7,
    .py-xl-7
    {
        padding-bottom: 6rem !important;
    }
    .pl-xl-7,
    .px-xl-7
    {
        padding-left: 6rem !important;
    }
    .p-xl-8
    {
        padding: 7.5rem !important;
    }
    .pt-xl-8,
    .py-xl-8
    {
        padding-top: 7.5rem !important;
    }
    .pr-xl-8,
    .px-xl-8
    {
        padding-right: 7.5rem !important;
    }
    .pb-xl-8,
    .py-xl-8
    {
        padding-bottom: 7.5rem !important;
    }
    .pl-xl-8,
    .px-xl-8
    {
        padding-left: 7.5rem !important;
    }
    .p-xl-9
    {
        padding: 9rem !important;
    }
    .pt-xl-9,
    .py-xl-9
    {
        padding-top: 9rem !important;
    }
    .pr-xl-9,
    .px-xl-9
    {
        padding-right: 9rem !important;
    }
    .pb-xl-9,
    .py-xl-9
    {
        padding-bottom: 9rem !important;
    }
    .pl-xl-9,
    .px-xl-9
    {
        padding-left: 9rem !important;
    }
    .p-xl-10
    {
        padding: 10rem !important;
    }
    .pt-xl-10,
    .py-xl-10
    {
        padding-top: 10rem !important;
    }
    .pr-xl-10,
    .px-xl-10
    {
        padding-right: 10rem !important;
    }
    .pb-xl-10,
    .py-xl-10
    {
        padding-bottom: 10rem !important;
    }
    .pl-xl-10,
    .px-xl-10
    {
        padding-left: 10rem !important;
    }
    .p-xl-11
    {
        padding: 12rem !important;
    }
    .pt-xl-11,
    .py-xl-11
    {
        padding-top: 12rem !important;
    }
    .pr-xl-11,
    .px-xl-11
    {
        padding-right: 12rem !important;
    }
    .pb-xl-11,
    .py-xl-11
    {
        padding-bottom: 12rem !important;
    }
    .pl-xl-11,
    .px-xl-11
    {
        padding-left: 12rem !important;
    }
    .p-xl-12
    {
        padding: 14.5rem !important;
    }
    .pt-xl-12,
    .py-xl-12
    {
        padding-top: 14.5rem !important;
    }
    .pr-xl-12,
    .px-xl-12
    {
        padding-right: 14.5rem !important;
    }
    .pb-xl-12,
    .py-xl-12
    {
        padding-bottom: 14.5rem !important;
    }
    .pl-xl-12,
    .px-xl-12
    {
        padding-left: 14.5rem !important;
    }
    .p-xl-13
    {
        padding: 15rem !important;
    }
    .pt-xl-13,
    .py-xl-13
    {
        padding-top: 15rem !important;
    }
    .pr-xl-13,
    .px-xl-13
    {
        padding-right: 15rem !important;
    }
    .pb-xl-13,
    .py-xl-13
    {
        padding-bottom: 15rem !important;
    }
    .pl-xl-13,
    .px-xl-13
    {
        padding-left: 15rem !important;
    }
    .p-xl-14
    {
        padding: 16rem !important;
    }
    .pt-xl-14,
    .py-xl-14
    {
        padding-top: 16rem !important;
    }
    .pr-xl-14,
    .px-xl-14
    {
        padding-right: 16rem !important;
    }
    .pb-xl-14,
    .py-xl-14
    {
        padding-bottom: 16rem !important;
    }
    .pl-xl-14,
    .px-xl-14
    {
        padding-left: 16rem !important;
    }
    .p-xl-15
    {
        padding: 17rem !important;
    }
    .pt-xl-15,
    .py-xl-15
    {
        padding-top: 17rem !important;
    }
    .pr-xl-15,
    .px-xl-15
    {
        padding-right: 17rem !important;
    }
    .pb-xl-15,
    .py-xl-15
    {
        padding-bottom: 17rem !important;
    }
    .pl-xl-15,
    .px-xl-15
    {
        padding-left: 17rem !important;
    }
    .m-xl-n1
    {
        margin: -.25rem !important;
    }
    .mt-xl-n1,
    .my-xl-n1
    {
        margin-top: -.25rem !important;
    }
    .mr-xl-n1,
    .mx-xl-n1
    {
        margin-right: -.25rem !important;
    }
    .mb-xl-n1,
    .my-xl-n1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-xl-n1,
    .mx-xl-n1
    {
        margin-left: -.25rem !important;
    }
    .m-xl-n2
    {
        margin: -.5rem !important;
    }
    .mt-xl-n2,
    .my-xl-n2
    {
        margin-top: -.5rem !important;
    }
    .mr-xl-n2,
    .mx-xl-n2
    {
        margin-right: -.5rem !important;
    }
    .mb-xl-n2,
    .my-xl-n2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-xl-n2,
    .mx-xl-n2
    {
        margin-left: -.5rem !important;
    }
    .m-xl-n3
    {
        margin: -1rem !important;
    }
    .mt-xl-n3,
    .my-xl-n3
    {
        margin-top: -1rem !important;
    }
    .mr-xl-n3,
    .mx-xl-n3
    {
        margin-right: -1rem !important;
    }
    .mb-xl-n3,
    .my-xl-n3
    {
        margin-bottom: -1rem !important;
    }
    .ml-xl-n3,
    .mx-xl-n3
    {
        margin-left: -1rem !important;
    }
    .m-xl-n4
    {
        margin: -1.5rem !important;
    }
    .mt-xl-n4,
    .my-xl-n4
    {
        margin-top: -1.5rem !important;
    }
    .mr-xl-n4,
    .mx-xl-n4
    {
        margin-right: -1.5rem !important;
    }
    .mb-xl-n4,
    .my-xl-n4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-xl-n4,
    .mx-xl-n4
    {
        margin-left: -1.5rem !important;
    }
    .m-xl-n5
    {
        margin: -3rem !important;
    }
    .mt-xl-n5,
    .my-xl-n5
    {
        margin-top: -3rem !important;
    }
    .mr-xl-n5,
    .mx-xl-n5
    {
        margin-right: -3rem !important;
    }
    .mb-xl-n5,
    .my-xl-n5
    {
        margin-bottom: -3rem !important;
    }
    .ml-xl-n5,
    .mx-xl-n5
    {
        margin-left: -3rem !important;
    }
    .m-xl-n6
    {
        margin: -4.5rem !important;
    }
    .mt-xl-n6,
    .my-xl-n6
    {
        margin-top: -4.5rem !important;
    }
    .mr-xl-n6,
    .mx-xl-n6
    {
        margin-right: -4.5rem !important;
    }
    .mb-xl-n6,
    .my-xl-n6
    {
        margin-bottom: -4.5rem !important;
    }
    .ml-xl-n6,
    .mx-xl-n6
    {
        margin-left: -4.5rem !important;
    }
    .m-xl-n7
    {
        margin: -6rem !important;
    }
    .mt-xl-n7,
    .my-xl-n7
    {
        margin-top: -6rem !important;
    }
    .mr-xl-n7,
    .mx-xl-n7
    {
        margin-right: -6rem !important;
    }
    .mb-xl-n7,
    .my-xl-n7
    {
        margin-bottom: -6rem !important;
    }
    .ml-xl-n7,
    .mx-xl-n7
    {
        margin-left: -6rem !important;
    }
    .m-xl-n8
    {
        margin: -7.5rem !important;
    }
    .mt-xl-n8,
    .my-xl-n8
    {
        margin-top: -7.5rem !important;
    }
    .mr-xl-n8,
    .mx-xl-n8
    {
        margin-right: -7.5rem !important;
    }
    .mb-xl-n8,
    .my-xl-n8
    {
        margin-bottom: -7.5rem !important;
    }
    .ml-xl-n8,
    .mx-xl-n8
    {
        margin-left: -7.5rem !important;
    }
    .m-xl-n9
    {
        margin: -9rem !important;
    }
    .mt-xl-n9,
    .my-xl-n9
    {
        margin-top: -9rem !important;
    }
    .mr-xl-n9,
    .mx-xl-n9
    {
        margin-right: -9rem !important;
    }
    .mb-xl-n9,
    .my-xl-n9
    {
        margin-bottom: -9rem !important;
    }
    .ml-xl-n9,
    .mx-xl-n9
    {
        margin-left: -9rem !important;
    }
    .m-xl-n10
    {
        margin: -10rem !important;
    }
    .mt-xl-n10,
    .my-xl-n10
    {
        margin-top: -10rem !important;
    }
    .mr-xl-n10,
    .mx-xl-n10
    {
        margin-right: -10rem !important;
    }
    .mb-xl-n10,
    .my-xl-n10
    {
        margin-bottom: -10rem !important;
    }
    .ml-xl-n10,
    .mx-xl-n10
    {
        margin-left: -10rem !important;
    }
    .m-xl-n11
    {
        margin: -12rem !important;
    }
    .mt-xl-n11,
    .my-xl-n11
    {
        margin-top: -12rem !important;
    }
    .mr-xl-n11,
    .mx-xl-n11
    {
        margin-right: -12rem !important;
    }
    .mb-xl-n11,
    .my-xl-n11
    {
        margin-bottom: -12rem !important;
    }
    .ml-xl-n11,
    .mx-xl-n11
    {
        margin-left: -12rem !important;
    }
    .m-xl-n12
    {
        margin: -14.5rem !important;
    }
    .mt-xl-n12,
    .my-xl-n12
    {
        margin-top: -14.5rem !important;
    }
    .mr-xl-n12,
    .mx-xl-n12
    {
        margin-right: -14.5rem !important;
    }
    .mb-xl-n12,
    .my-xl-n12
    {
        margin-bottom: -14.5rem !important;
    }
    .ml-xl-n12,
    .mx-xl-n12
    {
        margin-left: -14.5rem !important;
    }
    .m-xl-n13
    {
        margin: -15rem !important;
    }
    .mt-xl-n13,
    .my-xl-n13
    {
        margin-top: -15rem !important;
    }
    .mr-xl-n13,
    .mx-xl-n13
    {
        margin-right: -15rem !important;
    }
    .mb-xl-n13,
    .my-xl-n13
    {
        margin-bottom: -15rem !important;
    }
    .ml-xl-n13,
    .mx-xl-n13
    {
        margin-left: -15rem !important;
    }
    .m-xl-n14
    {
        margin: -16rem !important;
    }
    .mt-xl-n14,
    .my-xl-n14
    {
        margin-top: -16rem !important;
    }
    .mr-xl-n14,
    .mx-xl-n14
    {
        margin-right: -16rem !important;
    }
    .mb-xl-n14,
    .my-xl-n14
    {
        margin-bottom: -16rem !important;
    }
    .ml-xl-n14,
    .mx-xl-n14
    {
        margin-left: -16rem !important;
    }
    .m-xl-n15
    {
        margin: -17rem !important;
    }
    .mt-xl-n15,
    .my-xl-n15
    {
        margin-top: -17rem !important;
    }
    .mr-xl-n15,
    .mx-xl-n15
    {
        margin-right: -17rem !important;
    }
    .mb-xl-n15,
    .my-xl-n15
    {
        margin-bottom: -17rem !important;
    }
    .ml-xl-n15,
    .mx-xl-n15
    {
        margin-left: -17rem !important;
    }
    .m-xl-auto
    {
        margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto
    {
        margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto
    {
        margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto
    {
        margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto
    {
        margin-left: auto !important;
    }
}

@media (min-width: 1600px)
{
    .m-xxl-0
    {
        margin: 0 !important;
    }
    .mt-xxl-0,
    .my-xxl-0
    {
        margin-top: 0 !important;
    }
    .mr-xxl-0,
    .mx-xxl-0
    {
        margin-right: 0 !important;
    }
    .mb-xxl-0,
    .my-xxl-0
    {
        margin-bottom: 0 !important;
    }
    .ml-xxl-0,
    .mx-xxl-0
    {
        margin-left: 0 !important;
    }
    .m-xxl-1
    {
        margin: .25rem !important;
    }
    .mt-xxl-1,
    .my-xxl-1
    {
        margin-top: .25rem !important;
    }
    .mr-xxl-1,
    .mx-xxl-1
    {
        margin-right: .25rem !important;
    }
    .mb-xxl-1,
    .my-xxl-1
    {
        margin-bottom: .25rem !important;
    }
    .ml-xxl-1,
    .mx-xxl-1
    {
        margin-left: .25rem !important;
    }
    .m-xxl-2
    {
        margin: .5rem !important;
    }
    .mt-xxl-2,
    .my-xxl-2
    {
        margin-top: .5rem !important;
    }
    .mr-xxl-2,
    .mx-xxl-2
    {
        margin-right: .5rem !important;
    }
    .mb-xxl-2,
    .my-xxl-2
    {
        margin-bottom: .5rem !important;
    }
    .ml-xxl-2,
    .mx-xxl-2
    {
        margin-left: .5rem !important;
    }
    .m-xxl-3
    {
        margin: 1rem !important;
    }
    .mt-xxl-3,
    .my-xxl-3
    {
        margin-top: 1rem !important;
    }
    .mr-xxl-3,
    .mx-xxl-3
    {
        margin-right: 1rem !important;
    }
    .mb-xxl-3,
    .my-xxl-3
    {
        margin-bottom: 1rem !important;
    }
    .ml-xxl-3,
    .mx-xxl-3
    {
        margin-left: 1rem !important;
    }
    .m-xxl-4
    {
        margin: 1.5rem !important;
    }
    .mt-xxl-4,
    .my-xxl-4
    {
        margin-top: 1.5rem !important;
    }
    .mr-xxl-4,
    .mx-xxl-4
    {
        margin-right: 1.5rem !important;
    }
    .mb-xxl-4,
    .my-xxl-4
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-xxl-4,
    .mx-xxl-4
    {
        margin-left: 1.5rem !important;
    }
    .m-xxl-5
    {
        margin: 3rem !important;
    }
    .mt-xxl-5,
    .my-xxl-5
    {
        margin-top: 3rem !important;
    }
    .mr-xxl-5,
    .mx-xxl-5
    {
        margin-right: 3rem !important;
    }
    .mb-xxl-5,
    .my-xxl-5
    {
        margin-bottom: 3rem !important;
    }
    .ml-xxl-5,
    .mx-xxl-5
    {
        margin-left: 3rem !important;
    }
    .m-xxl-6
    {
        margin: 4.5rem !important;
    }
    .mt-xxl-6,
    .my-xxl-6
    {
        margin-top: 4.5rem !important;
    }
    .mr-xxl-6,
    .mx-xxl-6
    {
        margin-right: 4.5rem !important;
    }
    .mb-xxl-6,
    .my-xxl-6
    {
        margin-bottom: 4.5rem !important;
    }
    .ml-xxl-6,
    .mx-xxl-6
    {
        margin-left: 4.5rem !important;
    }
    .m-xxl-7
    {
        margin: 6rem !important;
    }
    .mt-xxl-7,
    .my-xxl-7
    {
        margin-top: 6rem !important;
    }
    .mr-xxl-7,
    .mx-xxl-7
    {
        margin-right: 6rem !important;
    }
    .mb-xxl-7,
    .my-xxl-7
    {
        margin-bottom: 6rem !important;
    }
    .ml-xxl-7,
    .mx-xxl-7
    {
        margin-left: 6rem !important;
    }
    .m-xxl-8
    {
        margin: 7.5rem !important;
    }
    .mt-xxl-8,
    .my-xxl-8
    {
        margin-top: 7.5rem !important;
    }
    .mr-xxl-8,
    .mx-xxl-8
    {
        margin-right: 7.5rem !important;
    }
    .mb-xxl-8,
    .my-xxl-8
    {
        margin-bottom: 7.5rem !important;
    }
    .ml-xxl-8,
    .mx-xxl-8
    {
        margin-left: 7.5rem !important;
    }
    .m-xxl-9
    {
        margin: 9rem !important;
    }
    .mt-xxl-9,
    .my-xxl-9
    {
        margin-top: 9rem !important;
    }
    .mr-xxl-9,
    .mx-xxl-9
    {
        margin-right: 9rem !important;
    }
    .mb-xxl-9,
    .my-xxl-9
    {
        margin-bottom: 9rem !important;
    }
    .ml-xxl-9,
    .mx-xxl-9
    {
        margin-left: 9rem !important;
    }
    .m-xxl-10
    {
        margin: 10rem !important;
    }
    .mt-xxl-10,
    .my-xxl-10
    {
        margin-top: 10rem !important;
    }
    .mr-xxl-10,
    .mx-xxl-10
    {
        margin-right: 10rem !important;
    }
    .mb-xxl-10,
    .my-xxl-10
    {
        margin-bottom: 10rem !important;
    }
    .ml-xxl-10,
    .mx-xxl-10
    {
        margin-left: 10rem !important;
    }
    .m-xxl-11
    {
        margin: 12rem !important;
    }
    .mt-xxl-11,
    .my-xxl-11
    {
        margin-top: 12rem !important;
    }
    .mr-xxl-11,
    .mx-xxl-11
    {
        margin-right: 12rem !important;
    }
    .mb-xxl-11,
    .my-xxl-11
    {
        margin-bottom: 12rem !important;
    }
    .ml-xxl-11,
    .mx-xxl-11
    {
        margin-left: 12rem !important;
    }
    .m-xxl-12
    {
        margin: 14.5rem !important;
    }
    .mt-xxl-12,
    .my-xxl-12
    {
        margin-top: 14.5rem !important;
    }
    .mr-xxl-12,
    .mx-xxl-12
    {
        margin-right: 14.5rem !important;
    }
    .mb-xxl-12,
    .my-xxl-12
    {
        margin-bottom: 14.5rem !important;
    }
    .ml-xxl-12,
    .mx-xxl-12
    {
        margin-left: 14.5rem !important;
    }
    .m-xxl-13
    {
        margin: 15rem !important;
    }
    .mt-xxl-13,
    .my-xxl-13
    {
        margin-top: 15rem !important;
    }
    .mr-xxl-13,
    .mx-xxl-13
    {
        margin-right: 15rem !important;
    }
    .mb-xxl-13,
    .my-xxl-13
    {
        margin-bottom: 15rem !important;
    }
    .ml-xxl-13,
    .mx-xxl-13
    {
        margin-left: 15rem !important;
    }
    .m-xxl-14
    {
        margin: 16rem !important;
    }
    .mt-xxl-14,
    .my-xxl-14
    {
        margin-top: 16rem !important;
    }
    .mr-xxl-14,
    .mx-xxl-14
    {
        margin-right: 16rem !important;
    }
    .mb-xxl-14,
    .my-xxl-14
    {
        margin-bottom: 16rem !important;
    }
    .ml-xxl-14,
    .mx-xxl-14
    {
        margin-left: 16rem !important;
    }
    .m-xxl-15
    {
        margin: 17rem !important;
    }
    .mt-xxl-15,
    .my-xxl-15
    {
        margin-top: 17rem !important;
    }
    .mr-xxl-15,
    .mx-xxl-15
    {
        margin-right: 17rem !important;
    }
    .mb-xxl-15,
    .my-xxl-15
    {
        margin-bottom: 17rem !important;
    }
    .ml-xxl-15,
    .mx-xxl-15
    {
        margin-left: 17rem !important;
    }
    .p-xxl-0
    {
        padding: 0 !important;
    }
    .pt-xxl-0,
    .py-xxl-0
    {
        padding-top: 0 !important;
    }
    .pr-xxl-0,
    .px-xxl-0
    {
        padding-right: 0 !important;
    }
    .pb-xxl-0,
    .py-xxl-0
    {
        padding-bottom: 0 !important;
    }
    .pl-xxl-0,
    .px-xxl-0
    {
        padding-left: 0 !important;
    }
    .p-xxl-1
    {
        padding: .25rem !important;
    }
    .pt-xxl-1,
    .py-xxl-1
    {
        padding-top: .25rem !important;
    }
    .pr-xxl-1,
    .px-xxl-1
    {
        padding-right: .25rem !important;
    }
    .pb-xxl-1,
    .py-xxl-1
    {
        padding-bottom: .25rem !important;
    }
    .pl-xxl-1,
    .px-xxl-1
    {
        padding-left: .25rem !important;
    }
    .p-xxl-2
    {
        padding: .5rem !important;
    }
    .pt-xxl-2,
    .py-xxl-2
    {
        padding-top: .5rem !important;
    }
    .pr-xxl-2,
    .px-xxl-2
    {
        padding-right: .5rem !important;
    }
    .pb-xxl-2,
    .py-xxl-2
    {
        padding-bottom: .5rem !important;
    }
    .pl-xxl-2,
    .px-xxl-2
    {
        padding-left: .5rem !important;
    }
    .p-xxl-3
    {
        padding: 1rem !important;
    }
    .pt-xxl-3,
    .py-xxl-3
    {
        padding-top: 1rem !important;
    }
    .pr-xxl-3,
    .px-xxl-3
    {
        padding-right: 1rem !important;
    }
    .pb-xxl-3,
    .py-xxl-3
    {
        padding-bottom: 1rem !important;
    }
    .pl-xxl-3,
    .px-xxl-3
    {
        padding-left: 1rem !important;
    }
    .p-xxl-4
    {
        padding: 1.5rem !important;
    }
    .pt-xxl-4,
    .py-xxl-4
    {
        padding-top: 1.5rem !important;
    }
    .pr-xxl-4,
    .px-xxl-4
    {
        padding-right: 1.5rem !important;
    }
    .pb-xxl-4,
    .py-xxl-4
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-xxl-4,
    .px-xxl-4
    {
        padding-left: 1.5rem !important;
    }
    .p-xxl-5
    {
        padding: 3rem !important;
    }
    .pt-xxl-5,
    .py-xxl-5
    {
        padding-top: 3rem !important;
    }
    .pr-xxl-5,
    .px-xxl-5
    {
        padding-right: 3rem !important;
    }
    .pb-xxl-5,
    .py-xxl-5
    {
        padding-bottom: 3rem !important;
    }
    .pl-xxl-5,
    .px-xxl-5
    {
        padding-left: 3rem !important;
    }
    .p-xxl-6
    {
        padding: 4.5rem !important;
    }
    .pt-xxl-6,
    .py-xxl-6
    {
        padding-top: 4.5rem !important;
    }
    .pr-xxl-6,
    .px-xxl-6
    {
        padding-right: 4.5rem !important;
    }
    .pb-xxl-6,
    .py-xxl-6
    {
        padding-bottom: 4.5rem !important;
    }
    .pl-xxl-6,
    .px-xxl-6
    {
        padding-left: 4.5rem !important;
    }
    .p-xxl-7
    {
        padding: 6rem !important;
    }
    .pt-xxl-7,
    .py-xxl-7
    {
        padding-top: 6rem !important;
    }
    .pr-xxl-7,
    .px-xxl-7
    {
        padding-right: 6rem !important;
    }
    .pb-xxl-7,
    .py-xxl-7
    {
        padding-bottom: 6rem !important;
    }
    .pl-xxl-7,
    .px-xxl-7
    {
        padding-left: 6rem !important;
    }
    .p-xxl-8
    {
        padding: 7.5rem !important;
    }
    .pt-xxl-8,
    .py-xxl-8
    {
        padding-top: 7.5rem !important;
    }
    .pr-xxl-8,
    .px-xxl-8
    {
        padding-right: 7.5rem !important;
    }
    .pb-xxl-8,
    .py-xxl-8
    {
        padding-bottom: 7.5rem !important;
    }
    .pl-xxl-8,
    .px-xxl-8
    {
        padding-left: 7.5rem !important;
    }
    .p-xxl-9
    {
        padding: 9rem !important;
    }
    .pt-xxl-9,
    .py-xxl-9
    {
        padding-top: 9rem !important;
    }
    .pr-xxl-9,
    .px-xxl-9
    {
        padding-right: 9rem !important;
    }
    .pb-xxl-9,
    .py-xxl-9
    {
        padding-bottom: 9rem !important;
    }
    .pl-xxl-9,
    .px-xxl-9
    {
        padding-left: 9rem !important;
    }
    .p-xxl-10
    {
        padding: 10rem !important;
    }
    .pt-xxl-10,
    .py-xxl-10
    {
        padding-top: 10rem !important;
    }
    .pr-xxl-10,
    .px-xxl-10
    {
        padding-right: 10rem !important;
    }
    .pb-xxl-10,
    .py-xxl-10
    {
        padding-bottom: 10rem !important;
    }
    .pl-xxl-10,
    .px-xxl-10
    {
        padding-left: 10rem !important;
    }
    .p-xxl-11
    {
        padding: 12rem !important;
    }
    .pt-xxl-11,
    .py-xxl-11
    {
        padding-top: 12rem !important;
    }
    .pr-xxl-11,
    .px-xxl-11
    {
        padding-right: 12rem !important;
    }
    .pb-xxl-11,
    .py-xxl-11
    {
        padding-bottom: 12rem !important;
    }
    .pl-xxl-11,
    .px-xxl-11
    {
        padding-left: 12rem !important;
    }
    .p-xxl-12
    {
        padding: 14.5rem !important;
    }
    .pt-xxl-12,
    .py-xxl-12
    {
        padding-top: 14.5rem !important;
    }
    .pr-xxl-12,
    .px-xxl-12
    {
        padding-right: 14.5rem !important;
    }
    .pb-xxl-12,
    .py-xxl-12
    {
        padding-bottom: 14.5rem !important;
    }
    .pl-xxl-12,
    .px-xxl-12
    {
        padding-left: 14.5rem !important;
    }
    .p-xxl-13
    {
        padding: 15rem !important;
    }
    .pt-xxl-13,
    .py-xxl-13
    {
        padding-top: 15rem !important;
    }
    .pr-xxl-13,
    .px-xxl-13
    {
        padding-right: 15rem !important;
    }
    .pb-xxl-13,
    .py-xxl-13
    {
        padding-bottom: 15rem !important;
    }
    .pl-xxl-13,
    .px-xxl-13
    {
        padding-left: 15rem !important;
    }
    .p-xxl-14
    {
        padding: 16rem !important;
    }
    .pt-xxl-14,
    .py-xxl-14
    {
        padding-top: 16rem !important;
    }
    .pr-xxl-14,
    .px-xxl-14
    {
        padding-right: 16rem !important;
    }
    .pb-xxl-14,
    .py-xxl-14
    {
        padding-bottom: 16rem !important;
    }
    .pl-xxl-14,
    .px-xxl-14
    {
        padding-left: 16rem !important;
    }
    .p-xxl-15
    {
        padding: 17rem !important;
    }
    .pt-xxl-15,
    .py-xxl-15
    {
        padding-top: 17rem !important;
    }
    .pr-xxl-15,
    .px-xxl-15
    {
        padding-right: 17rem !important;
    }
    .pb-xxl-15,
    .py-xxl-15
    {
        padding-bottom: 17rem !important;
    }
    .pl-xxl-15,
    .px-xxl-15
    {
        padding-left: 17rem !important;
    }
    .m-xxl-n1
    {
        margin: -.25rem !important;
    }
    .mt-xxl-n1,
    .my-xxl-n1
    {
        margin-top: -.25rem !important;
    }
    .mr-xxl-n1,
    .mx-xxl-n1
    {
        margin-right: -.25rem !important;
    }
    .mb-xxl-n1,
    .my-xxl-n1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-xxl-n1,
    .mx-xxl-n1
    {
        margin-left: -.25rem !important;
    }
    .m-xxl-n2
    {
        margin: -.5rem !important;
    }
    .mt-xxl-n2,
    .my-xxl-n2
    {
        margin-top: -.5rem !important;
    }
    .mr-xxl-n2,
    .mx-xxl-n2
    {
        margin-right: -.5rem !important;
    }
    .mb-xxl-n2,
    .my-xxl-n2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-xxl-n2,
    .mx-xxl-n2
    {
        margin-left: -.5rem !important;
    }
    .m-xxl-n3
    {
        margin: -1rem !important;
    }
    .mt-xxl-n3,
    .my-xxl-n3
    {
        margin-top: -1rem !important;
    }
    .mr-xxl-n3,
    .mx-xxl-n3
    {
        margin-right: -1rem !important;
    }
    .mb-xxl-n3,
    .my-xxl-n3
    {
        margin-bottom: -1rem !important;
    }
    .ml-xxl-n3,
    .mx-xxl-n3
    {
        margin-left: -1rem !important;
    }
    .m-xxl-n4
    {
        margin: -1.5rem !important;
    }
    .mt-xxl-n4,
    .my-xxl-n4
    {
        margin-top: -1.5rem !important;
    }
    .mr-xxl-n4,
    .mx-xxl-n4
    {
        margin-right: -1.5rem !important;
    }
    .mb-xxl-n4,
    .my-xxl-n4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-xxl-n4,
    .mx-xxl-n4
    {
        margin-left: -1.5rem !important;
    }
    .m-xxl-n5
    {
        margin: -3rem !important;
    }
    .mt-xxl-n5,
    .my-xxl-n5
    {
        margin-top: -3rem !important;
    }
    .mr-xxl-n5,
    .mx-xxl-n5
    {
        margin-right: -3rem !important;
    }
    .mb-xxl-n5,
    .my-xxl-n5
    {
        margin-bottom: -3rem !important;
    }
    .ml-xxl-n5,
    .mx-xxl-n5
    {
        margin-left: -3rem !important;
    }
    .m-xxl-n6
    {
        margin: -4.5rem !important;
    }
    .mt-xxl-n6,
    .my-xxl-n6
    {
        margin-top: -4.5rem !important;
    }
    .mr-xxl-n6,
    .mx-xxl-n6
    {
        margin-right: -4.5rem !important;
    }
    .mb-xxl-n6,
    .my-xxl-n6
    {
        margin-bottom: -4.5rem !important;
    }
    .ml-xxl-n6,
    .mx-xxl-n6
    {
        margin-left: -4.5rem !important;
    }
    .m-xxl-n7
    {
        margin: -6rem !important;
    }
    .mt-xxl-n7,
    .my-xxl-n7
    {
        margin-top: -6rem !important;
    }
    .mr-xxl-n7,
    .mx-xxl-n7
    {
        margin-right: -6rem !important;
    }
    .mb-xxl-n7,
    .my-xxl-n7
    {
        margin-bottom: -6rem !important;
    }
    .ml-xxl-n7,
    .mx-xxl-n7
    {
        margin-left: -6rem !important;
    }
    .m-xxl-n8
    {
        margin: -7.5rem !important;
    }
    .mt-xxl-n8,
    .my-xxl-n8
    {
        margin-top: -7.5rem !important;
    }
    .mr-xxl-n8,
    .mx-xxl-n8
    {
        margin-right: -7.5rem !important;
    }
    .mb-xxl-n8,
    .my-xxl-n8
    {
        margin-bottom: -7.5rem !important;
    }
    .ml-xxl-n8,
    .mx-xxl-n8
    {
        margin-left: -7.5rem !important;
    }
    .m-xxl-n9
    {
        margin: -9rem !important;
    }
    .mt-xxl-n9,
    .my-xxl-n9
    {
        margin-top: -9rem !important;
    }
    .mr-xxl-n9,
    .mx-xxl-n9
    {
        margin-right: -9rem !important;
    }
    .mb-xxl-n9,
    .my-xxl-n9
    {
        margin-bottom: -9rem !important;
    }
    .ml-xxl-n9,
    .mx-xxl-n9
    {
        margin-left: -9rem !important;
    }
    .m-xxl-n10
    {
        margin: -10rem !important;
    }
    .mt-xxl-n10,
    .my-xxl-n10
    {
        margin-top: -10rem !important;
    }
    .mr-xxl-n10,
    .mx-xxl-n10
    {
        margin-right: -10rem !important;
    }
    .mb-xxl-n10,
    .my-xxl-n10
    {
        margin-bottom: -10rem !important;
    }
    .ml-xxl-n10,
    .mx-xxl-n10
    {
        margin-left: -10rem !important;
    }
    .m-xxl-n11
    {
        margin: -12rem !important;
    }
    .mt-xxl-n11,
    .my-xxl-n11
    {
        margin-top: -12rem !important;
    }
    .mr-xxl-n11,
    .mx-xxl-n11
    {
        margin-right: -12rem !important;
    }
    .mb-xxl-n11,
    .my-xxl-n11
    {
        margin-bottom: -12rem !important;
    }
    .ml-xxl-n11,
    .mx-xxl-n11
    {
        margin-left: -12rem !important;
    }
    .m-xxl-n12
    {
        margin: -14.5rem !important;
    }
    .mt-xxl-n12,
    .my-xxl-n12
    {
        margin-top: -14.5rem !important;
    }
    .mr-xxl-n12,
    .mx-xxl-n12
    {
        margin-right: -14.5rem !important;
    }
    .mb-xxl-n12,
    .my-xxl-n12
    {
        margin-bottom: -14.5rem !important;
    }
    .ml-xxl-n12,
    .mx-xxl-n12
    {
        margin-left: -14.5rem !important;
    }
    .m-xxl-n13
    {
        margin: -15rem !important;
    }
    .mt-xxl-n13,
    .my-xxl-n13
    {
        margin-top: -15rem !important;
    }
    .mr-xxl-n13,
    .mx-xxl-n13
    {
        margin-right: -15rem !important;
    }
    .mb-xxl-n13,
    .my-xxl-n13
    {
        margin-bottom: -15rem !important;
    }
    .ml-xxl-n13,
    .mx-xxl-n13
    {
        margin-left: -15rem !important;
    }
    .m-xxl-n14
    {
        margin: -16rem !important;
    }
    .mt-xxl-n14,
    .my-xxl-n14
    {
        margin-top: -16rem !important;
    }
    .mr-xxl-n14,
    .mx-xxl-n14
    {
        margin-right: -16rem !important;
    }
    .mb-xxl-n14,
    .my-xxl-n14
    {
        margin-bottom: -16rem !important;
    }
    .ml-xxl-n14,
    .mx-xxl-n14
    {
        margin-left: -16rem !important;
    }
    .m-xxl-n15
    {
        margin: -17rem !important;
    }
    .mt-xxl-n15,
    .my-xxl-n15
    {
        margin-top: -17rem !important;
    }
    .mr-xxl-n15,
    .mx-xxl-n15
    {
        margin-right: -17rem !important;
    }
    .mb-xxl-n15,
    .my-xxl-n15
    {
        margin-bottom: -17rem !important;
    }
    .ml-xxl-n15,
    .mx-xxl-n15
    {
        margin-left: -17rem !important;
    }
    .m-xxl-auto
    {
        margin: auto !important;
    }
    .mt-xxl-auto,
    .my-xxl-auto
    {
        margin-top: auto !important;
    }
    .mr-xxl-auto,
    .mx-xxl-auto
    {
        margin-right: auto !important;
    }
    .mb-xxl-auto,
    .my-xxl-auto
    {
        margin-bottom: auto !important;
    }
    .ml-xxl-auto,
    .mx-xxl-auto
    {
        margin-left: auto !important;
    }
}

.stretched-link::after
{
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    pointer-events: auto;

    background-color: rgba(0, 0, 0, 0);
}

.text-monospace
{
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
}

.text-justify
{
    text-align: justify !important;
}

.text-wrap
{
    white-space: normal !important;
}

.text-nowrap
{
    white-space: nowrap !important;
}

.text-truncate
{
    overflow: hidden;

    white-space: nowrap; 
    text-overflow: ellipsis;
}

.text-left
{
    text-align: left !important;
}

.text-right
{
    text-align: right !important;
}

.text-center
{
    text-align: center !important;
}

@media (min-width: 576px)
{
    .text-sm-left
    {
        text-align: left !important;
    }
    .text-sm-right
    {
        text-align: right !important;
    }
    .text-sm-center
    {
        text-align: center !important;
    }
}

@media (min-width: 768px)
{
    .text-md-left
    {
        text-align: left !important;
    }
    .text-md-right
    {
        text-align: right !important;
    }
    .text-md-center
    {
        text-align: center !important;
    }
}

@media (min-width: 992px)
{
    .text-lg-left
    {
        text-align: left !important;
    }
    .text-lg-right
    {
        text-align: right !important;
    }
    .text-lg-center
    {
        text-align: center !important;
    }
}

@media (min-width: 1200px)
{
    .text-xl-left
    {
        text-align: left !important;
    }
    .text-xl-right
    {
        text-align: right !important;
    }
    .text-xl-center
    {
        text-align: center !important;
    }
}

@media (min-width: 1600px)
{
    .text-xxl-left
    {
        text-align: left !important;
    }
    .text-xxl-right
    {
        text-align: right !important;
    }
    .text-xxl-center
    {
        text-align: center !important;
    }
}

.text-lowercase
{
    text-transform: lowercase !important;
}

.text-uppercase
{
    text-transform: uppercase !important;
}

.text-capitalize
{
    text-transform: capitalize !important;
}

.font-weight-light
{
    font-weight: 300 !important;
}

.font-weight-lighter
{
    font-weight: lighter !important;
}

.font-weight-normal
{
    font-weight: 400 !important;
}

.font-weight-bold
{
    font-weight: 700 !important;
}

.font-weight-bolder
{
    font-weight: bolder !important;
}

.font-italic
{
    font-style: italic !important;
}

.text-white
{
    color: #fff !important;
}

.text-primary
{
    color: #f12639 !important;
}

a.text-primary:hover,
a.text-primary:focus
{
    color: #be0c1d !important;
}

.text-secondary
{
    color: #1f2749 !important;
}

a.text-secondary:hover,
a.text-secondary:focus
{
    color: #080a13 !important;
}

.text-success
{
    color: #2ecd75 !important;
}

a.text-success:hover,
a.text-success:focus
{
    color: #208f51 !important;
}

.text-info
{
    color: #41c6f2 !important;
}

a.text-info:hover,
a.text-info:focus
{
    color: #0fa6d8 !important;
}

.text-warning
{
    color: #fbcc5d !important;
}

a.text-warning:hover,
a.text-warning:focus
{
    color: #f9b412 !important;
}

.text-danger
{
    color: #f55454 !important;
}

a.text-danger:hover,
a.text-danger:focus
{
    color: #ef0e0e !important;
}

.text-light
{
    color: #f5f5f5 !important;
}

a.text-light:hover,
a.text-light:focus
{
    color: #cfcfcf !important;
}

.text-dark
{
    color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus
{
    color: #121416 !important;
}

.text-blue-light
{
    color: #f3f8fc !important;
}

a.text-blue-light:hover,
a.text-blue-light:focus
{
    color: #b6d4ed !important;
}

.text-body
{
    color: #1a1e2c !important;
}

.text-muted
{
    color: #6c757d !important;
}

.text-black-50
{
    color: rgba(0, 0, 0, .5) !important;
}

.text-white-50
{
    color: rgba(255, 255, 255, .5) !important;
}

.text-hide
{
    font: 0/0 a;

    color: transparent;
    border: 0; 
    background-color: transparent;
    text-shadow: none;
}

.text-decoration-none
{
    text-decoration: none !important;
}

.text-break
{
    word-break: break-word !important;

    overflow-wrap: break-word !important;
}

.text-reset
{
    color: inherit !important;
}

.visible
{
    visibility: visible !important;
}

.invisible
{
    visibility: hidden !important;
}

@media print
{
    *,
    *::before,
    *::after
    {
        box-shadow: none !important; 
        text-shadow: none !important;
    }
    a:not(.btn)
    {
        text-decoration: underline;
    }
    abbr[title]::after
    {
        content: ' (' attr(title) ')';
    }
    pre
    {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote
    {
        border: 1px solid #87888d;

        page-break-inside: avoid;
    }
    thead
    {
        display: table-header-group;
    }
    tr,
    img
    {
        page-break-inside: avoid;
    }
    p,
    h2,
    h3
    {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3
    {
        page-break-after: avoid;
    }
    @page
    {
        size: a3;
    }
    body
    {
        min-width: 992px !important;
    }
    .container
    {
        min-width: 992px !important;
    }
    .navbar
    {
        display: none;
    }
    .badge
    {
        border: 1px solid #000;
    }
    .table
    {
        border-collapse: collapse !important;
    }
    .table td,
    .table th
    {
        background-color: #fff !important;
    }
    .table-bordered th,
    .table-bordered td
    {
        border: 1px solid #d2d4db !important;
    }
    .table-dark
    {
        color: inherit;
    }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody
    {
        border-color: #d2d4db;
    }
    .table .thead-dark th
    {
        color: inherit;
        border-color: #d2d4db;
    }
}

/* ==========================================================================
   Background
   ========================================================================== */
.bg-container
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow: hidden; 

    margin: 0;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    backface-visibility: hidden;
}
.bg-container.overlay::before
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';

    background-color: rgba(31, 39, 73, .5);
}

/* ==========================================================================
   Background text
   ========================================================================== */
[data-background-text],
.bg-text
{
    position: relative;
}
[data-background-text]::after,
.bg-text::after
{
    font-family: 'Oswald', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; 

    content: attr(data-background-text);
}

/* ==========================================================================
   Buttons
   ========================================================================== */
.btn
{
    font-family: 'Oswald', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

    text-transform: uppercase;
}

/* Button sizes
   ========================================================================== */
.btn-lg,
.btn-group-lg > .btn
{
    font-size: 1.125rem;
    line-height: 1.5;

    padding: 1.032rem 1rem;

    border-radius: 0;
}

.btn-sm,
.btn-group-sm > .btn
{
    font-size: 1.125rem;
    line-height: 1.5;

    padding: .25rem .5rem;

    border-radius: 0;
}

/* Social media colors
   ========================================================================== */
.btn-facebook
{
    color: #fff;
    border-color: #3b5998; 
    background-color: #3b5998;
}
.btn-facebook:hover
{
    color: #fff;
    border-color: #2d4373; 
    background-color: #30497c;
}
.btn-facebook:focus,
.btn-facebook.focus
{
    color: #fff;
    border-color: #2d4373;
    background-color: #30497c;
    box-shadow: 0 0 0 .2rem rgba(88, 114, 167, .5);
}
.btn-facebook.disabled,
.btn-facebook:disabled
{
    color: #fff;
    border-color: #3b5998; 
    background-color: #3b5998;
}
.btn-facebook:not(:disabled):not(.disabled):active,
.btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle
{
    color: #fff;
    border-color: #293e6a; 
    background-color: #2d4373;
}
.btn-facebook:not(:disabled):not(.disabled):active:focus,
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(88, 114, 167, .5);
}

.btn-twitter
{
    color: #fff;
    border-color: #1da1f2; 
    background-color: #1da1f2;
}
.btn-twitter:hover
{
    color: #fff;
    border-color: #0c85d0; 
    background-color: #0d8ddc;
}
.btn-twitter:focus,
.btn-twitter.focus
{
    color: #fff;
    border-color: #0c85d0;
    background-color: #0d8ddc;
    box-shadow: 0 0 0 .2rem rgba(63, 175, 244, .5);
}
.btn-twitter.disabled,
.btn-twitter:disabled
{
    color: #fff;
    border-color: #1da1f2; 
    background-color: #1da1f2;
}
.btn-twitter:not(:disabled):not(.disabled):active,
.btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle
{
    color: #fff;
    border-color: #0b7ec4; 
    background-color: #0c85d0;
}
.btn-twitter:not(:disabled):not(.disabled):active:focus,
.btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-twitter.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(63, 175, 244, .5);
}

.btn-google-plus
{
    color: #fff;
    border-color: #db4437; 
    background-color: #db4437;
}
.btn-google-plus:hover
{
    color: #fff;
    border-color: #bd2e22; 
    background-color: #c83124;
}
.btn-google-plus:focus,
.btn-google-plus.focus
{
    color: #fff;
    border-color: #bd2e22;
    background-color: #c83124;
    box-shadow: 0 0 0 .2rem rgba(224, 96, 85, .5);
}
.btn-google-plus.disabled,
.btn-google-plus:disabled
{
    color: #fff;
    border-color: #db4437; 
    background-color: #db4437;
}
.btn-google-plus:not(:disabled):not(.disabled):active,
.btn-google-plus:not(:disabled):not(.disabled).active,
.show > .btn-google-plus.dropdown-toggle
{
    color: #fff;
    border-color: #b22c20; 
    background-color: #bd2e22;
}
.btn-google-plus:not(:disabled):not(.disabled):active:focus,
.btn-google-plus:not(:disabled):not(.disabled).active:focus,
.show > .btn-google-plus.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(224, 96, 85, .5);
}

.btn-instagram
{
    color: #fff;
    border-color: #c32aa3; 
    background-color: #c32aa3;
}
.btn-instagram:hover
{
    color: #fff;
    border-color: #992180; 
    background-color: #a42389;
}
.btn-instagram:focus,
.btn-instagram.focus
{
    color: #fff;
    border-color: #992180;
    background-color: #a42389;
    box-shadow: 0 0 0 .2rem rgba(204, 74, 177, .5);
}
.btn-instagram.disabled,
.btn-instagram:disabled
{
    color: #fff;
    border-color: #c32aa3; 
    background-color: #c32aa3;
}
.btn-instagram:not(:disabled):not(.disabled):active,
.btn-instagram:not(:disabled):not(.disabled).active,
.show > .btn-instagram.dropdown-toggle
{
    color: #fff;
    border-color: #8f1f77; 
    background-color: #992180;
}
.btn-instagram:not(:disabled):not(.disabled):active:focus,
.btn-instagram:not(:disabled):not(.disabled).active:focus,
.show > .btn-instagram.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(204, 74, 177, .5);
}

.btn-pinterest
{
    color: #fff;
    border-color: #bd081c; 
    background-color: #bd081c;
}
.btn-pinterest:hover
{
    color: #fff;
    border-color: #8c0615; 
    background-color: #980617;
}
.btn-pinterest:focus,
.btn-pinterest.focus
{
    color: #fff;
    border-color: #8c0615;
    background-color: #980617;
    box-shadow: 0 0 0 .2rem rgba(199, 45, 62, .5);
}
.btn-pinterest.disabled,
.btn-pinterest:disabled
{
    color: #fff;
    border-color: #bd081c; 
    background-color: #bd081c;
}
.btn-pinterest:not(:disabled):not(.disabled):active,
.btn-pinterest:not(:disabled):not(.disabled).active,
.show > .btn-pinterest.dropdown-toggle
{
    color: #fff;
    border-color: #800513; 
    background-color: #8c0615;
}
.btn-pinterest:not(:disabled):not(.disabled):active:focus,
.btn-pinterest:not(:disabled):not(.disabled).active:focus,
.show > .btn-pinterest.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(199, 45, 62, .5);
}

.btn-youtube
{
    color: #fff;
    border-color: #f00; 
    background-color: #f00;
}
.btn-youtube:hover
{
    color: #fff;
    border-color: #c00; 
    background-color: #d90000;
}
.btn-youtube:focus,
.btn-youtube.focus
{
    color: #fff;
    border-color: #c00;
    background-color: #d90000;
    box-shadow: 0 0 0 .2rem rgba(255, 38, 38, .5);
}
.btn-youtube.disabled,
.btn-youtube:disabled
{
    color: #fff;
    border-color: #f00; 
    background-color: #f00;
}
.btn-youtube:not(:disabled):not(.disabled):active,
.btn-youtube:not(:disabled):not(.disabled).active,
.show > .btn-youtube.dropdown-toggle
{
    color: #fff;
    border-color: #bf0000; 
    background-color: #c00;
}
.btn-youtube:not(:disabled):not(.disabled):active:focus,
.btn-youtube:not(:disabled):not(.disabled).active:focus,
.show > .btn-youtube.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(255, 38, 38, .5);
}

.btn-vimeo
{
    color: #fff;
    border-color: #1ab7ea; 
    background-color: #1ab7ea;
}
.btn-vimeo:hover
{
    color: #fff;
    border-color: #1295bf; 
    background-color: #139ecb;
}
.btn-vimeo:focus,
.btn-vimeo.focus
{
    color: #fff;
    border-color: #1295bf;
    background-color: #139ecb;
    box-shadow: 0 0 0 .2rem rgba(60, 194, 237, .5);
}
.btn-vimeo.disabled,
.btn-vimeo:disabled
{
    color: #fff;
    border-color: #1ab7ea; 
    background-color: #1ab7ea;
}
.btn-vimeo:not(:disabled):not(.disabled):active,
.btn-vimeo:not(:disabled):not(.disabled).active,
.show > .btn-vimeo.dropdown-toggle
{
    color: #fff;
    border-color: #108cb4; 
    background-color: #1295bf;
}
.btn-vimeo:not(:disabled):not(.disabled):active:focus,
.btn-vimeo:not(:disabled):not(.disabled).active:focus,
.show > .btn-vimeo.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(60, 194, 237, .5);
}

.btn-slack
{
    color: #fff;
    border-color: #3eb890; 
    background-color: #3eb890;
}
.btn-slack:hover
{
    color: #fff;
    border-color: #319272; 
    background-color: #349b7a;
}
.btn-slack:focus,
.btn-slack.focus
{
    color: #fff;
    border-color: #319272;
    background-color: #349b7a;
    box-shadow: 0 0 0 .2rem rgba(91, 195, 161, .5);
}
.btn-slack.disabled,
.btn-slack:disabled
{
    color: #fff;
    border-color: #3eb890; 
    background-color: #3eb890;
}
.btn-slack:not(:disabled):not(.disabled):active,
.btn-slack:not(:disabled):not(.disabled).active,
.show > .btn-slack.dropdown-toggle
{
    color: #fff;
    border-color: #2e886b; 
    background-color: #319272;
}
.btn-slack:not(:disabled):not(.disabled):active:focus,
.btn-slack:not(:disabled):not(.disabled).active:focus,
.show > .btn-slack.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(91, 195, 161, .5);
}

.btn-dribbble
{
    color: #fff;
    border-color: #ea4c89; 
    background-color: #ea4c89;
}
.btn-dribbble:hover
{
    color: #fff;
    border-color: #e51e6b; 
    background-color: #e62a72;
}
.btn-dribbble:focus,
.btn-dribbble.focus
{
    color: #fff;
    border-color: #e51e6b;
    background-color: #e62a72;
    box-shadow: 0 0 0 .2rem rgba(237, 103, 155, .5);
}
.btn-dribbble.disabled,
.btn-dribbble:disabled
{
    color: #fff;
    border-color: #ea4c89; 
    background-color: #ea4c89;
}
.btn-dribbble:not(:disabled):not(.disabled):active,
.btn-dribbble:not(:disabled):not(.disabled).active,
.show > .btn-dribbble.dropdown-toggle
{
    color: #fff;
    border-color: #dc1a65; 
    background-color: #e51e6b;
}
.btn-dribbble:not(:disabled):not(.disabled):active:focus,
.btn-dribbble:not(:disabled):not(.disabled).active:focus,
.show > .btn-dribbble.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(237, 103, 155, .5);
}

.btn-linkedin
{
    color: #fff;
    border-color: #007bb5; 
    background-color: #007bb5;
}
.btn-linkedin:hover
{
    color: #fff;
    border-color: #005882; 
    background-color: #00618f;
}
.btn-linkedin:focus,
.btn-linkedin.focus
{
    color: #fff;
    border-color: #005882;
    background-color: #00618f;
    box-shadow: 0 0 0 .2rem rgba(38, 143, 192, .5);
}
.btn-linkedin.disabled,
.btn-linkedin:disabled
{
    color: #fff;
    border-color: #007bb5; 
    background-color: #007bb5;
}
.btn-linkedin:not(:disabled):not(.disabled):active,
.btn-linkedin:not(:disabled):not(.disabled).active,
.show > .btn-linkedin.dropdown-toggle
{
    color: #fff;
    border-color: #005075; 
    background-color: #005882;
}
.btn-linkedin:not(:disabled):not(.disabled):active:focus,
.btn-linkedin:not(:disabled):not(.disabled).active:focus,
.show > .btn-linkedin.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(38, 143, 192, .5);
}

.btn-flickr
{
    color: #fff;
    border-color: #f40083; 
    background-color: #f40083;
}
.btn-flickr:hover
{
    color: #fff;
    border-color: #c10068; 
    background-color: #ce006e;
}
.btn-flickr:focus,
.btn-flickr.focus
{
    color: #fff;
    border-color: #c10068;
    background-color: #ce006e;
    box-shadow: 0 0 0 .2rem rgba(246, 38, 150, .5);
}
.btn-flickr.disabled,
.btn-flickr:disabled
{
    color: #fff;
    border-color: #f40083; 
    background-color: #f40083;
}
.btn-flickr:not(:disabled):not(.disabled):active,
.btn-flickr:not(:disabled):not(.disabled).active,
.show > .btn-flickr.dropdown-toggle
{
    color: #fff;
    border-color: #b40061; 
    background-color: #c10068;
}
.btn-flickr:not(:disabled):not(.disabled):active:focus,
.btn-flickr:not(:disabled):not(.disabled).active:focus,
.show > .btn-flickr.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(246, 38, 150, .5);
}

/* ==========================================================================
   Card
   ========================================================================== */
.card-info.active,
.card-info:hover
{
    cursor: pointer; 

    border-color: #f12639;
    background-color: #f12639;
}
.card-info.active *,
.card-info:hover *
{
    color: #fff;
}

/* ==========================================================================
   Faq
   ========================================================================== */
.faq .card
{
    padding-bottom: .5rem;

    border-right: none; 
    border-left: none;
}

.faq .card-header
{
    padding-right: 0;
    padding-left: 0;

    border: none;
    background-color: transparent;
}
.faq .card-header button
{
    font-size: 1.5rem;
    font-weight: medium;

    padding: 0;

    text-align: left;
    text-decoration: none; 
    text-transform: none;

    color: #1f2749;
}
.faq .card-header button::before
{
    font-size: 1.5rem; 

    display: inline !important;

    margin-right: .35em;

    content: '\f273';
    vertical-align: baseline;
}
.faq .card-header button.collapsed::before
{
    content: '\f278';
}

/* ==========================================================================
   Footer
   ========================================================================== */
.footer
{
    position: relative;

    background-color: #1f2749;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.footer::before
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';

    background-color: rgba(31, 39, 73, .97);
}

/* ==========================================================================
   Forms
   ========================================================================== */
.form-control
{
    font-family: 'Oswald', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1rem;
}

.form-control-sm
{
    font-size: .875rem;
}

.form-control-lg
{
    font-size: 1.125rem;
}

/* ==========================================================================
   Icons
   ========================================================================== */
.icon
{
    display: flex;

    width: 110px;
    height: 110px;

    transition: transform .3s ease; 
    text-decoration: none !important;

    border-radius: 100%;
    background: radial-gradient(circle, rgba(255, 255, 255, .1), rgba(255, 255, 255, .35)) !important;
}
.icon i
{
    display: inline-flex;

    margin: auto;

    color: #fff;
}
.icon:hover
{
    transform: scale(1.1);
}
.icon.icon-sm
{
    width: 55px;
    height: 55px;
}
.icon.icon-lg
{
    width: 150px;
    height: 150px;
}

/* ==========================================================================
   Links
   ========================================================================== */
ul li a.active
{
    text-decoration: underline;
}

/* ==========================================================================
   Move element
   ========================================================================== */
.move
{
    position: absolute;
    right: 0;
    bottom: .5rem;
    left: 0;

    text-align: center;
}
.move a
{
    line-height: 0;

    transition: opacity .35s ease; 

    opacity: .3;
    color: #6c757d;
}
.move a:hover
{
    opacity: .7;
}

/* ==========================================================================
   SPYRE Navbar
   ========================================================================== */
/* Navbar
   ========================================================================== */
.spyre-navbar
{
    height: 60px;

    flex-wrap: nowrap;
}
.spyre-navbar .navbar-brand
{
    margin-top: 20px;
}
.spyre-navbar .navbar-text
{
    font-family: 'Oswald', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1rem;
    font-weight: 500;

    position: relative;

    transition: color .5s ease;
}
.spyre-navbar .navbar-text a
{
    transition: color .5s ease;
}

@media (min-width: 992px)
{
    .spyre-navbar
    {
        height: 80px;
    }
}

/* Toggler
   ========================================================================== */
.menu-toggle
{
    position: relative;
    top: -1px;
    left: 3px;

    width: 37px;
    min-width: 37px;
    height: 37px;

    cursor: pointer;
}
.menu-toggle span
{
    transition: .25s ease-in-out; 
    transition-property: top, left, width, height;

    background: #fff;
}
.menu-toggle .hamburger
{
    position: absolute;

    width: 37px; 
    height: 37px;
}
.menu-toggle .hamburger span
{
    position: absolute;
    right: 4px;

    width: 30px;
    height: 3px;
}
.menu-toggle .hamburger span:nth-child(1)
{
    top: 8px;

    transition-delay: .5s;
}
.menu-toggle .hamburger span:nth-child(2)
{
    top: 17px;

    width: 20px;

    transition-delay: .625s;
}
.menu-toggle .hamburger span:nth-child(3)
{
    bottom: 8px;

    width: 24px;

    transition-delay: .75s;
}
.menu-toggle .cross
{
    position: absolute;

    width: 100%;
    height: 100%;

    transform: rotate(45deg);
}
.menu-toggle .cross span
{
    position: absolute;
}
.menu-toggle .cross span:nth-child(1)
{
    top: 0;
    left: 17px;

    width: 3px;
    height: 0;

    transition-delay: 0s;
}
.menu-toggle .cross span:nth-child(2)
{
    top: 17px;
    left: 0;

    width: 0;
    height: 3px;

    transition-delay: .25s;
}
.menu-toggle.open .hamburger span
{
    width: 0;
}
.menu-toggle.open .hamburger span:nth-child(1)
{
    transition-delay: 0s;
}
.menu-toggle.open .hamburger span:nth-child(2)
{
    transition-delay: .1s;
}
.menu-toggle.open .hamburger span:nth-child(3)
{
    transition-delay: .2s;
}
.menu-toggle.open .cross span
{
    background: #f12639;
}
.menu-toggle.open .cross span:nth-child(1)
{
    height: 37px;

    transition-delay: .5s;
}
.menu-toggle.open .cross span:nth-child(2)
{
    width: 37px;

    transition-delay: .3s;
}

/* Search
   ========================================================================== */
.search
{
    position: relative;
}
.search i
{
    font-size: 2.3rem;

    cursor: pointer; 

    color: #fff;
}
.search .form-control
{
    font-size: 30px;
    font-weight: 700;

    position: absolute;
    right: 50px;

    width: 0;
    padding: 0;

    transition: width .5s ease-in-out; 

    color: #fff;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, .3);
    background: none;
}
.search.open
{
    width: 100%;
}
.search.open i
{
    color: #f12639;
}
.search.open .form-control
{
    width: calc(100% - 100px);
}
.search.open .form-control:focus
{
    border-color: rgba(255, 255, 255, .5);
    box-shadow: none;
}

/* Navbar nav
   ========================================================================== */
.spyre-navbar-nav-container
{
    height: 100vh;
}

.spyre-navbar-nav
{
    font-family: 'Oswald', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

    position: relative;
    top: 82px;

    display: flex;
    overflow-x: hidden;
    overflow-y: auto;

    width: 100%;
    height: calc(100vh - 82px);
    padding: 0 15px;

    animation: scale-down-ver-center .5s cubic-bezier(.39, .575, .565, 1) both; 

    align-items: center;
}
.spyre-navbar-nav.open
{
    animation: scale-up-ver-center .5s cubic-bezier(.39, .575, .565, 1) .8s both;
}

.spyre-nav
{
    width: 100%;
    height: auto;
    margin: auto 0;
    padding: 0;

    list-style: none;
    list-style-type: none;
    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);

    text-align: center;
}
.spyre-nav .show .spyre-nav-link.dropdown-toggle::after
{
    content: '\f2fc';
}
.spyre-nav .active .spyre-nav-link,
.spyre-nav .show .spyre-nav-link
{
    color: #f12639;
}

.spyre-nav-item
{
    margin-bottom: .75rem;
}
.spyre-nav-item .dropdown-menu
{
    position: relative;

    float: none;

    margin: .5rem .5rem .625rem;
    padding: 0 1.25rem;

    text-align: inherit; 

    border: none;
    background: none;
}

.spyre-nav-link
{
    font-size: 1.875rem;
    font-weight: 300;
    line-height: 2.25rem;

    position: relative;

    display: block;

    transition: color .3s ease; 
    text-transform: uppercase;

    color: #1f2749;
}
.spyre-nav-link:hover
{
    text-decoration: none;

    color: #f12639;
}
.spyre-nav-link:focus
{
    outline: 0;
}
.spyre-nav-link.dropdown-toggle::after
{
    font-size: 1.875rem; 
    line-height: 0;

    display: inline !important;

    margin-left: .35em;

    content: '\f2f9';
    vertical-align: baseline;

    border: none;
}

.dropdown-menu-item
{
    margin-bottom: .4rem;
}
.dropdown-menu-item.active .dropdown-menu-link,
.dropdown-menu-item.show .dropdown-menu-link
{
    color: #f12639;
}

.dropdown-menu-link
{
    font-family: 'Roboto', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 2.25rem;
    line-height: 1.4rem;

    position: relative;

    display: block;

    transition: color .3s ease; 

    color: #1f2749;
}
.dropdown-menu-link:hover
{
    text-decoration: none;

    color: #f12639;
}

@media (min-width: 768px)
{
    .spyre-navbar-nav
    {
        top: 100px;

        height: calc(100vh - 100px);
    }
    .spyre-nav
    {
        text-align: left;
    }
    .spyre-nav-item
    {
        margin-bottom: .875rem;
    }
    .spyre-nav-item .dropdown-menu::before
    {
        position: absolute;
        top: .3rem;
        bottom: .2rem;
        left: 0;

        width: 1px;

        content: '';

        background-color: #f12639;
    }
    .spyre-nav-link
    {
        font-size: 2.875rem;
        line-height: 3.5rem;
    }
    .spyre-nav-link.dropdown-toggle::after
    {
        font-size: 2.875rem;
    }
}

/* Navbar Ovrelay
   ========================================================================== */
.spyre-navbar-overlay
{
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;

    visibility: hidden;
    overflow: hidden;

    width: 100%;
    height: 100%;

    background: rgba(31, 39, 73, .995);
}
.spyre-navbar-overlay.open
{
    visibility: visible;
}

@media (max-width: 991.98px)
{
    .spyre-navbar-overlay .container
    {
        width: 100%;
        max-width: none;
    }
}

/* Ovrelay Effetcs
   ========================================================================== */
/* Overlay effect: Slide */
.overlay-slide
{
    top: 0;
    right: 0;
    left: auto;

    width: 0;
    height: auto;
    min-height: 100%;

    transition-timing-function: cubic-bezier(.7, 1, .3, 1); 
    transition-duration: .8s;
    transition-property: min-height, width, top, right, visibility;
    transform-origin: 100% 0;
}
.overlay-slide.open
{
    top: 0;
    right: 0; 

    width: 100%;
    min-height: 100%;
}

/* Overlay effect: Fade */
.overlay-fade
{
    transition: opacity .5s, visibility 0s .5s; 

    opacity: 0;
}
.overlay-fade.open
{
    transition: opacity .5s; 

    opacity: 1;
}

/* Overlay effect: Fall down */
.overlay-fall-down
{
    transition: opacity .5s, transform .5s, visibility 0s .5s; 
    transform: translateY(-100%);

    opacity: 0;
}
.overlay-fall-down.open
{
    transition: opacity .5s, transform .5s; 
    transform: translateX(0%);

    opacity: 1;
}

/* Overlay effect: Scale up */
.overlay-scale-up
{
    transition: transform .5s, opacity .4s, visibility 0s .5s; 
    transform: scale(.9);

    opacity: 0;
}
.overlay-scale-up.open
{
    transition: transform .5s, opacity .5s; 
    transform: scale(1);

    opacity: 1;
}

/* Overlay effect: Scale down */
.overlay-scale-down
{
    transition: transform .5s, opacity .5s, visibility 0s .5s; 
    transform: scale(1.1);

    opacity: 0;
}
.overlay-scale-down.open
{
    transition: transform .5s, opacity .5s; 
    transform: scale(1);

    opacity: 1;
}

/* Overlay effect: Corner */
.overlay-corner
{
    top: 0;
    right: 0;
    left: auto;

    width: 0;
    height: 0;
    min-height: 0;

    transition-timing-function: cubic-bezier(.7, 0, .3, 1); 
    transition-duration: .5s;
    transition-property: min-height, width, top, right, opacity, visibility;
    transform-origin: 100% 0;

    opacity: 0;
}
.overlay-corner.open
{
    top: 0;
    right: 0; 

    width: 100%;
    min-height: 100%;

    opacity: 1;
}

/* Keyframes */
@keyframes scale-up-ver-center
{
    0%
    {
        transform: scaleY(0);
    }
    100%
    {
        transform: scaleY(1);
    }
}

@keyframes scale-down-ver-center
{
    0%
    {
        transform: scaleY(1);
    }
    100%
    {
        transform: scaleY(0);
    }
}

/* ==========================================================================
   Preloader
   ========================================================================== */
.preloader
{
    position: fixed;
    z-index: 1080;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: #fff;
}

.spinner
{
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}
.spinner .circles
{
    position: relative;

    width: 50px;
    height: 50px;
    margin: 50px auto;

    animation: dotsRotate 1.5s infinite linear; 
    text-align: center;
}
.spinner .circles::before,
.spinner .circles::after
{
    position: absolute;
    top: 0;

    display: inline-block;

    width: 60%;
    height: 60%;

    content: '';
    animation: dotsBounce 1.5s infinite ease-in-out; 

    border-radius: 100%;
    background-color: #f12639;
}
.spinner .circles::after
{
    top: auto;
    bottom: 0;

    animation-delay: -.75s;
}

/* Keyframes */
@keyframes dotsRotate
{
    100%
    {
        transform: rotate(360deg);
    }
}

@keyframes dotsBounce
{
    0%,
    100%
    {
        transform: scale(0);
    }
    50%
    {
        transform: scale(1);
    }
}

/* ==========================================================================
   Reboot
   ========================================================================== */
html
{
    overflow-x: hidden;

    -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
}

body
{
    overflow-x: hidden;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

/* Code */
pre,
code,
kbd,
samp
{
    font-size: 87.5%;
}

::-moz-selection
{
    color: #fff; 
    background: #f12639;
}

::selection
{
    color: #fff; 
    background: #f12639;
}

/* ==========================================================================
   Root
   ========================================================================== */
:root
{
    --alpha: 0;
}

/* ==========================================================================
   Section
   ========================================================================== */
section
{
    position: relative;

    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
}

@media (min-width: 992px)
{
    section
    {
        padding-top: 9rem;
        padding-bottom: 9rem;
    }
}

/* ==========================================================================
   Shapes
   ========================================================================== */
.square::before,
.rectangle::before
{
    display: block; 

    content: '';
}

.square::before
{
    padding-top: 100%;
}

.rectangle::before
{
    padding-top: 50%;
}

.rectangle.standing::before
{
    padding-top: 200%;
}

/* ==========================================================================
   Type
   ========================================================================== */
/* Title underline
   ========================================================================== */
.title-underline
{
    position: relative;
}
.title-underline::after
{
    position: absolute;
    bottom: -7px;
    left: 0;

    width: 50px;
    height: 1px;

    content: '';

    background-color: #f12639;
}

/* Misc
   ========================================================================== */
/* Blockquotes */
.blockquote
{
    position: relative;

    padding-top: 1.5rem; 
    padding-left: 1.75rem;
}
.blockquote::before
{
    font-family: 'Oswald', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; 
    font-size: 2em;
    line-height: 1em;

    position: absolute;
    top: 0;
    left: 0;

    content: '“';

    color: #1a1e2c;
}

.blockquote-footer
{
    font-weight: 500;
}
.blockquote-footer::before
{
    display: none;
}

/* ==========================================================================
   Utilities
   ========================================================================== */
/* ==========================================================================
   Borders
   ========================================================================== */
/* Centered borders
   ========================================================================== */
.border-top-centered-10,
.border-right-centered-10,
.border-bottom-centered-10,
.border-left-centered-10
{
    position: relative;
}
.border-top-centered-10::before,
.border-top-centered-10::after,
.border-right-centered-10::before,
.border-right-centered-10::after,
.border-bottom-centered-10::before,
.border-bottom-centered-10::after,
.border-left-centered-10::before,
.border-left-centered-10::after
{
    position: absolute;

    content: '';

    background: #d2d4db !important;
}

.border-top-centered-10::before
{
    top: 0;
    left: 45%;

    width: 10%;
    height: 1px !important;
}

.border-top-centered-10.border-top-centered-faded::before
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-right-centered-10::after
{
    top: 45%;
    right: 0;

    width: 1px !important; 
    height: 10%;
}

.border-right-centered-10.border-right-centered-faded::after
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-10::after
{
    bottom: 0;
    left: 45%;

    width: 10%;
    height: 1px !important;
}

.border-bottom-centered-10.border-bottom-centered-faded::after
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-left-centered-10::before
{
    top: 45%;
    left: 0;

    width: 1px !important; 
    height: 10%;
}

.border-left-centered-10.border-left-centered-faded::before
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-20,
.border-right-centered-20,
.border-bottom-centered-20,
.border-left-centered-20
{
    position: relative;
}
.border-top-centered-20::before,
.border-top-centered-20::after,
.border-right-centered-20::before,
.border-right-centered-20::after,
.border-bottom-centered-20::before,
.border-bottom-centered-20::after,
.border-left-centered-20::before,
.border-left-centered-20::after
{
    position: absolute;

    content: '';

    background: #d2d4db !important;
}

.border-top-centered-20::before
{
    top: 0;
    left: 40%;

    width: 20%;
    height: 1px !important;
}

.border-top-centered-20.border-top-centered-faded::before
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-right-centered-20::after
{
    top: 40%;
    right: 0;

    width: 1px !important; 
    height: 20%;
}

.border-right-centered-20.border-right-centered-faded::after
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-20::after
{
    bottom: 0;
    left: 40%;

    width: 20%;
    height: 1px !important;
}

.border-bottom-centered-20.border-bottom-centered-faded::after
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-left-centered-20::before
{
    top: 40%;
    left: 0;

    width: 1px !important; 
    height: 20%;
}

.border-left-centered-20.border-left-centered-faded::before
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-30,
.border-right-centered-30,
.border-bottom-centered-30,
.border-left-centered-30
{
    position: relative;
}
.border-top-centered-30::before,
.border-top-centered-30::after,
.border-right-centered-30::before,
.border-right-centered-30::after,
.border-bottom-centered-30::before,
.border-bottom-centered-30::after,
.border-left-centered-30::before,
.border-left-centered-30::after
{
    position: absolute;

    content: '';

    background: #d2d4db !important;
}

.border-top-centered-30::before
{
    top: 0;
    left: 35%;

    width: 30%;
    height: 1px !important;
}

.border-top-centered-30.border-top-centered-faded::before
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-right-centered-30::after
{
    top: 35%;
    right: 0;

    width: 1px !important; 
    height: 30%;
}

.border-right-centered-30.border-right-centered-faded::after
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-30::after
{
    bottom: 0;
    left: 35%;

    width: 30%;
    height: 1px !important;
}

.border-bottom-centered-30.border-bottom-centered-faded::after
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-left-centered-30::before
{
    top: 35%;
    left: 0;

    width: 1px !important; 
    height: 30%;
}

.border-left-centered-30.border-left-centered-faded::before
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-40,
.border-right-centered-40,
.border-bottom-centered-40,
.border-left-centered-40
{
    position: relative;
}
.border-top-centered-40::before,
.border-top-centered-40::after,
.border-right-centered-40::before,
.border-right-centered-40::after,
.border-bottom-centered-40::before,
.border-bottom-centered-40::after,
.border-left-centered-40::before,
.border-left-centered-40::after
{
    position: absolute;

    content: '';

    background: #d2d4db !important;
}

.border-top-centered-40::before
{
    top: 0;
    left: 30%;

    width: 40%;
    height: 1px !important;
}

.border-top-centered-40.border-top-centered-faded::before
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-right-centered-40::after
{
    top: 30%;
    right: 0;

    width: 1px !important; 
    height: 40%;
}

.border-right-centered-40.border-right-centered-faded::after
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-40::after
{
    bottom: 0;
    left: 30%;

    width: 40%;
    height: 1px !important;
}

.border-bottom-centered-40.border-bottom-centered-faded::after
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-left-centered-40::before
{
    top: 30%;
    left: 0;

    width: 1px !important; 
    height: 40%;
}

.border-left-centered-40.border-left-centered-faded::before
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-50,
.border-right-centered-50,
.border-bottom-centered-50,
.border-left-centered-50
{
    position: relative;
}
.border-top-centered-50::before,
.border-top-centered-50::after,
.border-right-centered-50::before,
.border-right-centered-50::after,
.border-bottom-centered-50::before,
.border-bottom-centered-50::after,
.border-left-centered-50::before,
.border-left-centered-50::after
{
    position: absolute;

    content: '';

    background: #d2d4db !important;
}

.border-top-centered-50::before
{
    top: 0;
    left: 25%;

    width: 50%;
    height: 1px !important;
}

.border-top-centered-50.border-top-centered-faded::before
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-right-centered-50::after
{
    top: 25%;
    right: 0;

    width: 1px !important; 
    height: 50%;
}

.border-right-centered-50.border-right-centered-faded::after
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-50::after
{
    bottom: 0;
    left: 25%;

    width: 50%;
    height: 1px !important;
}

.border-bottom-centered-50.border-bottom-centered-faded::after
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-left-centered-50::before
{
    top: 25%;
    left: 0;

    width: 1px !important; 
    height: 50%;
}

.border-left-centered-50.border-left-centered-faded::before
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-60,
.border-right-centered-60,
.border-bottom-centered-60,
.border-left-centered-60
{
    position: relative;
}
.border-top-centered-60::before,
.border-top-centered-60::after,
.border-right-centered-60::before,
.border-right-centered-60::after,
.border-bottom-centered-60::before,
.border-bottom-centered-60::after,
.border-left-centered-60::before,
.border-left-centered-60::after
{
    position: absolute;

    content: '';

    background: #d2d4db !important;
}

.border-top-centered-60::before
{
    top: 0;
    left: 20%;

    width: 60%;
    height: 1px !important;
}

.border-top-centered-60.border-top-centered-faded::before
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-right-centered-60::after
{
    top: 20%;
    right: 0;

    width: 1px !important; 
    height: 60%;
}

.border-right-centered-60.border-right-centered-faded::after
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-60::after
{
    bottom: 0;
    left: 20%;

    width: 60%;
    height: 1px !important;
}

.border-bottom-centered-60.border-bottom-centered-faded::after
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-left-centered-60::before
{
    top: 20%;
    left: 0;

    width: 1px !important; 
    height: 60%;
}

.border-left-centered-60.border-left-centered-faded::before
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-70,
.border-right-centered-70,
.border-bottom-centered-70,
.border-left-centered-70
{
    position: relative;
}
.border-top-centered-70::before,
.border-top-centered-70::after,
.border-right-centered-70::before,
.border-right-centered-70::after,
.border-bottom-centered-70::before,
.border-bottom-centered-70::after,
.border-left-centered-70::before,
.border-left-centered-70::after
{
    position: absolute;

    content: '';

    background: #d2d4db !important;
}

.border-top-centered-70::before
{
    top: 0;
    left: 15%;

    width: 70%;
    height: 1px !important;
}

.border-top-centered-70.border-top-centered-faded::before
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-right-centered-70::after
{
    top: 15%;
    right: 0;

    width: 1px !important; 
    height: 70%;
}

.border-right-centered-70.border-right-centered-faded::after
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-70::after
{
    bottom: 0;
    left: 15%;

    width: 70%;
    height: 1px !important;
}

.border-bottom-centered-70.border-bottom-centered-faded::after
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-left-centered-70::before
{
    top: 15%;
    left: 0;

    width: 1px !important; 
    height: 70%;
}

.border-left-centered-70.border-left-centered-faded::before
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-80,
.border-right-centered-80,
.border-bottom-centered-80,
.border-left-centered-80
{
    position: relative;
}
.border-top-centered-80::before,
.border-top-centered-80::after,
.border-right-centered-80::before,
.border-right-centered-80::after,
.border-bottom-centered-80::before,
.border-bottom-centered-80::after,
.border-left-centered-80::before,
.border-left-centered-80::after
{
    position: absolute;

    content: '';

    background: #d2d4db !important;
}

.border-top-centered-80::before
{
    top: 0;
    left: 10%;

    width: 80%;
    height: 1px !important;
}

.border-top-centered-80.border-top-centered-faded::before
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-right-centered-80::after
{
    top: 10%;
    right: 0;

    width: 1px !important; 
    height: 80%;
}

.border-right-centered-80.border-right-centered-faded::after
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-80::after
{
    bottom: 0;
    left: 10%;

    width: 80%;
    height: 1px !important;
}

.border-bottom-centered-80.border-bottom-centered-faded::after
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-left-centered-80::before
{
    top: 10%;
    left: 0;

    width: 1px !important; 
    height: 80%;
}

.border-left-centered-80.border-left-centered-faded::before
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-90,
.border-right-centered-90,
.border-bottom-centered-90,
.border-left-centered-90
{
    position: relative;
}
.border-top-centered-90::before,
.border-top-centered-90::after,
.border-right-centered-90::before,
.border-right-centered-90::after,
.border-bottom-centered-90::before,
.border-bottom-centered-90::after,
.border-left-centered-90::before,
.border-left-centered-90::after
{
    position: absolute;

    content: '';

    background: #d2d4db !important;
}

.border-top-centered-90::before
{
    top: 0;
    left: 5%;

    width: 90%;
    height: 1px !important;
}

.border-top-centered-90.border-top-centered-faded::before
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-right-centered-90::after
{
    top: 5%;
    right: 0;

    width: 1px !important; 
    height: 90%;
}

.border-right-centered-90.border-right-centered-faded::after
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-90::after
{
    bottom: 0;
    left: 5%;

    width: 90%;
    height: 1px !important;
}

.border-bottom-centered-90.border-bottom-centered-faded::after
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-left-centered-90::before
{
    top: 5%;
    left: 0;

    width: 1px !important; 
    height: 90%;
}

.border-left-centered-90.border-left-centered-faded::before
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-100,
.border-right-centered-100,
.border-bottom-centered-100,
.border-left-centered-100
{
    position: relative;
}
.border-top-centered-100::before,
.border-top-centered-100::after,
.border-right-centered-100::before,
.border-right-centered-100::after,
.border-bottom-centered-100::before,
.border-bottom-centered-100::after,
.border-left-centered-100::before,
.border-left-centered-100::after
{
    position: absolute;

    content: '';

    background: #d2d4db !important;
}

.border-top-centered-100::before
{
    top: 0;
    left: 0;

    width: 100%;
    height: 1px !important;
}

.border-top-centered-100.border-top-centered-faded::before
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-right-centered-100::after
{
    top: 0;
    right: 0;

    width: 1px !important; 
    height: 100%;
}

.border-right-centered-100.border-right-centered-faded::after
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-100::after
{
    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px !important;
}

.border-bottom-centered-100.border-bottom-centered-faded::after
{
    background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-left-centered-100::before
{
    top: 0;
    left: 0;

    width: 1px !important; 
    height: 100%;
}

.border-left-centered-100.border-left-centered-faded::before
{
    background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

/* ==========================================================================
   Position
   ========================================================================== */
.top--10
{
    top: -10px;
}

.right--10
{
    top: -10px;
}

.bottom--10
{
    top: -10px;
}

.left--10
{
    top: -10px;
}

.top--9
{
    top: -9px;
}

.right--9
{
    top: -9px;
}

.bottom--9
{
    top: -9px;
}

.left--9
{
    top: -9px;
}

.top--8
{
    top: -8px;
}

.right--8
{
    top: -8px;
}

.bottom--8
{
    top: -8px;
}

.left--8
{
    top: -8px;
}

.top--7
{
    top: -7px;
}

.right--7
{
    top: -7px;
}

.bottom--7
{
    top: -7px;
}

.left--7
{
    top: -7px;
}

.top--6
{
    top: -6px;
}

.right--6
{
    top: -6px;
}

.bottom--6
{
    top: -6px;
}

.left--6
{
    top: -6px;
}

.top--5
{
    top: -5px;
}

.right--5
{
    top: -5px;
}

.bottom--5
{
    top: -5px;
}

.left--5
{
    top: -5px;
}

.top--4
{
    top: -4px;
}

.right--4
{
    top: -4px;
}

.bottom--4
{
    top: -4px;
}

.left--4
{
    top: -4px;
}

.top--3
{
    top: -3px;
}

.right--3
{
    top: -3px;
}

.bottom--3
{
    top: -3px;
}

.left--3
{
    top: -3px;
}

.top--2
{
    top: -2px;
}

.right--2
{
    top: -2px;
}

.bottom--2
{
    top: -2px;
}

.left--2
{
    top: -2px;
}

.top--1
{
    top: -1px;
}

.right--1
{
    top: -1px;
}

.bottom--1
{
    top: -1px;
}

.left--1
{
    top: -1px;
}

.top-0
{
    top: 0;
}

.right-0
{
    top: 0;
}

.bottom-0
{
    top: 0;
}

.left-0
{
    top: 0;
}

.top-1
{
    top: 1px;
}

.right-1
{
    top: 1px;
}

.bottom-1
{
    top: 1px;
}

.left-1
{
    top: 1px;
}

.top-2
{
    top: 2px;
}

.right-2
{
    top: 2px;
}

.bottom-2
{
    top: 2px;
}

.left-2
{
    top: 2px;
}

.top-3
{
    top: 3px;
}

.right-3
{
    top: 3px;
}

.bottom-3
{
    top: 3px;
}

.left-3
{
    top: 3px;
}

.top-4
{
    top: 4px;
}

.right-4
{
    top: 4px;
}

.bottom-4
{
    top: 4px;
}

.left-4
{
    top: 4px;
}

.top-5
{
    top: 5px;
}

.right-5
{
    top: 5px;
}

.bottom-5
{
    top: 5px;
}

.left-5
{
    top: 5px;
}

.top-6
{
    top: 6px;
}

.right-6
{
    top: 6px;
}

.bottom-6
{
    top: 6px;
}

.left-6
{
    top: 6px;
}

.top-7
{
    top: 7px;
}

.right-7
{
    top: 7px;
}

.bottom-7
{
    top: 7px;
}

.left-7
{
    top: 7px;
}

.top-8
{
    top: 8px;
}

.right-8
{
    top: 8px;
}

.bottom-8
{
    top: 8px;
}

.left-8
{
    top: 8px;
}

.top-9
{
    top: 9px;
}

.right-9
{
    top: 9px;
}

.bottom-9
{
    top: 9px;
}

.left-9
{
    top: 9px;
}

.top-10
{
    top: 10px;
}

.right-10
{
    top: 10px;
}

.bottom-10
{
    top: 10px;
}

.left-10
{
    top: 10px;
}

/* ==========================================================================
   Helpers
   ========================================================================== */
/* Overflows
   ========================================================================== */
.overflow-scroll,
.overflow-x-scroll
{
    overflow-x: scroll !important;
}

.overflow-scroll,
.overflow-y-scroll
{
    overflow-y: scroll !important;
}

.overflow-hidden,
.overflow-x-hidden
{
    overflow-x: hidden !important;
}

.overflow-hidden,
.overflow-y-hidden
{
    overflow-y: hidden !important;
}

.oveflow-auto,
.oveflow-x-auto
{
    overflow-x: auto !important;
}

.oveflow-auto,
.oveflow-y-auto
{
    overflow-y: auto !important;
}

.overflow-visible,
.overflow-x-visible
{
    overflow: visible !important;
}

.overflow-visible,
.overflow-y-visible
{
    overflow: visible !important;
}

@media (min-width: 576px)
{
    .overflow-sm-scroll,
    .overflow-x-sm-scroll
    {
        overflow-x: scroll !important;
    }
    .overflow-sm-scroll,
    .overflow-y-sm-scroll
    {
        overflow-y: scroll !important;
    }
    .overflow-sm-hidden,
    .overflow-x-sm-hidden
    {
        overflow-x: hidden !important;
    }
    .overflow-sm-hidden,
    .overflow-y-sm-hidden
    {
        overflow-y: hidden !important;
    }
    .oveflow-sm-auto,
    .oveflow-x-sm-auto
    {
        overflow-x: auto !important;
    }
    .oveflow-sm-auto,
    .oveflow-y-sm-auto
    {
        overflow-y: auto !important;
    }
    .overflow-sm-visible,
    .overflow-x-sm-visible
    {
        overflow: visible !important;
    }
    .overflow-sm-visible,
    .overflow-y-sm-visible
    {
        overflow: visible !important;
    }
}

@media (min-width: 768px)
{
    .overflow-md-scroll,
    .overflow-x-md-scroll
    {
        overflow-x: scroll !important;
    }
    .overflow-md-scroll,
    .overflow-y-md-scroll
    {
        overflow-y: scroll !important;
    }
    .overflow-md-hidden,
    .overflow-x-md-hidden
    {
        overflow-x: hidden !important;
    }
    .overflow-md-hidden,
    .overflow-y-md-hidden
    {
        overflow-y: hidden !important;
    }
    .oveflow-md-auto,
    .oveflow-x-md-auto
    {
        overflow-x: auto !important;
    }
    .oveflow-md-auto,
    .oveflow-y-md-auto
    {
        overflow-y: auto !important;
    }
    .overflow-md-visible,
    .overflow-x-md-visible
    {
        overflow: visible !important;
    }
    .overflow-md-visible,
    .overflow-y-md-visible
    {
        overflow: visible !important;
    }
}

@media (min-width: 992px)
{
    .overflow-lg-scroll,
    .overflow-x-lg-scroll
    {
        overflow-x: scroll !important;
    }
    .overflow-lg-scroll,
    .overflow-y-lg-scroll
    {
        overflow-y: scroll !important;
    }
    .overflow-lg-hidden,
    .overflow-x-lg-hidden
    {
        overflow-x: hidden !important;
    }
    .overflow-lg-hidden,
    .overflow-y-lg-hidden
    {
        overflow-y: hidden !important;
    }
    .oveflow-lg-auto,
    .oveflow-x-lg-auto
    {
        overflow-x: auto !important;
    }
    .oveflow-lg-auto,
    .oveflow-y-lg-auto
    {
        overflow-y: auto !important;
    }
    .overflow-lg-visible,
    .overflow-x-lg-visible
    {
        overflow: visible !important;
    }
    .overflow-lg-visible,
    .overflow-y-lg-visible
    {
        overflow: visible !important;
    }
}

@media (min-width: 1200px)
{
    .overflow-xl-scroll,
    .overflow-x-xl-scroll
    {
        overflow-x: scroll !important;
    }
    .overflow-xl-scroll,
    .overflow-y-xl-scroll
    {
        overflow-y: scroll !important;
    }
    .overflow-xl-hidden,
    .overflow-x-xl-hidden
    {
        overflow-x: hidden !important;
    }
    .overflow-xl-hidden,
    .overflow-y-xl-hidden
    {
        overflow-y: hidden !important;
    }
    .oveflow-xl-auto,
    .oveflow-x-xl-auto
    {
        overflow-x: auto !important;
    }
    .oveflow-xl-auto,
    .oveflow-y-xl-auto
    {
        overflow-y: auto !important;
    }
    .overflow-xl-visible,
    .overflow-x-xl-visible
    {
        overflow: visible !important;
    }
    .overflow-xl-visible,
    .overflow-y-xl-visible
    {
        overflow: visible !important;
    }
}

@media (min-width: 1600px)
{
    .overflow-xxl-scroll,
    .overflow-x-xxl-scroll
    {
        overflow-x: scroll !important;
    }
    .overflow-xxl-scroll,
    .overflow-y-xxl-scroll
    {
        overflow-y: scroll !important;
    }
    .overflow-xxl-hidden,
    .overflow-x-xxl-hidden
    {
        overflow-x: hidden !important;
    }
    .overflow-xxl-hidden,
    .overflow-y-xxl-hidden
    {
        overflow-y: hidden !important;
    }
    .oveflow-xxl-auto,
    .oveflow-x-xxl-auto
    {
        overflow-x: auto !important;
    }
    .oveflow-xxl-auto,
    .oveflow-y-xxl-auto
    {
        overflow-y: auto !important;
    }
    .overflow-xxl-visible,
    .overflow-x-xxl-visible
    {
        overflow: visible !important;
    }
    .overflow-xxl-visible,
    .overflow-y-xxl-visible
    {
        overflow: visible !important;
    }
}

/* Extends
   ========================================================================== */
/**
 * Extend the area outside of the container.
 * For example good for extend background color
 */
.ext-l::before,
.ext-l::after,
.ext-r::before,
.ext-r::after
{
    position: absolute;
    top: 0;
    bottom: 0;

    content: '';

    background-color: inherit;
}

.ext-l::before
{
    right: 100%;
}
@media (min-width: 576px)
{
    .ext-l::before
    {
        width: calc(50vw - 270px + 2px);
    }
}
@media (min-width: 768px)
{
    .ext-l::before
    {
        width: calc(50vw - 360px + 2px);
    }
}
@media (min-width: 992px)
{
    .ext-l::before
    {
        width: calc(50vw - 480px + 2px);
    }
}
@media (min-width: 1200px)
{
    .ext-l::before
    {
        width: calc(50vw - 570px + 2px);
    }
}
@media (min-width: 1600px)
{
    .ext-l::before
    {
        width: calc(50vw - 650px + 2px);
    }
}

.ext-l.ext-t::after,
.ext-l.ext-b::after
{
    top: auto;
    right: 100%;
    bottom: 100%;

    height: 100%;
}
@media (min-width: 576px)
{
    .ext-l.ext-t::after,
    .ext-l.ext-b::after
    {
        width: calc(50vw - 270px + 2px);
    }
}
@media (min-width: 768px)
{
    .ext-l.ext-t::after,
    .ext-l.ext-b::after
    {
        width: calc(50vw - 360px + 2px);
    }
}
@media (min-width: 992px)
{
    .ext-l.ext-t::after,
    .ext-l.ext-b::after
    {
        width: calc(50vw - 480px + 2px);
    }
}
@media (min-width: 1200px)
{
    .ext-l.ext-t::after,
    .ext-l.ext-b::after
    {
        width: calc(50vw - 570px + 2px);
    }
}
@media (min-width: 1600px)
{
    .ext-l.ext-t::after,
    .ext-l.ext-b::after
    {
        width: calc(50vw - 650px + 2px);
    }
}

.ext-l.ext-t.ext-h-100::after,
.ext-l.ext-b.ext-h-100::after
{
    height: 100%;
}

.ext-l.ext-t.ext-h-125::after,
.ext-l.ext-b.ext-h-125::after
{
    height: 125%;
}

.ext-l.ext-t.ext-h-150::after,
.ext-l.ext-b.ext-h-150::after
{
    height: 150%;
}

.ext-l.ext-t.ext-h-175::after,
.ext-l.ext-b.ext-h-175::after
{
    height: 175%;
}

.ext-l.ext-t.ext-h-200::after,
.ext-l.ext-b.ext-h-200::after
{
    height: 200%;
}

.ext-l.ext-b::after
{
    top: 100%; 
    bottom: auto;
}

.ext-l.ext-l-no-gutters::before
{
    right: 100%;
}
@media (min-width: 576px)
{
    .ext-l.ext-l-no-gutters::before
    {
        width: calc(50vw - 270px + 15px + 2px);
    }
}
@media (min-width: 768px)
{
    .ext-l.ext-l-no-gutters::before
    {
        width: calc(50vw - 360px + 15px + 2px);
    }
}
@media (min-width: 992px)
{
    .ext-l.ext-l-no-gutters::before
    {
        width: calc(50vw - 480px + 15px + 2px);
    }
}
@media (min-width: 1200px)
{
    .ext-l.ext-l-no-gutters::before
    {
        width: calc(50vw - 570px + 15px + 2px);
    }
}
@media (min-width: 1600px)
{
    .ext-l.ext-l-no-gutters::before
    {
        width: calc(50vw - 650px + 15px + 2px);
    }
}

.ext-l.ext-l-no-gutters.ext-t::after,
.ext-l.ext-l-no-gutters.ext-b::after
{
    right: 100%;
}
@media (min-width: 576px)
{
    .ext-l.ext-l-no-gutters.ext-t::after,
    .ext-l.ext-l-no-gutters.ext-b::after
    {
        width: calc(50vw - 270px + 15px + 2px);
    }
}
@media (min-width: 768px)
{
    .ext-l.ext-l-no-gutters.ext-t::after,
    .ext-l.ext-l-no-gutters.ext-b::after
    {
        width: calc(50vw - 360px + 15px + 2px);
    }
}
@media (min-width: 992px)
{
    .ext-l.ext-l-no-gutters.ext-t::after,
    .ext-l.ext-l-no-gutters.ext-b::after
    {
        width: calc(50vw - 480px + 15px + 2px);
    }
}
@media (min-width: 1200px)
{
    .ext-l.ext-l-no-gutters.ext-t::after,
    .ext-l.ext-l-no-gutters.ext-b::after
    {
        width: calc(50vw - 570px + 15px + 2px);
    }
}
@media (min-width: 1600px)
{
    .ext-l.ext-l-no-gutters.ext-t::after,
    .ext-l.ext-l-no-gutters.ext-b::after
    {
        width: calc(50vw - 650px + 15px + 2px);
    }
}

.ext-r::after
{
    left: 100%;
}
@media (min-width: 576px)
{
    .ext-r::after
    {
        width: calc(50vw - 270px + 2px);
    }
}
@media (min-width: 768px)
{
    .ext-r::after
    {
        width: calc(50vw - 360px + 2px);
    }
}
@media (min-width: 992px)
{
    .ext-r::after
    {
        width: calc(50vw - 480px + 2px);
    }
}
@media (min-width: 1200px)
{
    .ext-r::after
    {
        width: calc(50vw - 570px + 2px);
    }
}
@media (min-width: 1600px)
{
    .ext-r::after
    {
        width: calc(50vw - 650px + 2px);
    }
}

.ext-r.ext-t::before,
.ext-r.ext-b::before
{
    top: auto;
    bottom: 100%;
    left: 100%;

    height: 100%;
}
@media (min-width: 576px)
{
    .ext-r.ext-t::before,
    .ext-r.ext-b::before
    {
        width: calc(50vw - 270px + 2px);
    }
}
@media (min-width: 768px)
{
    .ext-r.ext-t::before,
    .ext-r.ext-b::before
    {
        width: calc(50vw - 360px + 2px);
    }
}
@media (min-width: 992px)
{
    .ext-r.ext-t::before,
    .ext-r.ext-b::before
    {
        width: calc(50vw - 480px + 2px);
    }
}
@media (min-width: 1200px)
{
    .ext-r.ext-t::before,
    .ext-r.ext-b::before
    {
        width: calc(50vw - 570px + 2px);
    }
}
@media (min-width: 1600px)
{
    .ext-r.ext-t::before,
    .ext-r.ext-b::before
    {
        width: calc(50vw - 650px + 2px);
    }
}

.ext-r.ext-t.ext-h-100::before,
.ext-r.ext-b.ext-h-100::before
{
    height: 100%;
}

.ext-r.ext-t.ext-h-125::before,
.ext-r.ext-b.ext-h-125::before
{
    height: 125%;
}

.ext-r.ext-t.ext-h-150::before,
.ext-r.ext-b.ext-h-150::before
{
    height: 150%;
}

.ext-r.ext-t.ext-h-175::before,
.ext-r.ext-b.ext-h-175::before
{
    height: 175%;
}

.ext-r.ext-t.ext-h-200::before,
.ext-r.ext-b.ext-h-200::before
{
    height: 200%;
}

.ext-r.ext-b::before
{
    top: 100%; 
    bottom: auto;
}

.ext-r.ext-r-no-gutters::after
{
    left: 100%;
}
@media (min-width: 576px)
{
    .ext-r.ext-r-no-gutters::after
    {
        width: calc(50vw - 270px + 15px + 2px);
    }
}
@media (min-width: 768px)
{
    .ext-r.ext-r-no-gutters::after
    {
        width: calc(50vw - 360px + 15px + 2px);
    }
}
@media (min-width: 992px)
{
    .ext-r.ext-r-no-gutters::after
    {
        width: calc(50vw - 480px + 15px + 2px);
    }
}
@media (min-width: 1200px)
{
    .ext-r.ext-r-no-gutters::after
    {
        width: calc(50vw - 570px + 15px + 2px);
    }
}
@media (min-width: 1600px)
{
    .ext-r.ext-r-no-gutters::after
    {
        width: calc(50vw - 650px + 15px + 2px);
    }
}

.ext-r.ext-r-no-gutters.ext-t::after,
.ext-r.ext-r-no-gutters.ext-b::after
{
    left: 100%;
}
@media (min-width: 576px)
{
    .ext-r.ext-r-no-gutters.ext-t::after,
    .ext-r.ext-r-no-gutters.ext-b::after
    {
        width: calc(50vw - 270px + 15px + 2px);
    }
}
@media (min-width: 768px)
{
    .ext-r.ext-r-no-gutters.ext-t::after,
    .ext-r.ext-r-no-gutters.ext-b::after
    {
        width: calc(50vw - 360px + 15px + 2px);
    }
}
@media (min-width: 992px)
{
    .ext-r.ext-r-no-gutters.ext-t::after,
    .ext-r.ext-r-no-gutters.ext-b::after
    {
        width: calc(50vw - 480px + 15px + 2px);
    }
}
@media (min-width: 1200px)
{
    .ext-r.ext-r-no-gutters.ext-t::after,
    .ext-r.ext-r-no-gutters.ext-b::after
    {
        width: calc(50vw - 570px + 15px + 2px);
    }
}
@media (min-width: 1600px)
{
    .ext-r.ext-r-no-gutters.ext-t::after,
    .ext-r.ext-r-no-gutters.ext-b::after
    {
        width: calc(50vw - 650px + 15px + 2px);
    }
}

.release-container
{
    margin-right: calc(-50vw + 50%); 
    margin-left: calc(-50vw + 50%);
}

.unrelease-container
{
    margin-right: 0; 
    margin-left: 0;
}

@media (min-width: 576px)
{
    .release-sm-container
    {
        margin-right: calc(-50vw + 50%); 
        margin-left: calc(-50vw + 50%);
    }
    .unrelease-sm-container
    {
        margin-right: 0; 
        margin-left: 0;
    }
}

@media (min-width: 768px)
{
    .release-md-container
    {
        margin-right: calc(-50vw + 50%); 
        margin-left: calc(-50vw + 50%);
    }
    .unrelease-md-container
    {
        margin-right: 0; 
        margin-left: 0;
    }
}

@media (min-width: 992px)
{
    .release-lg-container
    {
        margin-right: calc(-50vw + 50%); 
        margin-left: calc(-50vw + 50%);
    }
    .unrelease-lg-container
    {
        margin-right: 0; 
        margin-left: 0;
    }
}

@media (min-width: 1200px)
{
    .release-xl-container
    {
        margin-right: calc(-50vw + 50%); 
        margin-left: calc(-50vw + 50%);
    }
    .unrelease-xl-container
    {
        margin-right: 0; 
        margin-left: 0;
    }
}

@media (min-width: 1600px)
{
    .release-xxl-container
    {
        margin-right: calc(-50vw + 50%); 
        margin-left: calc(-50vw + 50%);
    }
    .unrelease-xxl-container
    {
        margin-right: 0; 
        margin-left: 0;
    }
}

/* ==========================================================================
   Opacity
   ========================================================================== */
.opacity-0
{
    opacity: 0;
}

.opacity-0-hover
{
    transition: opacity .35s ease;
}
.opacity-0-hover:hover
{
    opacity: 0;
}

.opacity-1
{
    opacity: .1;
}

.opacity-1-hover
{
    transition: opacity .35s ease;
}
.opacity-1-hover:hover
{
    opacity: .1;
}

.opacity-2
{
    opacity: .2;
}

.opacity-2-hover
{
    transition: opacity .35s ease;
}
.opacity-2-hover:hover
{
    opacity: .2;
}

.opacity-3
{
    opacity: .3;
}

.opacity-3-hover
{
    transition: opacity .35s ease;
}
.opacity-3-hover:hover
{
    opacity: .3;
}

.opacity-4
{
    opacity: .4;
}

.opacity-4-hover
{
    transition: opacity .35s ease;
}
.opacity-4-hover:hover
{
    opacity: .4;
}

.opacity-5
{
    opacity: .5;
}

.opacity-5-hover
{
    transition: opacity .35s ease;
}
.opacity-5-hover:hover
{
    opacity: .5;
}

.opacity-6
{
    opacity: .6;
}

.opacity-6-hover
{
    transition: opacity .35s ease;
}
.opacity-6-hover:hover
{
    opacity: .6;
}

.opacity-7
{
    opacity: .7;
}

.opacity-7-hover
{
    transition: opacity .35s ease;
}
.opacity-7-hover:hover
{
    opacity: .7;
}

.opacity-8
{
    opacity: .8;
}

.opacity-8-hover
{
    transition: opacity .35s ease;
}
.opacity-8-hover:hover
{
    opacity: .8;
}

.opacity-9
{
    opacity: .9;
}

.opacity-9-hover
{
    transition: opacity .35s ease;
}
.opacity-9-hover:hover
{
    opacity: .9;
}

.opacity-10
{
    opacity: 1;
}

.opacity-10-hover
{
    transition: opacity .35s ease;
}
.opacity-10-hover:hover
{
    opacity: 1;
}

/* ==========================================================================
   Background overlays
   ========================================================================== */
.overlay-0::before
{
    background-color: rgba(31, 39, 73, 0) !important;
}

.overlay-primary-0::before
{
    background-color: rgba(241, 38, 57, 0) !important;
}

.overlay-secondary-0::before
{
    background-color: rgba(31, 39, 73, 0) !important;
}

.overlay-success-0::before
{
    background-color: rgba(46, 205, 117, 0) !important;
}

.overlay-info-0::before
{
    background-color: rgba(65, 198, 242, 0) !important;
}

.overlay-warning-0::before
{
    background-color: rgba(251, 204, 93, 0) !important;
}

.overlay-danger-0::before
{
    background-color: rgba(245, 84, 84, 0) !important;
}

.overlay-light-0::before
{
    background-color: rgba(245, 245, 245, 0) !important;
}

.overlay-dark-0::before
{
    background-color: rgba(52, 58, 64, 0) !important;
}

.overlay-blue-light-0::before
{
    background-color: rgba(243, 248, 252, 0) !important;
}

.overlay-10::before
{
    background-color: rgba(31, 39, 73, .1) !important;
}

.overlay-primary-10::before
{
    background-color: rgba(241, 38, 57, .1) !important;
}

.overlay-secondary-10::before
{
    background-color: rgba(31, 39, 73, .1) !important;
}

.overlay-success-10::before
{
    background-color: rgba(46, 205, 117, .1) !important;
}

.overlay-info-10::before
{
    background-color: rgba(65, 198, 242, .1) !important;
}

.overlay-warning-10::before
{
    background-color: rgba(251, 204, 93, .1) !important;
}

.overlay-danger-10::before
{
    background-color: rgba(245, 84, 84, .1) !important;
}

.overlay-light-10::before
{
    background-color: rgba(245, 245, 245, .1) !important;
}

.overlay-dark-10::before
{
    background-color: rgba(52, 58, 64, .1) !important;
}

.overlay-blue-light-10::before
{
    background-color: rgba(243, 248, 252, .1) !important;
}

.overlay-20::before
{
    background-color: rgba(31, 39, 73, .2) !important;
}

.overlay-primary-20::before
{
    background-color: rgba(241, 38, 57, .2) !important;
}

.overlay-secondary-20::before
{
    background-color: rgba(31, 39, 73, .2) !important;
}

.overlay-success-20::before
{
    background-color: rgba(46, 205, 117, .2) !important;
}

.overlay-info-20::before
{
    background-color: rgba(65, 198, 242, .2) !important;
}

.overlay-warning-20::before
{
    background-color: rgba(251, 204, 93, .2) !important;
}

.overlay-danger-20::before
{
    background-color: rgba(245, 84, 84, .2) !important;
}

.overlay-light-20::before
{
    background-color: rgba(245, 245, 245, .2) !important;
}

.overlay-dark-20::before
{
    background-color: rgba(52, 58, 64, .2) !important;
}

.overlay-blue-light-20::before
{
    background-color: rgba(243, 248, 252, .2) !important;
}

.overlay-30::before
{
    background-color: rgba(31, 39, 73, .3) !important;
}

.overlay-primary-30::before
{
    background-color: rgba(241, 38, 57, .3) !important;
}

.overlay-secondary-30::before
{
    background-color: rgba(31, 39, 73, .3) !important;
}

.overlay-success-30::before
{
    background-color: rgba(46, 205, 117, .3) !important;
}

.overlay-info-30::before
{
    background-color: rgba(65, 198, 242, .3) !important;
}

.overlay-warning-30::before
{
    background-color: rgba(251, 204, 93, .3) !important;
}

.overlay-danger-30::before
{
    background-color: rgba(245, 84, 84, .3) !important;
}

.overlay-light-30::before
{
    background-color: rgba(245, 245, 245, .3) !important;
}

.overlay-dark-30::before
{
    background-color: rgba(52, 58, 64, .3) !important;
}

.overlay-blue-light-30::before
{
    background-color: rgba(243, 248, 252, .3) !important;
}

.overlay-40::before
{
    background-color: rgba(31, 39, 73, .4) !important;
}

.overlay-primary-40::before
{
    background-color: rgba(241, 38, 57, .4) !important;
}

.overlay-secondary-40::before
{
    background-color: rgba(31, 39, 73, .4) !important;
}

.overlay-success-40::before
{
    background-color: rgba(46, 205, 117, .4) !important;
}

.overlay-info-40::before
{
    background-color: rgba(65, 198, 242, .4) !important;
}

.overlay-warning-40::before
{
    background-color: rgba(251, 204, 93, .4) !important;
}

.overlay-danger-40::before
{
    background-color: rgba(245, 84, 84, .4) !important;
}

.overlay-light-40::before
{
    background-color: rgba(245, 245, 245, .4) !important;
}

.overlay-dark-40::before
{
    background-color: rgba(52, 58, 64, .4) !important;
}

.overlay-blue-light-40::before
{
    background-color: rgba(243, 248, 252, .4) !important;
}

.overlay-50::before
{
    background-color: rgba(31, 39, 73, .5) !important;
}

.overlay-primary-50::before
{
    background-color: rgba(241, 38, 57, .5) !important;
}

.overlay-secondary-50::before
{
    background-color: rgba(31, 39, 73, .5) !important;
}

.overlay-success-50::before
{
    background-color: rgba(46, 205, 117, .5) !important;
}

.overlay-info-50::before
{
    background-color: rgba(65, 198, 242, .5) !important;
}

.overlay-warning-50::before
{
    background-color: rgba(251, 204, 93, .5) !important;
}

.overlay-danger-50::before
{
    background-color: rgba(245, 84, 84, .5) !important;
}

.overlay-light-50::before
{
    background-color: rgba(245, 245, 245, .5) !important;
}

.overlay-dark-50::before
{
    background-color: rgba(52, 58, 64, .5) !important;
}

.overlay-blue-light-50::before
{
    background-color: rgba(243, 248, 252, .5) !important;
}

.overlay-60::before
{
    background-color: rgba(31, 39, 73, .6) !important;
}

.overlay-primary-60::before
{
    background-color: rgba(241, 38, 57, .6) !important;
}

.overlay-secondary-60::before
{
    background-color: rgba(31, 39, 73, .6) !important;
}

.overlay-success-60::before
{
    background-color: rgba(46, 205, 117, .6) !important;
}

.overlay-info-60::before
{
    background-color: rgba(65, 198, 242, .6) !important;
}

.overlay-warning-60::before
{
    background-color: rgba(251, 204, 93, .6) !important;
}

.overlay-danger-60::before
{
    background-color: rgba(245, 84, 84, .6) !important;
}

.overlay-light-60::before
{
    background-color: rgba(245, 245, 245, .6) !important;
}

.overlay-dark-60::before
{
    background-color: rgba(52, 58, 64, .6) !important;
}

.overlay-blue-light-60::before
{
    background-color: rgba(243, 248, 252, .6) !important;
}

.overlay-70::before
{
    background-color: rgba(31, 39, 73, .7) !important;
}

.overlay-primary-70::before
{
    background-color: rgba(241, 38, 57, .7) !important;
}

.overlay-secondary-70::before
{
    background-color: rgba(31, 39, 73, .7) !important;
}

.overlay-success-70::before
{
    background-color: rgba(46, 205, 117, .7) !important;
}

.overlay-info-70::before
{
    background-color: rgba(65, 198, 242, .7) !important;
}

.overlay-warning-70::before
{
    background-color: rgba(251, 204, 93, .7) !important;
}

.overlay-danger-70::before
{
    background-color: rgba(245, 84, 84, .7) !important;
}

.overlay-light-70::before
{
    background-color: rgba(245, 245, 245, .7) !important;
}

.overlay-dark-70::before
{
    background-color: rgba(52, 58, 64, .7) !important;
}

.overlay-blue-light-70::before
{
    background-color: rgba(243, 248, 252, .7) !important;
}

.overlay-80::before
{
    background-color: rgba(31, 39, 73, .8) !important;
}

.overlay-primary-80::before
{
    background-color: rgba(241, 38, 57, .8) !important;
}

.overlay-secondary-80::before
{
    background-color: rgba(31, 39, 73, .8) !important;
}

.overlay-success-80::before
{
    background-color: rgba(46, 205, 117, .8) !important;
}

.overlay-info-80::before
{
    background-color: rgba(65, 198, 242, .8) !important;
}

.overlay-warning-80::before
{
    background-color: rgba(251, 204, 93, .8) !important;
}

.overlay-danger-80::before
{
    background-color: rgba(245, 84, 84, .8) !important;
}

.overlay-light-80::before
{
    background-color: rgba(245, 245, 245, .8) !important;
}

.overlay-dark-80::before
{
    background-color: rgba(52, 58, 64, .8) !important;
}

.overlay-blue-light-80::before
{
    background-color: rgba(243, 248, 252, .8) !important;
}

.overlay-90::before
{
    background-color: rgba(31, 39, 73, .9) !important;
}

.overlay-primary-90::before
{
    background-color: rgba(241, 38, 57, .9) !important;
}

.overlay-secondary-90::before
{
    background-color: rgba(31, 39, 73, .9) !important;
}

.overlay-success-90::before
{
    background-color: rgba(46, 205, 117, .9) !important;
}

.overlay-info-90::before
{
    background-color: rgba(65, 198, 242, .9) !important;
}

.overlay-warning-90::before
{
    background-color: rgba(251, 204, 93, .9) !important;
}

.overlay-danger-90::before
{
    background-color: rgba(245, 84, 84, .9) !important;
}

.overlay-light-90::before
{
    background-color: rgba(245, 245, 245, .9) !important;
}

.overlay-dark-90::before
{
    background-color: rgba(52, 58, 64, .9) !important;
}

.overlay-blue-light-90::before
{
    background-color: rgba(243, 248, 252, .9) !important;
}

.overlay-100::before
{
    background-color: #1f2749 !important;
}

.overlay-primary-100::before
{
    background-color: #f12639 !important;
}

.overlay-secondary-100::before
{
    background-color: #1f2749 !important;
}

.overlay-success-100::before
{
    background-color: #2ecd75 !important;
}

.overlay-info-100::before
{
    background-color: #41c6f2 !important;
}

.overlay-warning-100::before
{
    background-color: #fbcc5d !important;
}

.overlay-danger-100::before
{
    background-color: #f55454 !important;
}

.overlay-light-100::before
{
    background-color: whitesmoke !important;
}

.overlay-dark-100::before
{
    background-color: #343a40 !important;
}

.overlay-blue-light-100::before
{
    background-color: #f3f8fc !important;
}

/* ==========================================================================
   Sizing
   ========================================================================== */
/* Height values in vh */
.minh-100vh
{
    min-height: 100vh;
}

.minh-50vh
{
    min-height: 50vh;
}

.h-100vh
{
    height: 100vh;
}

.h-50vh
{
    height: 50vh;
}

/* ==========================================================================
   Spacing
   ========================================================================== */
/* Negative margins
   ========================================================================== */
.m-neg-0
{
    margin: 0 !important;
}

.mt-neg-0,
.my-neg-0
{
    margin-top: 0 !important;
}

.mr-neg-0,
.mx-neg-0
{
    margin-right: 0 !important;
}

.mb-neg-0,
.my-neg-0
{
    margin-bottom: 0 !important;
}

.ml-neg-0,
.mx-neg-0
{
    margin-left: 0 !important;
}

.m-neg-1
{
    margin: .25rem !important;
}

.mt-neg-1,
.my-neg-1
{
    margin-top: -.25rem !important;
}

.mr-neg-1,
.mx-neg-1
{
    margin-right: -.25rem !important;
}

.mb-neg-1,
.my-neg-1
{
    margin-bottom: -.25rem !important;
}

.ml-neg-1,
.mx-neg-1
{
    margin-left: -.25rem !important;
}

.m-neg-2
{
    margin: .5rem !important;
}

.mt-neg-2,
.my-neg-2
{
    margin-top: -.5rem !important;
}

.mr-neg-2,
.mx-neg-2
{
    margin-right: -.5rem !important;
}

.mb-neg-2,
.my-neg-2
{
    margin-bottom: -.5rem !important;
}

.ml-neg-2,
.mx-neg-2
{
    margin-left: -.5rem !important;
}

.m-neg-3
{
    margin: 1rem !important;
}

.mt-neg-3,
.my-neg-3
{
    margin-top: -1rem !important;
}

.mr-neg-3,
.mx-neg-3
{
    margin-right: -1rem !important;
}

.mb-neg-3,
.my-neg-3
{
    margin-bottom: -1rem !important;
}

.ml-neg-3,
.mx-neg-3
{
    margin-left: -1rem !important;
}

.m-neg-4
{
    margin: 1.5rem !important;
}

.mt-neg-4,
.my-neg-4
{
    margin-top: -1.5rem !important;
}

.mr-neg-4,
.mx-neg-4
{
    margin-right: -1.5rem !important;
}

.mb-neg-4,
.my-neg-4
{
    margin-bottom: -1.5rem !important;
}

.ml-neg-4,
.mx-neg-4
{
    margin-left: -1.5rem !important;
}

.m-neg-5
{
    margin: 3rem !important;
}

.mt-neg-5,
.my-neg-5
{
    margin-top: -3rem !important;
}

.mr-neg-5,
.mx-neg-5
{
    margin-right: -3rem !important;
}

.mb-neg-5,
.my-neg-5
{
    margin-bottom: -3rem !important;
}

.ml-neg-5,
.mx-neg-5
{
    margin-left: -3rem !important;
}

.m-neg-6
{
    margin: 4.5rem !important;
}

.mt-neg-6,
.my-neg-6
{
    margin-top: -4.5rem !important;
}

.mr-neg-6,
.mx-neg-6
{
    margin-right: -4.5rem !important;
}

.mb-neg-6,
.my-neg-6
{
    margin-bottom: -4.5rem !important;
}

.ml-neg-6,
.mx-neg-6
{
    margin-left: -4.5rem !important;
}

.m-neg-7
{
    margin: 6rem !important;
}

.mt-neg-7,
.my-neg-7
{
    margin-top: -6rem !important;
}

.mr-neg-7,
.mx-neg-7
{
    margin-right: -6rem !important;
}

.mb-neg-7,
.my-neg-7
{
    margin-bottom: -6rem !important;
}

.ml-neg-7,
.mx-neg-7
{
    margin-left: -6rem !important;
}

.m-neg-8
{
    margin: 7.5rem !important;
}

.mt-neg-8,
.my-neg-8
{
    margin-top: -7.5rem !important;
}

.mr-neg-8,
.mx-neg-8
{
    margin-right: -7.5rem !important;
}

.mb-neg-8,
.my-neg-8
{
    margin-bottom: -7.5rem !important;
}

.ml-neg-8,
.mx-neg-8
{
    margin-left: -7.5rem !important;
}

.m-neg-9
{
    margin: 9rem !important;
}

.mt-neg-9,
.my-neg-9
{
    margin-top: -9rem !important;
}

.mr-neg-9,
.mx-neg-9
{
    margin-right: -9rem !important;
}

.mb-neg-9,
.my-neg-9
{
    margin-bottom: -9rem !important;
}

.ml-neg-9,
.mx-neg-9
{
    margin-left: -9rem !important;
}

.m-neg-10
{
    margin: 10rem !important;
}

.mt-neg-10,
.my-neg-10
{
    margin-top: -10rem !important;
}

.mr-neg-10,
.mx-neg-10
{
    margin-right: -10rem !important;
}

.mb-neg-10,
.my-neg-10
{
    margin-bottom: -10rem !important;
}

.ml-neg-10,
.mx-neg-10
{
    margin-left: -10rem !important;
}

.m-neg-11
{
    margin: 12rem !important;
}

.mt-neg-11,
.my-neg-11
{
    margin-top: -12rem !important;
}

.mr-neg-11,
.mx-neg-11
{
    margin-right: -12rem !important;
}

.mb-neg-11,
.my-neg-11
{
    margin-bottom: -12rem !important;
}

.ml-neg-11,
.mx-neg-11
{
    margin-left: -12rem !important;
}

.m-neg-12
{
    margin: 14.5rem !important;
}

.mt-neg-12,
.my-neg-12
{
    margin-top: -14.5rem !important;
}

.mr-neg-12,
.mx-neg-12
{
    margin-right: -14.5rem !important;
}

.mb-neg-12,
.my-neg-12
{
    margin-bottom: -14.5rem !important;
}

.ml-neg-12,
.mx-neg-12
{
    margin-left: -14.5rem !important;
}

.m-neg-13
{
    margin: 15rem !important;
}

.mt-neg-13,
.my-neg-13
{
    margin-top: -15rem !important;
}

.mr-neg-13,
.mx-neg-13
{
    margin-right: -15rem !important;
}

.mb-neg-13,
.my-neg-13
{
    margin-bottom: -15rem !important;
}

.ml-neg-13,
.mx-neg-13
{
    margin-left: -15rem !important;
}

.m-neg-14
{
    margin: 16rem !important;
}

.mt-neg-14,
.my-neg-14
{
    margin-top: -16rem !important;
}

.mr-neg-14,
.mx-neg-14
{
    margin-right: -16rem !important;
}

.mb-neg-14,
.my-neg-14
{
    margin-bottom: -16rem !important;
}

.ml-neg-14,
.mx-neg-14
{
    margin-left: -16rem !important;
}

.m-neg-15
{
    margin: 17rem !important;
}

.mt-neg-15,
.my-neg-15
{
    margin-top: -17rem !important;
}

.mr-neg-15,
.mx-neg-15
{
    margin-right: -17rem !important;
}

.mb-neg-15,
.my-neg-15
{
    margin-bottom: -17rem !important;
}

.ml-neg-15,
.mx-neg-15
{
    margin-left: -17rem !important;
}

@media (min-width: 576px)
{
    .m-sm-neg-0
    {
        margin: 0 !important;
    }
    .mt-sm-neg-0,
    .my-sm-neg-0
    {
        margin-top: 0 !important;
    }
    .mr-sm-neg-0,
    .mx-sm-neg-0
    {
        margin-right: 0 !important;
    }
    .mb-sm-neg-0,
    .my-sm-neg-0
    {
        margin-bottom: 0 !important;
    }
    .ml-sm-neg-0,
    .mx-sm-neg-0
    {
        margin-left: 0 !important;
    }
    .m-sm-neg-1
    {
        margin: .25rem !important;
    }
    .mt-sm-neg-1,
    .my-sm-neg-1
    {
        margin-top: -.25rem !important;
    }
    .mr-sm-neg-1,
    .mx-sm-neg-1
    {
        margin-right: -.25rem !important;
    }
    .mb-sm-neg-1,
    .my-sm-neg-1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-sm-neg-1,
    .mx-sm-neg-1
    {
        margin-left: -.25rem !important;
    }
    .m-sm-neg-2
    {
        margin: .5rem !important;
    }
    .mt-sm-neg-2,
    .my-sm-neg-2
    {
        margin-top: -.5rem !important;
    }
    .mr-sm-neg-2,
    .mx-sm-neg-2
    {
        margin-right: -.5rem !important;
    }
    .mb-sm-neg-2,
    .my-sm-neg-2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-sm-neg-2,
    .mx-sm-neg-2
    {
        margin-left: -.5rem !important;
    }
    .m-sm-neg-3
    {
        margin: 1rem !important;
    }
    .mt-sm-neg-3,
    .my-sm-neg-3
    {
        margin-top: -1rem !important;
    }
    .mr-sm-neg-3,
    .mx-sm-neg-3
    {
        margin-right: -1rem !important;
    }
    .mb-sm-neg-3,
    .my-sm-neg-3
    {
        margin-bottom: -1rem !important;
    }
    .ml-sm-neg-3,
    .mx-sm-neg-3
    {
        margin-left: -1rem !important;
    }
    .m-sm-neg-4
    {
        margin: 1.5rem !important;
    }
    .mt-sm-neg-4,
    .my-sm-neg-4
    {
        margin-top: -1.5rem !important;
    }
    .mr-sm-neg-4,
    .mx-sm-neg-4
    {
        margin-right: -1.5rem !important;
    }
    .mb-sm-neg-4,
    .my-sm-neg-4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-sm-neg-4,
    .mx-sm-neg-4
    {
        margin-left: -1.5rem !important;
    }
    .m-sm-neg-5
    {
        margin: 3rem !important;
    }
    .mt-sm-neg-5,
    .my-sm-neg-5
    {
        margin-top: -3rem !important;
    }
    .mr-sm-neg-5,
    .mx-sm-neg-5
    {
        margin-right: -3rem !important;
    }
    .mb-sm-neg-5,
    .my-sm-neg-5
    {
        margin-bottom: -3rem !important;
    }
    .ml-sm-neg-5,
    .mx-sm-neg-5
    {
        margin-left: -3rem !important;
    }
    .m-sm-neg-6
    {
        margin: 4.5rem !important;
    }
    .mt-sm-neg-6,
    .my-sm-neg-6
    {
        margin-top: -4.5rem !important;
    }
    .mr-sm-neg-6,
    .mx-sm-neg-6
    {
        margin-right: -4.5rem !important;
    }
    .mb-sm-neg-6,
    .my-sm-neg-6
    {
        margin-bottom: -4.5rem !important;
    }
    .ml-sm-neg-6,
    .mx-sm-neg-6
    {
        margin-left: -4.5rem !important;
    }
    .m-sm-neg-7
    {
        margin: 6rem !important;
    }
    .mt-sm-neg-7,
    .my-sm-neg-7
    {
        margin-top: -6rem !important;
    }
    .mr-sm-neg-7,
    .mx-sm-neg-7
    {
        margin-right: -6rem !important;
    }
    .mb-sm-neg-7,
    .my-sm-neg-7
    {
        margin-bottom: -6rem !important;
    }
    .ml-sm-neg-7,
    .mx-sm-neg-7
    {
        margin-left: -6rem !important;
    }
    .m-sm-neg-8
    {
        margin: 7.5rem !important;
    }
    .mt-sm-neg-8,
    .my-sm-neg-8
    {
        margin-top: -7.5rem !important;
    }
    .mr-sm-neg-8,
    .mx-sm-neg-8
    {
        margin-right: -7.5rem !important;
    }
    .mb-sm-neg-8,
    .my-sm-neg-8
    {
        margin-bottom: -7.5rem !important;
    }
    .ml-sm-neg-8,
    .mx-sm-neg-8
    {
        margin-left: -7.5rem !important;
    }
    .m-sm-neg-9
    {
        margin: 9rem !important;
    }
    .mt-sm-neg-9,
    .my-sm-neg-9
    {
        margin-top: -9rem !important;
    }
    .mr-sm-neg-9,
    .mx-sm-neg-9
    {
        margin-right: -9rem !important;
    }
    .mb-sm-neg-9,
    .my-sm-neg-9
    {
        margin-bottom: -9rem !important;
    }
    .ml-sm-neg-9,
    .mx-sm-neg-9
    {
        margin-left: -9rem !important;
    }
    .m-sm-neg-10
    {
        margin: 10rem !important;
    }
    .mt-sm-neg-10,
    .my-sm-neg-10
    {
        margin-top: -10rem !important;
    }
    .mr-sm-neg-10,
    .mx-sm-neg-10
    {
        margin-right: -10rem !important;
    }
    .mb-sm-neg-10,
    .my-sm-neg-10
    {
        margin-bottom: -10rem !important;
    }
    .ml-sm-neg-10,
    .mx-sm-neg-10
    {
        margin-left: -10rem !important;
    }
    .m-sm-neg-11
    {
        margin: 12rem !important;
    }
    .mt-sm-neg-11,
    .my-sm-neg-11
    {
        margin-top: -12rem !important;
    }
    .mr-sm-neg-11,
    .mx-sm-neg-11
    {
        margin-right: -12rem !important;
    }
    .mb-sm-neg-11,
    .my-sm-neg-11
    {
        margin-bottom: -12rem !important;
    }
    .ml-sm-neg-11,
    .mx-sm-neg-11
    {
        margin-left: -12rem !important;
    }
    .m-sm-neg-12
    {
        margin: 14.5rem !important;
    }
    .mt-sm-neg-12,
    .my-sm-neg-12
    {
        margin-top: -14.5rem !important;
    }
    .mr-sm-neg-12,
    .mx-sm-neg-12
    {
        margin-right: -14.5rem !important;
    }
    .mb-sm-neg-12,
    .my-sm-neg-12
    {
        margin-bottom: -14.5rem !important;
    }
    .ml-sm-neg-12,
    .mx-sm-neg-12
    {
        margin-left: -14.5rem !important;
    }
    .m-sm-neg-13
    {
        margin: 15rem !important;
    }
    .mt-sm-neg-13,
    .my-sm-neg-13
    {
        margin-top: -15rem !important;
    }
    .mr-sm-neg-13,
    .mx-sm-neg-13
    {
        margin-right: -15rem !important;
    }
    .mb-sm-neg-13,
    .my-sm-neg-13
    {
        margin-bottom: -15rem !important;
    }
    .ml-sm-neg-13,
    .mx-sm-neg-13
    {
        margin-left: -15rem !important;
    }
    .m-sm-neg-14
    {
        margin: 16rem !important;
    }
    .mt-sm-neg-14,
    .my-sm-neg-14
    {
        margin-top: -16rem !important;
    }
    .mr-sm-neg-14,
    .mx-sm-neg-14
    {
        margin-right: -16rem !important;
    }
    .mb-sm-neg-14,
    .my-sm-neg-14
    {
        margin-bottom: -16rem !important;
    }
    .ml-sm-neg-14,
    .mx-sm-neg-14
    {
        margin-left: -16rem !important;
    }
    .m-sm-neg-15
    {
        margin: 17rem !important;
    }
    .mt-sm-neg-15,
    .my-sm-neg-15
    {
        margin-top: -17rem !important;
    }
    .mr-sm-neg-15,
    .mx-sm-neg-15
    {
        margin-right: -17rem !important;
    }
    .mb-sm-neg-15,
    .my-sm-neg-15
    {
        margin-bottom: -17rem !important;
    }
    .ml-sm-neg-15,
    .mx-sm-neg-15
    {
        margin-left: -17rem !important;
    }
}

@media (min-width: 768px)
{
    .m-md-neg-0
    {
        margin: 0 !important;
    }
    .mt-md-neg-0,
    .my-md-neg-0
    {
        margin-top: 0 !important;
    }
    .mr-md-neg-0,
    .mx-md-neg-0
    {
        margin-right: 0 !important;
    }
    .mb-md-neg-0,
    .my-md-neg-0
    {
        margin-bottom: 0 !important;
    }
    .ml-md-neg-0,
    .mx-md-neg-0
    {
        margin-left: 0 !important;
    }
    .m-md-neg-1
    {
        margin: .25rem !important;
    }
    .mt-md-neg-1,
    .my-md-neg-1
    {
        margin-top: -.25rem !important;
    }
    .mr-md-neg-1,
    .mx-md-neg-1
    {
        margin-right: -.25rem !important;
    }
    .mb-md-neg-1,
    .my-md-neg-1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-md-neg-1,
    .mx-md-neg-1
    {
        margin-left: -.25rem !important;
    }
    .m-md-neg-2
    {
        margin: .5rem !important;
    }
    .mt-md-neg-2,
    .my-md-neg-2
    {
        margin-top: -.5rem !important;
    }
    .mr-md-neg-2,
    .mx-md-neg-2
    {
        margin-right: -.5rem !important;
    }
    .mb-md-neg-2,
    .my-md-neg-2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-md-neg-2,
    .mx-md-neg-2
    {
        margin-left: -.5rem !important;
    }
    .m-md-neg-3
    {
        margin: 1rem !important;
    }
    .mt-md-neg-3,
    .my-md-neg-3
    {
        margin-top: -1rem !important;
    }
    .mr-md-neg-3,
    .mx-md-neg-3
    {
        margin-right: -1rem !important;
    }
    .mb-md-neg-3,
    .my-md-neg-3
    {
        margin-bottom: -1rem !important;
    }
    .ml-md-neg-3,
    .mx-md-neg-3
    {
        margin-left: -1rem !important;
    }
    .m-md-neg-4
    {
        margin: 1.5rem !important;
    }
    .mt-md-neg-4,
    .my-md-neg-4
    {
        margin-top: -1.5rem !important;
    }
    .mr-md-neg-4,
    .mx-md-neg-4
    {
        margin-right: -1.5rem !important;
    }
    .mb-md-neg-4,
    .my-md-neg-4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-md-neg-4,
    .mx-md-neg-4
    {
        margin-left: -1.5rem !important;
    }
    .m-md-neg-5
    {
        margin: 3rem !important;
    }
    .mt-md-neg-5,
    .my-md-neg-5
    {
        margin-top: -3rem !important;
    }
    .mr-md-neg-5,
    .mx-md-neg-5
    {
        margin-right: -3rem !important;
    }
    .mb-md-neg-5,
    .my-md-neg-5
    {
        margin-bottom: -3rem !important;
    }
    .ml-md-neg-5,
    .mx-md-neg-5
    {
        margin-left: -3rem !important;
    }
    .m-md-neg-6
    {
        margin: 4.5rem !important;
    }
    .mt-md-neg-6,
    .my-md-neg-6
    {
        margin-top: -4.5rem !important;
    }
    .mr-md-neg-6,
    .mx-md-neg-6
    {
        margin-right: -4.5rem !important;
    }
    .mb-md-neg-6,
    .my-md-neg-6
    {
        margin-bottom: -4.5rem !important;
    }
    .ml-md-neg-6,
    .mx-md-neg-6
    {
        margin-left: -4.5rem !important;
    }
    .m-md-neg-7
    {
        margin: 6rem !important;
    }
    .mt-md-neg-7,
    .my-md-neg-7
    {
        margin-top: -6rem !important;
    }
    .mr-md-neg-7,
    .mx-md-neg-7
    {
        margin-right: -6rem !important;
    }
    .mb-md-neg-7,
    .my-md-neg-7
    {
        margin-bottom: -6rem !important;
    }
    .ml-md-neg-7,
    .mx-md-neg-7
    {
        margin-left: -6rem !important;
    }
    .m-md-neg-8
    {
        margin: 7.5rem !important;
    }
    .mt-md-neg-8,
    .my-md-neg-8
    {
        margin-top: -7.5rem !important;
    }
    .mr-md-neg-8,
    .mx-md-neg-8
    {
        margin-right: -7.5rem !important;
    }
    .mb-md-neg-8,
    .my-md-neg-8
    {
        margin-bottom: -7.5rem !important;
    }
    .ml-md-neg-8,
    .mx-md-neg-8
    {
        margin-left: -7.5rem !important;
    }
    .m-md-neg-9
    {
        margin: 9rem !important;
    }
    .mt-md-neg-9,
    .my-md-neg-9
    {
        margin-top: -9rem !important;
    }
    .mr-md-neg-9,
    .mx-md-neg-9
    {
        margin-right: -9rem !important;
    }
    .mb-md-neg-9,
    .my-md-neg-9
    {
        margin-bottom: -9rem !important;
    }
    .ml-md-neg-9,
    .mx-md-neg-9
    {
        margin-left: -9rem !important;
    }
    .m-md-neg-10
    {
        margin: 10rem !important;
    }
    .mt-md-neg-10,
    .my-md-neg-10
    {
        margin-top: -10rem !important;
    }
    .mr-md-neg-10,
    .mx-md-neg-10
    {
        margin-right: -10rem !important;
    }
    .mb-md-neg-10,
    .my-md-neg-10
    {
        margin-bottom: -10rem !important;
    }
    .ml-md-neg-10,
    .mx-md-neg-10
    {
        margin-left: -10rem !important;
    }
    .m-md-neg-11
    {
        margin: 12rem !important;
    }
    .mt-md-neg-11,
    .my-md-neg-11
    {
        margin-top: -12rem !important;
    }
    .mr-md-neg-11,
    .mx-md-neg-11
    {
        margin-right: -12rem !important;
    }
    .mb-md-neg-11,
    .my-md-neg-11
    {
        margin-bottom: -12rem !important;
    }
    .ml-md-neg-11,
    .mx-md-neg-11
    {
        margin-left: -12rem !important;
    }
    .m-md-neg-12
    {
        margin: 14.5rem !important;
    }
    .mt-md-neg-12,
    .my-md-neg-12
    {
        margin-top: -14.5rem !important;
    }
    .mr-md-neg-12,
    .mx-md-neg-12
    {
        margin-right: -14.5rem !important;
    }
    .mb-md-neg-12,
    .my-md-neg-12
    {
        margin-bottom: -14.5rem !important;
    }
    .ml-md-neg-12,
    .mx-md-neg-12
    {
        margin-left: -14.5rem !important;
    }
    .m-md-neg-13
    {
        margin: 15rem !important;
    }
    .mt-md-neg-13,
    .my-md-neg-13
    {
        margin-top: -15rem !important;
    }
    .mr-md-neg-13,
    .mx-md-neg-13
    {
        margin-right: -15rem !important;
    }
    .mb-md-neg-13,
    .my-md-neg-13
    {
        margin-bottom: -15rem !important;
    }
    .ml-md-neg-13,
    .mx-md-neg-13
    {
        margin-left: -15rem !important;
    }
    .m-md-neg-14
    {
        margin: 16rem !important;
    }
    .mt-md-neg-14,
    .my-md-neg-14
    {
        margin-top: -16rem !important;
    }
    .mr-md-neg-14,
    .mx-md-neg-14
    {
        margin-right: -16rem !important;
    }
    .mb-md-neg-14,
    .my-md-neg-14
    {
        margin-bottom: -16rem !important;
    }
    .ml-md-neg-14,
    .mx-md-neg-14
    {
        margin-left: -16rem !important;
    }
    .m-md-neg-15
    {
        margin: 17rem !important;
    }
    .mt-md-neg-15,
    .my-md-neg-15
    {
        margin-top: -17rem !important;
    }
    .mr-md-neg-15,
    .mx-md-neg-15
    {
        margin-right: -17rem !important;
    }
    .mb-md-neg-15,
    .my-md-neg-15
    {
        margin-bottom: -17rem !important;
    }
    .ml-md-neg-15,
    .mx-md-neg-15
    {
        margin-left: -17rem !important;
    }
}

@media (min-width: 992px)
{
    .m-lg-neg-0
    {
        margin: 0 !important;
    }
    .mt-lg-neg-0,
    .my-lg-neg-0
    {
        margin-top: 0 !important;
    }
    .mr-lg-neg-0,
    .mx-lg-neg-0
    {
        margin-right: 0 !important;
    }
    .mb-lg-neg-0,
    .my-lg-neg-0
    {
        margin-bottom: 0 !important;
    }
    .ml-lg-neg-0,
    .mx-lg-neg-0
    {
        margin-left: 0 !important;
    }
    .m-lg-neg-1
    {
        margin: .25rem !important;
    }
    .mt-lg-neg-1,
    .my-lg-neg-1
    {
        margin-top: -.25rem !important;
    }
    .mr-lg-neg-1,
    .mx-lg-neg-1
    {
        margin-right: -.25rem !important;
    }
    .mb-lg-neg-1,
    .my-lg-neg-1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-lg-neg-1,
    .mx-lg-neg-1
    {
        margin-left: -.25rem !important;
    }
    .m-lg-neg-2
    {
        margin: .5rem !important;
    }
    .mt-lg-neg-2,
    .my-lg-neg-2
    {
        margin-top: -.5rem !important;
    }
    .mr-lg-neg-2,
    .mx-lg-neg-2
    {
        margin-right: -.5rem !important;
    }
    .mb-lg-neg-2,
    .my-lg-neg-2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-lg-neg-2,
    .mx-lg-neg-2
    {
        margin-left: -.5rem !important;
    }
    .m-lg-neg-3
    {
        margin: 1rem !important;
    }
    .mt-lg-neg-3,
    .my-lg-neg-3
    {
        margin-top: -1rem !important;
    }
    .mr-lg-neg-3,
    .mx-lg-neg-3
    {
        margin-right: -1rem !important;
    }
    .mb-lg-neg-3,
    .my-lg-neg-3
    {
        margin-bottom: -1rem !important;
    }
    .ml-lg-neg-3,
    .mx-lg-neg-3
    {
        margin-left: -1rem !important;
    }
    .m-lg-neg-4
    {
        margin: 1.5rem !important;
    }
    .mt-lg-neg-4,
    .my-lg-neg-4
    {
        margin-top: -1.5rem !important;
    }
    .mr-lg-neg-4,
    .mx-lg-neg-4
    {
        margin-right: -1.5rem !important;
    }
    .mb-lg-neg-4,
    .my-lg-neg-4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-lg-neg-4,
    .mx-lg-neg-4
    {
        margin-left: -1.5rem !important;
    }
    .m-lg-neg-5
    {
        margin: 3rem !important;
    }
    .mt-lg-neg-5,
    .my-lg-neg-5
    {
        margin-top: -3rem !important;
    }
    .mr-lg-neg-5,
    .mx-lg-neg-5
    {
        margin-right: -3rem !important;
    }
    .mb-lg-neg-5,
    .my-lg-neg-5
    {
        margin-bottom: -3rem !important;
    }
    .ml-lg-neg-5,
    .mx-lg-neg-5
    {
        margin-left: -3rem !important;
    }
    .m-lg-neg-6
    {
        margin: 4.5rem !important;
    }
    .mt-lg-neg-6,
    .my-lg-neg-6
    {
        margin-top: -4.5rem !important;
    }
    .mr-lg-neg-6,
    .mx-lg-neg-6
    {
        margin-right: -4.5rem !important;
    }
    .mb-lg-neg-6,
    .my-lg-neg-6
    {
        margin-bottom: -4.5rem !important;
    }
    .ml-lg-neg-6,
    .mx-lg-neg-6
    {
        margin-left: -4.5rem !important;
    }
    .m-lg-neg-7
    {
        margin: 6rem !important;
    }
    .mt-lg-neg-7,
    .my-lg-neg-7
    {
        margin-top: -6rem !important;
    }
    .mr-lg-neg-7,
    .mx-lg-neg-7
    {
        margin-right: -6rem !important;
    }
    .mb-lg-neg-7,
    .my-lg-neg-7
    {
        margin-bottom: -6rem !important;
    }
    .ml-lg-neg-7,
    .mx-lg-neg-7
    {
        margin-left: -6rem !important;
    }
    .m-lg-neg-8
    {
        margin: 7.5rem !important;
    }
    .mt-lg-neg-8,
    .my-lg-neg-8
    {
        margin-top: -7.5rem !important;
    }
    .mr-lg-neg-8,
    .mx-lg-neg-8
    {
        margin-right: -7.5rem !important;
    }
    .mb-lg-neg-8,
    .my-lg-neg-8
    {
        margin-bottom: -7.5rem !important;
    }
    .ml-lg-neg-8,
    .mx-lg-neg-8
    {
        margin-left: -7.5rem !important;
    }
    .m-lg-neg-9
    {
        margin: 9rem !important;
    }
    .mt-lg-neg-9,
    .my-lg-neg-9
    {
        margin-top: -9rem !important;
    }
    .mr-lg-neg-9,
    .mx-lg-neg-9
    {
        margin-right: -9rem !important;
    }
    .mb-lg-neg-9,
    .my-lg-neg-9
    {
        margin-bottom: -9rem !important;
    }
    .ml-lg-neg-9,
    .mx-lg-neg-9
    {
        margin-left: -9rem !important;
    }
    .m-lg-neg-10
    {
        margin: 10rem !important;
    }
    .mt-lg-neg-10,
    .my-lg-neg-10
    {
        margin-top: -10rem !important;
    }
    .mr-lg-neg-10,
    .mx-lg-neg-10
    {
        margin-right: -10rem !important;
    }
    .mb-lg-neg-10,
    .my-lg-neg-10
    {
        margin-bottom: -10rem !important;
    }
    .ml-lg-neg-10,
    .mx-lg-neg-10
    {
        margin-left: -10rem !important;
    }
    .m-lg-neg-11
    {
        margin: 12rem !important;
    }
    .mt-lg-neg-11,
    .my-lg-neg-11
    {
        margin-top: -12rem !important;
    }
    .mr-lg-neg-11,
    .mx-lg-neg-11
    {
        margin-right: -12rem !important;
    }
    .mb-lg-neg-11,
    .my-lg-neg-11
    {
        margin-bottom: -12rem !important;
    }
    .ml-lg-neg-11,
    .mx-lg-neg-11
    {
        margin-left: -12rem !important;
    }
    .m-lg-neg-12
    {
        margin: 14.5rem !important;
    }
    .mt-lg-neg-12,
    .my-lg-neg-12
    {
        margin-top: -14.5rem !important;
    }
    .mr-lg-neg-12,
    .mx-lg-neg-12
    {
        margin-right: -14.5rem !important;
    }
    .mb-lg-neg-12,
    .my-lg-neg-12
    {
        margin-bottom: -14.5rem !important;
    }
    .ml-lg-neg-12,
    .mx-lg-neg-12
    {
        margin-left: -14.5rem !important;
    }
    .m-lg-neg-13
    {
        margin: 15rem !important;
    }
    .mt-lg-neg-13,
    .my-lg-neg-13
    {
        margin-top: -15rem !important;
    }
    .mr-lg-neg-13,
    .mx-lg-neg-13
    {
        margin-right: -15rem !important;
    }
    .mb-lg-neg-13,
    .my-lg-neg-13
    {
        margin-bottom: -15rem !important;
    }
    .ml-lg-neg-13,
    .mx-lg-neg-13
    {
        margin-left: -15rem !important;
    }
    .m-lg-neg-14
    {
        margin: 16rem !important;
    }
    .mt-lg-neg-14,
    .my-lg-neg-14
    {
        margin-top: -16rem !important;
    }
    .mr-lg-neg-14,
    .mx-lg-neg-14
    {
        margin-right: -16rem !important;
    }
    .mb-lg-neg-14,
    .my-lg-neg-14
    {
        margin-bottom: -16rem !important;
    }
    .ml-lg-neg-14,
    .mx-lg-neg-14
    {
        margin-left: -16rem !important;
    }
    .m-lg-neg-15
    {
        margin: 17rem !important;
    }
    .mt-lg-neg-15,
    .my-lg-neg-15
    {
        margin-top: -17rem !important;
    }
    .mr-lg-neg-15,
    .mx-lg-neg-15
    {
        margin-right: -17rem !important;
    }
    .mb-lg-neg-15,
    .my-lg-neg-15
    {
        margin-bottom: -17rem !important;
    }
    .ml-lg-neg-15,
    .mx-lg-neg-15
    {
        margin-left: -17rem !important;
    }
}

@media (min-width: 1200px)
{
    .m-xl-neg-0
    {
        margin: 0 !important;
    }
    .mt-xl-neg-0,
    .my-xl-neg-0
    {
        margin-top: 0 !important;
    }
    .mr-xl-neg-0,
    .mx-xl-neg-0
    {
        margin-right: 0 !important;
    }
    .mb-xl-neg-0,
    .my-xl-neg-0
    {
        margin-bottom: 0 !important;
    }
    .ml-xl-neg-0,
    .mx-xl-neg-0
    {
        margin-left: 0 !important;
    }
    .m-xl-neg-1
    {
        margin: .25rem !important;
    }
    .mt-xl-neg-1,
    .my-xl-neg-1
    {
        margin-top: -.25rem !important;
    }
    .mr-xl-neg-1,
    .mx-xl-neg-1
    {
        margin-right: -.25rem !important;
    }
    .mb-xl-neg-1,
    .my-xl-neg-1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-xl-neg-1,
    .mx-xl-neg-1
    {
        margin-left: -.25rem !important;
    }
    .m-xl-neg-2
    {
        margin: .5rem !important;
    }
    .mt-xl-neg-2,
    .my-xl-neg-2
    {
        margin-top: -.5rem !important;
    }
    .mr-xl-neg-2,
    .mx-xl-neg-2
    {
        margin-right: -.5rem !important;
    }
    .mb-xl-neg-2,
    .my-xl-neg-2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-xl-neg-2,
    .mx-xl-neg-2
    {
        margin-left: -.5rem !important;
    }
    .m-xl-neg-3
    {
        margin: 1rem !important;
    }
    .mt-xl-neg-3,
    .my-xl-neg-3
    {
        margin-top: -1rem !important;
    }
    .mr-xl-neg-3,
    .mx-xl-neg-3
    {
        margin-right: -1rem !important;
    }
    .mb-xl-neg-3,
    .my-xl-neg-3
    {
        margin-bottom: -1rem !important;
    }
    .ml-xl-neg-3,
    .mx-xl-neg-3
    {
        margin-left: -1rem !important;
    }
    .m-xl-neg-4
    {
        margin: 1.5rem !important;
    }
    .mt-xl-neg-4,
    .my-xl-neg-4
    {
        margin-top: -1.5rem !important;
    }
    .mr-xl-neg-4,
    .mx-xl-neg-4
    {
        margin-right: -1.5rem !important;
    }
    .mb-xl-neg-4,
    .my-xl-neg-4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-xl-neg-4,
    .mx-xl-neg-4
    {
        margin-left: -1.5rem !important;
    }
    .m-xl-neg-5
    {
        margin: 3rem !important;
    }
    .mt-xl-neg-5,
    .my-xl-neg-5
    {
        margin-top: -3rem !important;
    }
    .mr-xl-neg-5,
    .mx-xl-neg-5
    {
        margin-right: -3rem !important;
    }
    .mb-xl-neg-5,
    .my-xl-neg-5
    {
        margin-bottom: -3rem !important;
    }
    .ml-xl-neg-5,
    .mx-xl-neg-5
    {
        margin-left: -3rem !important;
    }
    .m-xl-neg-6
    {
        margin: 4.5rem !important;
    }
    .mt-xl-neg-6,
    .my-xl-neg-6
    {
        margin-top: -4.5rem !important;
    }
    .mr-xl-neg-6,
    .mx-xl-neg-6
    {
        margin-right: -4.5rem !important;
    }
    .mb-xl-neg-6,
    .my-xl-neg-6
    {
        margin-bottom: -4.5rem !important;
    }
    .ml-xl-neg-6,
    .mx-xl-neg-6
    {
        margin-left: -4.5rem !important;
    }
    .m-xl-neg-7
    {
        margin: 6rem !important;
    }
    .mt-xl-neg-7,
    .my-xl-neg-7
    {
        margin-top: -6rem !important;
    }
    .mr-xl-neg-7,
    .mx-xl-neg-7
    {
        margin-right: -6rem !important;
    }
    .mb-xl-neg-7,
    .my-xl-neg-7
    {
        margin-bottom: -6rem !important;
    }
    .ml-xl-neg-7,
    .mx-xl-neg-7
    {
        margin-left: -6rem !important;
    }
    .m-xl-neg-8
    {
        margin: 7.5rem !important;
    }
    .mt-xl-neg-8,
    .my-xl-neg-8
    {
        margin-top: -7.5rem !important;
    }
    .mr-xl-neg-8,
    .mx-xl-neg-8
    {
        margin-right: -7.5rem !important;
    }
    .mb-xl-neg-8,
    .my-xl-neg-8
    {
        margin-bottom: -7.5rem !important;
    }
    .ml-xl-neg-8,
    .mx-xl-neg-8
    {
        margin-left: -7.5rem !important;
    }
    .m-xl-neg-9
    {
        margin: 9rem !important;
    }
    .mt-xl-neg-9,
    .my-xl-neg-9
    {
        margin-top: -9rem !important;
    }
    .mr-xl-neg-9,
    .mx-xl-neg-9
    {
        margin-right: -9rem !important;
    }
    .mb-xl-neg-9,
    .my-xl-neg-9
    {
        margin-bottom: -9rem !important;
    }
    .ml-xl-neg-9,
    .mx-xl-neg-9
    {
        margin-left: -9rem !important;
    }
    .m-xl-neg-10
    {
        margin: 10rem !important;
    }
    .mt-xl-neg-10,
    .my-xl-neg-10
    {
        margin-top: -10rem !important;
    }
    .mr-xl-neg-10,
    .mx-xl-neg-10
    {
        margin-right: -10rem !important;
    }
    .mb-xl-neg-10,
    .my-xl-neg-10
    {
        margin-bottom: -10rem !important;
    }
    .ml-xl-neg-10,
    .mx-xl-neg-10
    {
        margin-left: -10rem !important;
    }
    .m-xl-neg-11
    {
        margin: 12rem !important;
    }
    .mt-xl-neg-11,
    .my-xl-neg-11
    {
        margin-top: -12rem !important;
    }
    .mr-xl-neg-11,
    .mx-xl-neg-11
    {
        margin-right: -12rem !important;
    }
    .mb-xl-neg-11,
    .my-xl-neg-11
    {
        margin-bottom: -12rem !important;
    }
    .ml-xl-neg-11,
    .mx-xl-neg-11
    {
        margin-left: -12rem !important;
    }
    .m-xl-neg-12
    {
        margin: 14.5rem !important;
    }
    .mt-xl-neg-12,
    .my-xl-neg-12
    {
        margin-top: -14.5rem !important;
    }
    .mr-xl-neg-12,
    .mx-xl-neg-12
    {
        margin-right: -14.5rem !important;
    }
    .mb-xl-neg-12,
    .my-xl-neg-12
    {
        margin-bottom: -14.5rem !important;
    }
    .ml-xl-neg-12,
    .mx-xl-neg-12
    {
        margin-left: -14.5rem !important;
    }
    .m-xl-neg-13
    {
        margin: 15rem !important;
    }
    .mt-xl-neg-13,
    .my-xl-neg-13
    {
        margin-top: -15rem !important;
    }
    .mr-xl-neg-13,
    .mx-xl-neg-13
    {
        margin-right: -15rem !important;
    }
    .mb-xl-neg-13,
    .my-xl-neg-13
    {
        margin-bottom: -15rem !important;
    }
    .ml-xl-neg-13,
    .mx-xl-neg-13
    {
        margin-left: -15rem !important;
    }
    .m-xl-neg-14
    {
        margin: 16rem !important;
    }
    .mt-xl-neg-14,
    .my-xl-neg-14
    {
        margin-top: -16rem !important;
    }
    .mr-xl-neg-14,
    .mx-xl-neg-14
    {
        margin-right: -16rem !important;
    }
    .mb-xl-neg-14,
    .my-xl-neg-14
    {
        margin-bottom: -16rem !important;
    }
    .ml-xl-neg-14,
    .mx-xl-neg-14
    {
        margin-left: -16rem !important;
    }
    .m-xl-neg-15
    {
        margin: 17rem !important;
    }
    .mt-xl-neg-15,
    .my-xl-neg-15
    {
        margin-top: -17rem !important;
    }
    .mr-xl-neg-15,
    .mx-xl-neg-15
    {
        margin-right: -17rem !important;
    }
    .mb-xl-neg-15,
    .my-xl-neg-15
    {
        margin-bottom: -17rem !important;
    }
    .ml-xl-neg-15,
    .mx-xl-neg-15
    {
        margin-left: -17rem !important;
    }
}

@media (min-width: 1600px)
{
    .m-xxl-neg-0
    {
        margin: 0 !important;
    }
    .mt-xxl-neg-0,
    .my-xxl-neg-0
    {
        margin-top: 0 !important;
    }
    .mr-xxl-neg-0,
    .mx-xxl-neg-0
    {
        margin-right: 0 !important;
    }
    .mb-xxl-neg-0,
    .my-xxl-neg-0
    {
        margin-bottom: 0 !important;
    }
    .ml-xxl-neg-0,
    .mx-xxl-neg-0
    {
        margin-left: 0 !important;
    }
    .m-xxl-neg-1
    {
        margin: .25rem !important;
    }
    .mt-xxl-neg-1,
    .my-xxl-neg-1
    {
        margin-top: -.25rem !important;
    }
    .mr-xxl-neg-1,
    .mx-xxl-neg-1
    {
        margin-right: -.25rem !important;
    }
    .mb-xxl-neg-1,
    .my-xxl-neg-1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-xxl-neg-1,
    .mx-xxl-neg-1
    {
        margin-left: -.25rem !important;
    }
    .m-xxl-neg-2
    {
        margin: .5rem !important;
    }
    .mt-xxl-neg-2,
    .my-xxl-neg-2
    {
        margin-top: -.5rem !important;
    }
    .mr-xxl-neg-2,
    .mx-xxl-neg-2
    {
        margin-right: -.5rem !important;
    }
    .mb-xxl-neg-2,
    .my-xxl-neg-2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-xxl-neg-2,
    .mx-xxl-neg-2
    {
        margin-left: -.5rem !important;
    }
    .m-xxl-neg-3
    {
        margin: 1rem !important;
    }
    .mt-xxl-neg-3,
    .my-xxl-neg-3
    {
        margin-top: -1rem !important;
    }
    .mr-xxl-neg-3,
    .mx-xxl-neg-3
    {
        margin-right: -1rem !important;
    }
    .mb-xxl-neg-3,
    .my-xxl-neg-3
    {
        margin-bottom: -1rem !important;
    }
    .ml-xxl-neg-3,
    .mx-xxl-neg-3
    {
        margin-left: -1rem !important;
    }
    .m-xxl-neg-4
    {
        margin: 1.5rem !important;
    }
    .mt-xxl-neg-4,
    .my-xxl-neg-4
    {
        margin-top: -1.5rem !important;
    }
    .mr-xxl-neg-4,
    .mx-xxl-neg-4
    {
        margin-right: -1.5rem !important;
    }
    .mb-xxl-neg-4,
    .my-xxl-neg-4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-xxl-neg-4,
    .mx-xxl-neg-4
    {
        margin-left: -1.5rem !important;
    }
    .m-xxl-neg-5
    {
        margin: 3rem !important;
    }
    .mt-xxl-neg-5,
    .my-xxl-neg-5
    {
        margin-top: -3rem !important;
    }
    .mr-xxl-neg-5,
    .mx-xxl-neg-5
    {
        margin-right: -3rem !important;
    }
    .mb-xxl-neg-5,
    .my-xxl-neg-5
    {
        margin-bottom: -3rem !important;
    }
    .ml-xxl-neg-5,
    .mx-xxl-neg-5
    {
        margin-left: -3rem !important;
    }
    .m-xxl-neg-6
    {
        margin: 4.5rem !important;
    }
    .mt-xxl-neg-6,
    .my-xxl-neg-6
    {
        margin-top: -4.5rem !important;
    }
    .mr-xxl-neg-6,
    .mx-xxl-neg-6
    {
        margin-right: -4.5rem !important;
    }
    .mb-xxl-neg-6,
    .my-xxl-neg-6
    {
        margin-bottom: -4.5rem !important;
    }
    .ml-xxl-neg-6,
    .mx-xxl-neg-6
    {
        margin-left: -4.5rem !important;
    }
    .m-xxl-neg-7
    {
        margin: 6rem !important;
    }
    .mt-xxl-neg-7,
    .my-xxl-neg-7
    {
        margin-top: -6rem !important;
    }
    .mr-xxl-neg-7,
    .mx-xxl-neg-7
    {
        margin-right: -6rem !important;
    }
    .mb-xxl-neg-7,
    .my-xxl-neg-7
    {
        margin-bottom: -6rem !important;
    }
    .ml-xxl-neg-7,
    .mx-xxl-neg-7
    {
        margin-left: -6rem !important;
    }
    .m-xxl-neg-8
    {
        margin: 7.5rem !important;
    }
    .mt-xxl-neg-8,
    .my-xxl-neg-8
    {
        margin-top: -7.5rem !important;
    }
    .mr-xxl-neg-8,
    .mx-xxl-neg-8
    {
        margin-right: -7.5rem !important;
    }
    .mb-xxl-neg-8,
    .my-xxl-neg-8
    {
        margin-bottom: -7.5rem !important;
    }
    .ml-xxl-neg-8,
    .mx-xxl-neg-8
    {
        margin-left: -7.5rem !important;
    }
    .m-xxl-neg-9
    {
        margin: 9rem !important;
    }
    .mt-xxl-neg-9,
    .my-xxl-neg-9
    {
        margin-top: -9rem !important;
    }
    .mr-xxl-neg-9,
    .mx-xxl-neg-9
    {
        margin-right: -9rem !important;
    }
    .mb-xxl-neg-9,
    .my-xxl-neg-9
    {
        margin-bottom: -9rem !important;
    }
    .ml-xxl-neg-9,
    .mx-xxl-neg-9
    {
        margin-left: -9rem !important;
    }
    .m-xxl-neg-10
    {
        margin: 10rem !important;
    }
    .mt-xxl-neg-10,
    .my-xxl-neg-10
    {
        margin-top: -10rem !important;
    }
    .mr-xxl-neg-10,
    .mx-xxl-neg-10
    {
        margin-right: -10rem !important;
    }
    .mb-xxl-neg-10,
    .my-xxl-neg-10
    {
        margin-bottom: -10rem !important;
    }
    .ml-xxl-neg-10,
    .mx-xxl-neg-10
    {
        margin-left: -10rem !important;
    }
    .m-xxl-neg-11
    {
        margin: 12rem !important;
    }
    .mt-xxl-neg-11,
    .my-xxl-neg-11
    {
        margin-top: -12rem !important;
    }
    .mr-xxl-neg-11,
    .mx-xxl-neg-11
    {
        margin-right: -12rem !important;
    }
    .mb-xxl-neg-11,
    .my-xxl-neg-11
    {
        margin-bottom: -12rem !important;
    }
    .ml-xxl-neg-11,
    .mx-xxl-neg-11
    {
        margin-left: -12rem !important;
    }
    .m-xxl-neg-12
    {
        margin: 14.5rem !important;
    }
    .mt-xxl-neg-12,
    .my-xxl-neg-12
    {
        margin-top: -14.5rem !important;
    }
    .mr-xxl-neg-12,
    .mx-xxl-neg-12
    {
        margin-right: -14.5rem !important;
    }
    .mb-xxl-neg-12,
    .my-xxl-neg-12
    {
        margin-bottom: -14.5rem !important;
    }
    .ml-xxl-neg-12,
    .mx-xxl-neg-12
    {
        margin-left: -14.5rem !important;
    }
    .m-xxl-neg-13
    {
        margin: 15rem !important;
    }
    .mt-xxl-neg-13,
    .my-xxl-neg-13
    {
        margin-top: -15rem !important;
    }
    .mr-xxl-neg-13,
    .mx-xxl-neg-13
    {
        margin-right: -15rem !important;
    }
    .mb-xxl-neg-13,
    .my-xxl-neg-13
    {
        margin-bottom: -15rem !important;
    }
    .ml-xxl-neg-13,
    .mx-xxl-neg-13
    {
        margin-left: -15rem !important;
    }
    .m-xxl-neg-14
    {
        margin: 16rem !important;
    }
    .mt-xxl-neg-14,
    .my-xxl-neg-14
    {
        margin-top: -16rem !important;
    }
    .mr-xxl-neg-14,
    .mx-xxl-neg-14
    {
        margin-right: -16rem !important;
    }
    .mb-xxl-neg-14,
    .my-xxl-neg-14
    {
        margin-bottom: -16rem !important;
    }
    .ml-xxl-neg-14,
    .mx-xxl-neg-14
    {
        margin-left: -16rem !important;
    }
    .m-xxl-neg-15
    {
        margin: 17rem !important;
    }
    .mt-xxl-neg-15,
    .my-xxl-neg-15
    {
        margin-top: -17rem !important;
    }
    .mr-xxl-neg-15,
    .mx-xxl-neg-15
    {
        margin-right: -17rem !important;
    }
    .mb-xxl-neg-15,
    .my-xxl-neg-15
    {
        margin-bottom: -17rem !important;
    }
    .ml-xxl-neg-15,
    .mx-xxl-neg-15
    {
        margin-left: -17rem !important;
    }
}

/* ==========================================================================
   Text
   ========================================================================== */
/* Color
========================================================================== */
.text-100
{
    color: #f5f5f5 !important;
}

.text-200
{
    color: #e8e8e9 !important;
}

.text-300
{
    color: #d2d4db !important;
}

.text-400
{
    color: #cdced3 !important;
}

.text-500
{
    color: #87888d !important;
}

.text-600
{
    color: #6c757d !important;
}

.text-700
{
    color: #5a5c63 !important;
}

.text-800
{
    color: #343a40 !important;
}

.text-900
{
    color: #1a1e2c !important;
}

/* Weight
   ========================================================================== */
.fw-thin
{
    font-weight: 100 !important;
}

.fw-extra-light
{
    font-weight: 200 !important;
}

.fw-light
{
    font-weight: 300 !important;
}

.fw-normal
{
    font-weight: 400 !important;
}

.fw-medium
{
    font-weight: 500 !important;
}

.fw-semi-bold
{
    font-weight: 600 !important;
}

.fw-bold
{
    font-weight: 700 !important;
}

.fw-extra-bold
{
    font-weight: 800 !important;
}

.fw-black
{
    font-weight: 900 !important;
}

/* Text size from .fs--2 to .fs-9 
   ========================================================================== */
.fs--3
{
    font-size: .75rem !important;
}

.fs--2
{
    font-size: .8125rem !important;
}

.fs--1
{
    font-size: .9375rem !important;
}

.fs-0
{
    font-size: 1.125rem !important;
}

.fs-1
{
    font-size: 1.5rem !important;
}

.fs-2
{
    font-size: 2rem !important;
}

.fs-3
{
    font-size: 2.5rem !important;
}

.fs-4
{
    font-size: 3rem !important;
}

.fs-5
{
    font-size: 3.5rem !important;
}

.fs-6
{
    font-size: 4rem !important;
}

.fs-7
{
    font-size: 4.5rem !important;
}

.fs-8
{
    font-size: 5.125rem !important;
}

.fs-9
{
    font-size: 6rem !important;
}

@media (min-width: 576px)
{
    .fs-sm--3
    {
        font-size: .75rem !important;
    }
    .fs-sm--2
    {
        font-size: .8125rem !important;
    }
    .fs-sm--1
    {
        font-size: .9375rem !important;
    }
    .fs-sm-0
    {
        font-size: 1.125rem !important;
    }
    .fs-sm-1
    {
        font-size: 1.5rem !important;
    }
    .fs-sm-2
    {
        font-size: 2rem !important;
    }
    .fs-sm-3
    {
        font-size: 2.5rem !important;
    }
    .fs-sm-4
    {
        font-size: 3rem !important;
    }
    .fs-sm-5
    {
        font-size: 3.5rem !important;
    }
    .fs-sm-6
    {
        font-size: 4rem !important;
    }
    .fs-sm-7
    {
        font-size: 4.5rem !important;
    }
    .fs-sm-8
    {
        font-size: 5.125rem !important;
    }
    .fs-sm-9
    {
        font-size: 6rem !important;
    }
}

@media (min-width: 768px)
{
    .fs-md--3
    {
        font-size: .75rem !important;
    }
    .fs-md--2
    {
        font-size: .8125rem !important;
    }
    .fs-md--1
    {
        font-size: .9375rem !important;
    }
    .fs-md-0
    {
        font-size: 1.125rem !important;
    }
    .fs-md-1
    {
        font-size: 1.5rem !important;
    }
    .fs-md-2
    {
        font-size: 2rem !important;
    }
    .fs-md-3
    {
        font-size: 2.5rem !important;
    }
    .fs-md-4
    {
        font-size: 3rem !important;
    }
    .fs-md-5
    {
        font-size: 3.5rem !important;
    }
    .fs-md-6
    {
        font-size: 4rem !important;
    }
    .fs-md-7
    {
        font-size: 4.5rem !important;
    }
    .fs-md-8
    {
        font-size: 5.125rem !important;
    }
    .fs-md-9
    {
        font-size: 6rem !important;
    }
}

@media (min-width: 992px)
{
    .fs-lg--3
    {
        font-size: .75rem !important;
    }
    .fs-lg--2
    {
        font-size: .8125rem !important;
    }
    .fs-lg--1
    {
        font-size: .9375rem !important;
    }
    .fs-lg-0
    {
        font-size: 1.125rem !important;
    }
    .fs-lg-1
    {
        font-size: 1.5rem !important;
    }
    .fs-lg-2
    {
        font-size: 2rem !important;
    }
    .fs-lg-3
    {
        font-size: 2.5rem !important;
    }
    .fs-lg-4
    {
        font-size: 3rem !important;
    }
    .fs-lg-5
    {
        font-size: 3.5rem !important;
    }
    .fs-lg-6
    {
        font-size: 4rem !important;
    }
    .fs-lg-7
    {
        font-size: 4.5rem !important;
    }
    .fs-lg-8
    {
        font-size: 5.125rem !important;
    }
    .fs-lg-9
    {
        font-size: 6rem !important;
    }
}

@media (min-width: 1200px)
{
    .fs-xl--3
    {
        font-size: .75rem !important;
    }
    .fs-xl--2
    {
        font-size: .8125rem !important;
    }
    .fs-xl--1
    {
        font-size: .9375rem !important;
    }
    .fs-xl-0
    {
        font-size: 1.125rem !important;
    }
    .fs-xl-1
    {
        font-size: 1.5rem !important;
    }
    .fs-xl-2
    {
        font-size: 2rem !important;
    }
    .fs-xl-3
    {
        font-size: 2.5rem !important;
    }
    .fs-xl-4
    {
        font-size: 3rem !important;
    }
    .fs-xl-5
    {
        font-size: 3.5rem !important;
    }
    .fs-xl-6
    {
        font-size: 4rem !important;
    }
    .fs-xl-7
    {
        font-size: 4.5rem !important;
    }
    .fs-xl-8
    {
        font-size: 5.125rem !important;
    }
    .fs-xl-9
    {
        font-size: 6rem !important;
    }
}

@media (min-width: 1600px)
{
    .fs-xxl--3
    {
        font-size: .75rem !important;
    }
    .fs-xxl--2
    {
        font-size: .8125rem !important;
    }
    .fs-xxl--1
    {
        font-size: .9375rem !important;
    }
    .fs-xxl-0
    {
        font-size: 1.125rem !important;
    }
    .fs-xxl-1
    {
        font-size: 1.5rem !important;
    }
    .fs-xxl-2
    {
        font-size: 2rem !important;
    }
    .fs-xxl-3
    {
        font-size: 2.5rem !important;
    }
    .fs-xxl-4
    {
        font-size: 3rem !important;
    }
    .fs-xxl-5
    {
        font-size: 3.5rem !important;
    }
    .fs-xxl-6
    {
        font-size: 4rem !important;
    }
    .fs-xxl-7
    {
        font-size: 4.5rem !important;
    }
    .fs-xxl-8
    {
        font-size: 5.125rem !important;
    }
    .fs-xxl-9
    {
        font-size: 6rem !important;
    }
}

.lh-10
{
    line-height: 1;
}

.lh-11
{
    line-height: 1.1;
}

.lh-12
{
    line-height: 1.2;
}

.lh-13
{
    line-height: 1.3;
}

.lh-14
{
    line-height: 1.4;
}

.lh-15
{
    line-height: 1.5;
}

.lh-16
{
    line-height: 1.6;
}

.lh-17
{
    line-height: 1.7;
}

.lh-18
{
    line-height: 1.8;
}

.lh-19
{
    line-height: 1.9;
}

.lh-20
{
    line-height: 2;
}

/* ==========================================================================
   Z-index
   ========================================================================== */
.zindex--1
{
    z-index: -1 !important;
}

.zindex-0
{
    z-index: 0 !important;
}

.zindex-1
{
    z-index: 1 !important;
}

/* ==========================================================================
   Background video
   ========================================================================== */
.bg-video
{
    position: absolute !important;
    top: 0;
    left: 0;

    width: 100%;
    min-width: 100%;
    height: 100%;

    pointer-events: none;

    object-fit: cover;
}

/* ==========================================================================
   AOS - Animate on scroll library
   ========================================================================== */
@media (max-width: 991.98px)
{
    [data-aos]
    {
        visibility: visible !important; 

        transition: none !important;
        transform: none !important;

        opacity: 1 !important;
    }
}

/* ==========================================================================
   Material Icons
   ========================================================================== */
.zmdi,
.faq .card-header button::before,
.spyre-nav-link.dropdown-toggle::after
{
    font-family: 'Material-Design-Iconic-Font';
    font-weight: normal;
    font-style: normal;

    display: inline-block;

    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* ==========================================================================
   Prism Syntax highlighter
   ========================================================================== */
:not(pre) > code[class*='language-'],
pre[class*='language-']
{
    border: 1px solid #d2d4db;
    border-radius: 0;
    background: #f5f5f5;
}

code[class*=language-],
pre[class*=language-]
{
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 15px;
}

pre[class*='language-']
{
    padding-top: 0;
    padding-bottom: .5rem;
}

div.code-toolbar
{
    margin-bottom: 3rem;
}
div.code-toolbar > .toolbar
{
    top: 2px;
    right: 4px;

    padding: 1px 1px 1px 10px;

    opacity: 1 !important;
    background: #f5f5f5;
}
div.code-toolbar > .toolbar .toolbar-item a,
div.code-toolbar > .toolbar .toolbar-item button,
div.code-toolbar > .toolbar .toolbar-item span
{
    color: #6c757d; 
    border-radius: 3px;
    background: none;
    box-shadow: none;
}
div.code-toolbar > .toolbar .toolbar-item a:hover,
div.code-toolbar > .toolbar .toolbar-item button:hover,
div.code-toolbar > .toolbar .toolbar-item span:hover
{
    color: #fff; 
    border-color: #1f2749;
    background: #1f2749;
}

/* ==========================================================================
   Carousel
   ========================================================================== */
.owl-carousel
{
    /* Navs
    ========================================================================== */
    /* Nav arrows live inside the carousel in the corner */
    /* Nav arrows live on the two edges of the carousel */
    /* Nav arrows live on the two edges of the carousel, but inside */
    /* Nav arrows use different color and smaller size */
    /* Dots
    ========================================================================== */
}
.owl-carousel .owl-nav
{
    position: absolute;
    right: 0;
    bottom: 0;

    -webkit-tap-highlight-color: transparent;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next
{
    font-size: 1.3125rem;
    line-height: 3.125rem;

    display: inline-block;

    width: 3.125rem;
    height: 3.125rem;

    cursor: pointer; 

    color: rgba(31, 39, 73, .8);
    background-color: rgba(255, 255, 255, .7);
}
.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-next:hover
{
    text-decoration: none; 

    color: #1f2749;
    background-color: rgba(255, 255, 255, .92);
}
.owl-carousel .owl-nav button.owl-prev
{
    margin-right: 1px;
}
.owl-carousel .owl-nav .disabled
{
    cursor: default; 

    opacity: .3;
}
.owl-carousel.carousel-nav-edge .owl-nav
{
    top: 50%;
    right: 0;
    bottom: auto;
    left: 0;

    transform: translateY(-50%);
}
.owl-carousel.carousel-nav-edge .owl-nav button.owl-prev
{
    margin-right: auto;
    margin-left: 1.5625rem;
}
.owl-carousel.carousel-nav-edge .owl-nav button.owl-next
{
    float: right;

    margin-right: 1.5625rem;
}
.owl-carousel.carousel-nav-inside-container .owl-nav
{
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;

    width: 100%;

    transform: translate(-50%, -50%);
}
@media (min-width: 576px)
{
    .owl-carousel.carousel-nav-inside-container .owl-nav
    {
        width: 510px;
    }
}
@media (min-width: 768px)
{
    .owl-carousel.carousel-nav-inside-container .owl-nav
    {
        width: 690px;
    }
}
@media (min-width: 992px)
{
    .owl-carousel.carousel-nav-inside-container .owl-nav
    {
        width: 930px;
    }
}
@media (min-width: 1200px)
{
    .owl-carousel.carousel-nav-inside-container .owl-nav
    {
        width: 1110px;
    }
}
@media (min-width: 1600px)
{
    .owl-carousel.carousel-nav-inside-container .owl-nav
    {
        width: 1270px;
    }
}
@media (min-width: 992px)
{
    .owl-carousel.carousel-nav-inside-container .owl-nav button.owl-prev
    {
        margin-right: auto;
        margin-left: -1.5625rem;
    }
    .owl-carousel.carousel-nav-inside-container .owl-nav button.owl-next
    {
        float: right;

        margin-right: -1.5625rem;
    }
}
.owl-carousel.carousel-nav-primary button.owl-prev,
.owl-carousel.carousel-nav-primary button.owl-next
{
    line-height: 2.3125rem;

    width: 4.1875rem;
    height: 2.3125rem;

    color: rgba(255, 255, 255, .8); 
    background-color: rgba(241, 38, 57, .7);
}
.owl-carousel.carousel-nav-primary button.owl-prev:hover,
.owl-carousel.carousel-nav-primary button.owl-next:hover
{
    color: #fff; 
    background-color: rgba(241, 38, 57, .92);
}
.owl-carousel.carousel-nav-primary button.owl-prev
{
    margin-right: auto;
}
.owl-carousel.carousel-nav-primary button.owl-next
{
    float: right;
}
@media (min-width: 992px)
{
    .owl-carousel.carousel-nav-primary button.owl-prev
    {
        margin-left: -2.09375rem;
    }
    .owl-carousel.carousel-nav-primary button.owl-next
    {
        margin-right: -2.09375rem;
    }
}
.owl-carousel .owl-nav.disabled + .owl-dots
{
    margin-top: 10px;
}
.owl-carousel .owl-dots
{
    text-align: center;

    -webkit-tap-highlight-color: transparent;
}
.owl-carousel .owl-dots .owl-dot
{
    display: inline-block;
    zoom: 1;
}
.owl-carousel .owl-dots .owl-dot span
{
    display: block;

    width: 10px;
    height: 10px;
    margin: 5px 7px;

    transition: opacity .2s ease; 

    border-radius: 30px;
    background: rgba(255, 255, 255, .5);

    -webkit-backface-visibility: visible;
}
.owl-carousel .owl-dots .owl-dot.active span
{
    background: #fff;
}

/* ==========================================================================
   Fancybox
   ========================================================================== */
.fancybox-bg
{
    background: #1a1e2c;
}

.fancybox-is-open .fancybox-bg
{
    opacity: .9;
}

.fancybox-button,
.fancybox-button:link,
.fancybox-button:visited
{
    color: #fff; 
    background: rgba(26, 30, 44, 0);
}

.fancybox-caption
{
    color: #fff; 
    background: linear-gradient(to top, rgba(26, 30, 44, .85) 0%, rgba(26, 30, 44, .3) 50%, rgba(26, 30, 44, .15) 65%, rgba(26, 30, 44, .075) 75.5%, rgba(26, 30, 44, .037) 82.85%, rgba(26, 30, 44, .019) 88%, rgba(26, 30, 44, 0) 100%);
}

.fancybox-navigation .fancybox-button
{
    display: inline-block;

    width: 3.125rem;
    height: 3.125rem;
    margin: 0;
    padding: 0;

    cursor: pointer; 

    color: rgba(31, 39, 73, .8);
    background-color: rgba(255, 255, 255, .7);
}
.fancybox-navigation .fancybox-button:hover:not([disabled])
{
    color: #1f2749; 
    background-color: rgba(255, 255, 255, .92);
}
.fancybox-navigation .fancybox-button[disabled]
{
    cursor: default; 

    opacity: .3;
    color: rgba(31, 39, 73, .8);
}
.fancybox-navigation .fancybox-button div
{
    padding: 11px;
}

@media (min-width: 992px)
{
    .fancybox-slide
    {
        padding: 80px 120px;
    }
}

/* ==========================================================================
   Rellax - Parallax library
   ========================================================================== */
@media (max-width: 991.98px)
{
    .parallax
    {
        transform: translate3d(0, 0, 0) !important;
    }
}

/* ==========================================================================
   Custom styles
   ========================================================================== */
/**
 * Define you custome style here.
 * It will be merged with the default style.
 */

/*# sourceMappingURL=theme.css.map */
